import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';
import StyledPhoneField from 'components/molecules/Fields/PhoneField/PhoneField.styles';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Link from 'components/atoms/Links/Link/Link';
import InputAdornment from 'components/atoms/Inputs/InputAdornment/InputAdornment';
import Icon from 'components/atoms/Icons/Icon/Icon';
import PhoneInTalk from '@mui/icons-material/PhoneInTalk';

const PhoneField = withMemo(React.forwardRef((props, ref) => {
  const {
    showLinkIcon,
    ...innerProps
  } = useComponentProps(props, 'PhoneField', {
    static: ['showLinkIcon']
  });

  const renderReadOnly = (value, props) => {
    if (value) {
      if (showLinkIcon) {
        return <React.Fragment>
          <InputAdornment size={innerProps?.size ?? 'small'}
                          position="start">
            <Link href={`tel:${utils.phone2String(value)}`}
                  onClick={(e) => e.stopPropagation()}>
              <Icon icon={PhoneInTalk} size="small"/>
            </Link>
          </InputAdornment>
          <Span showTooltip={props.showTooltip}>
            {utils.phone2String(value)}
          </Span>
        </React.Fragment>;
      } else {
        return <Span showTooltip={props.showTooltip}>
          <Link href={`tel:${utils.phone2String(value)}`}
                onClick={(e) => e.stopPropagation()}>
            {utils.phone2String(value)}
          </Link>
        </Span>
      }
    } else {
      return (props.placeholder && !props.hiddenPlaceholder) ? <Span className="placeholder">{props.placeholder}</Span> :
        <Span>&nbsp;</Span>;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledPhoneField ref={ref} {...innerProps} renderReadOnly={renderReadOnly}/>
}));

PhoneField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showLinkIcon: PropTypes.bool
};

PhoneField.defaultProps = {
};

export default PhoneField;
