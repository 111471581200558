import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledEntitiesTable from 'components/organisms/Tables/EntitiesTable/EntitiesTable.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import utils from 'helpers/utils';
import Logo from 'components/atoms/Logos/Logo/Logo';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import Link from 'components/atoms/Links/Link/Link';
import {
  useEntityCsi,
  useEntityPersonalRelevancy,
  useEntityRelevancy,
  useEntityExternalRelevancy, useEntityDealLeader, useEntityRelevancies, processEntityFeedback, processEntityInfo
} from 'services/entity/entity.utils';
import EntityRelevancyTableCellEdit from 'components/organisms/TableCellEdits/EntityRelevancyTableCellEdit/EntityRelevancyTableCellEdit';
import EntityRelevancyTableCell from 'components/molecules/TableCells/EntityRelevancyTableCell/EntityRelevancyTableCell';
import DealflowStatusTableCell from 'components/molecules/TableCells/DealflowStatusTableCell/DealflowStatusTableCell';
import DealflowStatusTableCellEdit from 'components/organisms/TableCellEdits/DealflowStatusTableCellEdit/DealflowStatusTableCellEdit';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';
import Edit from '@mui/icons-material/Edit';
import BadgeTableCell from 'components/molecules/TableCells/BadgeTableCell/BadgeTableCell';
import TimelineTableCell from 'components/molecules/TableCells/TimelineTableCell/TimelineTableCell';
import EntityTimelineTableCellEdit
  from 'components/organisms/TableCellEdits/EntityTimelineTableCellEdit/EntityTimelineTableCellEdit';
import UserTableCell from 'components/molecules/TableCells/UserTableCell/UserTableCell';
import UserTableCellEdit
  from 'components/organisms/TableCellEdits/UserTableCellEdit/UserTableCellEdit';
import EntityTeamRelevancyTableCellEdit
  from 'components/organisms/TableCellEdits/EntityTeamRelevancyTableCellEdit/EntityTeamRelevancyTableCellEdit';
import {useAuthIsVentureIq} from 'services/auth/auth.utils';
import AnalysisScoreTableCell from 'components/molecules/TableCells/AnalysisScoreTableCell/AnalysisScoreTableCell';
import AnalysisScoreTableCellEdit
  from 'components/organisms/TableCellEdits/AnalysisScoreTableCellEdit/AnalysisScoreTableCellEdit';
import {useCollectionGet} from 'services/collection/collection.hooks';
import FeedbackHelper from 'components/molecules/Helpers/FeedbackHelper/FeedbackHelper';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import {Remove} from 'assets/icons';
import useMediaQuery from '@mui/material/useMediaQuery';

const NameCell = React.forwardRef(({cell, column, row, table}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
  const entity = cell.row.original;
  const name = cell.column.columnDef.optimistic.get(cell, entity.name);

  const setEditingCellEvent = useEffectEvent(() => table.setEditingCell(cell));
  const action = useMemo(() => ({
    label: 'Edit',
    tooltip: 'Edit',
    auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      if (!e.defaultPrevented) {
        setEditingCellEvent?.();
        e.preventDefault();
      }
    }
  }), [enableEditing, setEditingCellEvent]);

  return <TextTableCell ref={ref}
                        title={name}
                        subtitle={entity.website ? <Link href={utils.cleanExternalLink(entity.website)}
                                                         onClick={(e) => e.stopPropagation()}
                                                         target="_blank">
                          {utils.displayExternalLink(entity.website)}
                        </Link> : null}
                        logo={<Logo logo={entity.logoUrl}
                                    altLogo={entity.altLogoUrl}
                                    fallbackIcon={HomeWorkOutlined}
                                    size="large"
                                    density="sparse"
                                    outlined={true} />}
                        action={action} />
});

const NameCellEdit = React.forwardRef(({column, cell, table, columnDef, fieldData, update}, ref) => {
  const entity = cell.row.original;
  const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());
  const field = {
    ...columnDef,
    name: column.id,
    label: column.columnDef.header,
    required: true,
    initial: name
  };

  const handleChange = (field, value, onSuccess, onError) => {
    onSuccess?.(); // optimistic
    cell.column.columnDef.optimistic.set(cell, value);
    update?.(cell.row.original, {name: value}).catch(() => {
      onError?.();
      cell.column.columnDef.optimistic.reset(cell);
    });
  }

  return <FieldTableCellEdit ref={ref}
                             cell={cell}
                             table={table}
                             fields={[field]}
                             fieldData={fieldData}
                             Anchor={<TextTableCell title={name}
                                                    subtitle={entity.website ? <Link href={utils.cleanExternalLink(entity.website)}
                                                                                     onClick={(e) => e.stopPropagation()}
                                                                                     target="_blank">
                                                      {utils.displayExternalLink(entity.website)}
                                                    </Link> : null}
                                                    logo={<Logo logo={entity.logoUrl}
                                                                altLogo={entity.altLogoUrl}
                                                                fallbackIcon={HomeWorkOutlined}
                                                                size="large"
                                                                density="sparse"
                                                                outlined={true} />} />}
                             onChange={handleChange}/>
});

const TasksCell = React.forwardRef(({cell}, ref) => {
  const entity = cell.row.original;

  const ActionBadgeProps = {
    size: 'small',
    action: {
      label: (entity.tasks ?? 0).toString(),
      tooltip: `${entity.tasks ?? 0} open tasks`
    }
  }

  return <BadgeTableCell ref={ref} ActionBadgeProps={ActionBadgeProps} />
});

const FieldCell = React.forwardRef(({table, row, column, cell, columnDef, fieldData}, ref) => {
  const isVentureIq = useAuthIsVentureIq();
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
  const entity = cell.row.original;
  const csi = useEntityCsi(entity);
  const field = utils.initializeFormFields([columnDef], {
    ...csi,
    ...entity,
    tags: isVentureIq ? entity.systemTags : entity.tags,
  })[0];
  field.initial = cell.column.columnDef.optimistic.get(cell, field.initial);

  field.feedback = processEntityFeedback(entity, field, field.initial?.value ?? field.initial);
  field.info = processEntityInfo(entity, field, field.initial?.value ?? field.initial);

  field.FormFieldProps = field.FormFieldProps ?? {};
  if (field.tagGroup) {
    field.FormFieldProps.ChipProps = {
      ...field.FormFieldProps.ChipProps,
      size: 'medium'
    };
  }

  const setEditingCellEvent = useEffectEvent(() => table.setEditingCell(cell));
  const action = useMemo(() => ({
    label: 'Edit',
    tooltip: 'Edit',
    auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      if (!e.defaultPrevented) {
        setEditingCellEvent?.();
        e.preventDefault();
      }
    }
  }), [enableEditing, setEditingCellEvent]);

  return <FieldTableCell ref={ref} fields={[field]} fieldData={fieldData} action={action}/>
});

const FieldCellEdit = React.forwardRef(({cell, table, columnDef, fieldData, update, updateTag, updateTagGroup}, ref) => {
  const isVentureIq = useAuthIsVentureIq();
  const entity = cell.row.original;
  const csi = useEntityCsi(entity);
  const field = utils.initializeFormFields([columnDef], {
    ...csi,
    ...entity,
    tags: isVentureIq ? entity.systemTags : entity.tags,
  })[0];
  field.initial = cell.column.columnDef.optimistic.get(cell, field.initial);

  field.context = {
    ...field.context,
    name: entity?.name,
    legalName: entity?.legalName,
    country: entity?.location?.country,
    city: entity?.city,
    address: entity?.address,
    cocNumber: entity?.cocNumber
  };

  field.feedback = processEntityFeedback(entity, field, field.initial?.value ?? field.initial);
  field.info = processEntityInfo(entity, field, field.initial?.value ?? field.initial);

  const helperText = field.FormFieldProps?.helperText ?? '';
  field.FormFieldProps = {
    ...field.FormFieldProps,
    helperText: field.feedback?.show ?
      ((field, value, disabled) => {
        if (!disabled) {
          const feedback = processEntityFeedback(entity, field, value?.value ?? value);
          return <FeedbackHelper feedback={feedback}
                                 fallback={helperText}
                                 acceptField={!feedback?.suggestion ? `${utils.camelcase(field.name)}Verified` : null}
                                 ignoreField={`${utils.camelcase(field.name)}Verified`}
                                 suggestionField={field.name}
                                 variant="compact"/>
        }
      }) : helperText
  };

  const fields = [field];
  if (field.feedback) {
    fields.push({
      name: `${utils.camelcase(field.name)}Verified`,
      type: constants.formFieldTypes.text,
      parent: field,
      initial: field.feedback.verified,
      FormFieldProps: {
        hidden: true
      }
    });
  }

  const handleChange = (field, value, onSuccess, onError) => {
    let promise;
    if (field.customField && utils.camelcase(field.customField.name) === 'tags') {
      promise = updateTag?.(cell.row.original, cell.row.original[!isVentureIq ? 'tags' : 'systemTags'], value);
    } else if (field.tagGroup) {
      promise = updateTagGroup?.(cell.row.original, field.tagGroup,
        cell.row.original.tagGroups.find((tg) => +tg.groupId === +field.tagGroupId)?.tags || [], utils.toArray(value, true));
    } else if (field.feedback) {
      promise = update?.(cell.row.original, {
        [field.name]: value,
        [`${utils.camelcase(field.name)}Verified`]: true
      });
    } else {
      promise = update?.(cell.row.original, {[field.name]: value});
    }

    if (field.parent) {
      onSuccess?.(null); // optimistic
      promise.catch(() => {
        onError?.(null);
      });
    } else {
      onSuccess?.(); // optimistic
      cell.column.columnDef.optimistic.set(cell, value);
      promise.catch(() => {
        onError?.();
        cell.column.columnDef.optimistic.reset(cell);
      });
    }
  }

  return <FieldTableCellEdit ref={ref}
                             cell={cell}
                             table={table}
                             fields={fields}
                             fieldData={fieldData}
                             onChange={handleChange}/>
});

const DealflowStatusCell = React.forwardRef(({cell, column, row, table}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing)

  const entity = cell.row.original;
  const csi = useEntityCsi(entity);
  const statusId = cell.column.columnDef.optimistic.get(cell, csi?.status ?? 0);

  const handleClick = (e) => {
    table.setEditingCell(cell);
    e.preventDefault();
  }

  return <DealflowStatusTableCell ref={ref}
                                  statusId={statusId}
                                  disabled={!enableEditing}
                                  size="large"
                                  onClick={handleClick} />
});

const DealflowStatusCellEdit = React.forwardRef(({cell, table, updateCsi}, ref) => {
  const entity = cell.row.original;
  const csi = useEntityCsi(entity);
  const statusId = cell.column.columnDef.optimistic.get(cell, csi?.status ?? 0);

  const handleChange = (entity, value) => {
    return updateCsi?.(entity, {status: value});
  };

  return <DealflowStatusTableCellEdit ref={ref}
                                      statusId={statusId}
                                      cell={cell}
                                      table={table}
                                      onChange={handleChange}/>
});

const DealLeaderCell = React.forwardRef(({cell, column, row, table}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing)

  const entity = cell.row.original;
  const dealLeaderOriginal = useEntityDealLeader(entity);
  const dealLeader = cell.column.columnDef.optimistic.get(cell, dealLeaderOriginal ?? null);

  const setEditingCellEvent = useEffectEvent(() => table.setEditingCell(cell));
  const action = useMemo(() => ({
    label: 'Edit',
    tooltip: 'Edit',
    auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      if (!e.defaultPrevented) {
        setEditingCellEvent?.();
        e.preventDefault();
      }
    }
  }), [enableEditing, setEditingCellEvent]);

  return <UserTableCell ref={ref}
                        user={dealLeader}
                        action={action} />
});

const DealLeaderCellEdit = React.forwardRef(({cell, table, updateCsi}, ref) => {
  const entity = cell.row.original;
  const client = useAuthClient();
  const dealLeaderOriginal = useEntityDealLeader(entity);
  const dealLeader = cell.column.columnDef.optimistic.get(cell, dealLeaderOriginal ?? 0);

  const handleChange = (entity, value) => {
    return updateCsi?.(entity, {leaderId: value?.userId});
  };

  return <UserTableCellEdit ref={ref}
                            user={dealLeader}
                            proxies={Boolean(client?.props?.proxyCanBeDealLeader)}
                            cell={cell}
                            table={table}
                            onChange={handleChange}/>
});

const TeamRelevancyCell = React.forwardRef(({cell, column, row, table, collection}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing)

  const entity = cell.row.original;
  const relevancy = useEntityRelevancy(entity, collection);

  const handleClick = (e) => {
    table.setEditingCell(cell);
    e.preventDefault();
  }

  return <EntityRelevancyTableCell ref={ref}
                                   relevancy={relevancy}
                                   disabled={!enableEditing}
                                   onClick={handleClick} />
});

const TeamRelevancyCellEdit = React.forwardRef(({cell, table, collection}, ref) => {
  const entity = cell.row.original;
  const relevancy = useEntityRelevancy(entity, collection);
  const relevancies = useEntityRelevancies(entity, collection);

  return <EntityTeamRelevancyTableCellEdit ref={ref}
                                           cell={cell}
                                           table={table}
                                           relevancy={relevancy}
                                           relevancies={relevancies}/>
});

const ExternalRelevancyCell = React.forwardRef(({cell, column, row, table, collection}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing)

  const entity = cell.row.original;
  const relevancyValue = useEntityExternalRelevancy(entity, collection);
  const relevancy = cell.column.columnDef.optimistic.get(cell, relevancyValue ?? 0);

  const handleClick = (e) => {
    table.setEditingCell(cell);
    e.preventDefault();
  }

  return <EntityRelevancyTableCell ref={ref}
                                   relevancy={relevancy}
                                   disabled={!enableEditing}
                                   onClick={handleClick} />
});

const ExternalRelevancyCellEdit = React.forwardRef(({cell, table, collection, updateCsi}, ref) => {
  const entity = cell.row.original
  const relevancyValue = useEntityExternalRelevancy(entity, collection);
  const relevancy = cell.column.columnDef.optimistic.get(cell, relevancyValue ?? 0);

  const handleChange = (entity, value) => {
    return updateCsi?.(entity, {relevancy: value});
  };

  return <EntityRelevancyTableCellEdit ref={ref}
                                       cell={cell}
                                       table={table}
                                       relevancy={relevancy}
                                       onChange={handleChange}/>
});

const PersonalRelevancyCell = React.forwardRef(({cell, column, row, table, collection}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing)

  const entity = cell.row.original;
  const relevancyValue = useEntityPersonalRelevancy(entity, collection);
  const relevancy = cell.column.columnDef.optimistic.get(cell, relevancyValue ?? 0);

  const handleClick = (e) => {
    table.setEditingCell(cell);
    e.preventDefault();
  }

  return <EntityRelevancyTableCell ref={ref}
                                   relevancy={relevancy}
                                   disabled={!enableEditing}
                                   onClick={handleClick} />
});

const PersonalRelevancyCellEdit = React.forwardRef(({cell, table, collection, updateCsi}, ref) => {
  const entity = cell.row.original;
  const relevancyValue = useEntityPersonalRelevancy(entity, collection);
  const relevancy = cell.column.columnDef.optimistic.get(cell, relevancyValue ?? 0);

  const handleChange = (entity, value) => {
    return updateCsi?.(entity, {relevancy: value});
  };

  return <EntityRelevancyTableCellEdit ref={ref}
                                       cell={cell}
                                       table={table}
                                       relevancy={relevancy}
                                       onChange={handleChange}/>
});

const TimelineCell = React.forwardRef(({cell, column, row, table}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing)

  const entity = cell.row.original;

  const handleClick = (e) => {
    table.setEditingCell(cell);
    e.preventDefault();
  }

  return <TimelineTableCell ref={ref}
                            comments={entity.totalComments}
                            unread={entity.unreadComments > 0}
                            disabled={!enableEditing}
                            onClick={handleClick}/>
});

const TimelineCellEdit = React.forwardRef(({cell, table, collection, canUpdate}, ref) => {
  const entity = cell.row.original;

  return <EntityTimelineTableCellEdit ref={ref}
                                      cell={cell}
                                      table={table}
                                      canUpdate={canUpdate}
                                      entity={entity}
                                      collection={collection}/>
});

const TractionCell = React.forwardRef(({cell, columnDef}, ref) => {
  const entity = cell.row.original;
  const value = entity[utils.camelcase(columnDef.name)];

  const icon = (utils.isDefined(value) && utils.toNumber(value) < 0) ?
    <Icon icon={Remove} color="error" size="smaller"/> : <Icon icon={Add} color="success" size="smaller"/>;

  const title = !utils.isDefined(value) ? null :
    utils.fieldValue2FormattedValue(columnDef.format, Math.abs(value));

  return <TextTableCell ref={ref}
                        title={title}
                        density="denser"
                        icon={utils.isDefined(value) ? icon : null}/>
});

const AnalysisScoreCell = React.forwardRef(({cell, column, row, table, collection, canUpdate, openProfile}, ref) => {
  const entity = cell.row.original;
  const isUniverse = utils.isDefined(collection?.universeClientId);

  const handleClick = (e) => {
    table.setEditingCell(null);
    openProfile(entity?.entityId, {activeCard: isUniverse ? 'clientTags' : 'collectionTags', hideProfile: true});
    e.preventDefault();
  }

  const handleHover = (e, data) => {
    const score = data?.[`${data?.dataKey}-meta`];
    if (score) {
      cell.column.columnDef.optimistic.set(cell, score);
      table.setEditingCell(cell);
    }
  }

  return <AnalysisScoreTableCell ref={ref}
                                 entity={entity}
                                 collection={collection}
                                 onHover={handleHover}
                                 onClick={canUpdate ? handleClick : null}/>
});

const AnalysisScoreCellEdit = React.forwardRef(({row, column, cell, table, columnDef,
                                                  fieldData, collection, canUpdate, openProfile}, ref) => {
  const entity = cell.row.original;
  const score = cell.column.columnDef.optimistic.get(cell, null);
  const isUniverse = utils.isDefined(collection?.universeClientId);

  const handleClick = (e) => {
    table.setEditingCell(null);
    openProfile(entity?.entityId, {activeCard: isUniverse ? 'clientTags' : 'collectionTags', hideProfile: true});
    e.preventDefault();
  }

  const handleEdit = () => {
    table.setEditingCell(null);
    openProfile(entity?.entityId, {activeCard: isUniverse ? 'clientTags' : 'collectionTags', hideProfile: true});
  }

  return <AnalysisScoreTableCellEdit ref={ref}
                                     cell={cell}
                                     table={table}
                                     entity={entity}
                                     collection={collection}
                                     score={score}
                                     fieldData={fieldData}
                                     onClick={canUpdate ? handleClick : null}
                                     onEdit={canUpdate ? handleEdit : null}/>
});

const EntitiesTable = React.forwardRef((props, ref) => {
  const {
    state,
    onCanUpdate,
    onCanRowClick,
    onRowClick,
    ...innerProps
  } = useComponentProps(props, 'EntitiesPage');

  const tableProvider = useTable();
  const list = tableProvider.list;
  const fieldData = tableProvider.fieldData;
  const context = tableProvider.context?.data;
  const columnDefinitions = tableProvider.columnDefinitions;

  const innerRef = useRef(null);
  const [internalState] = useState({});

  const client = useAuthClient();
  const universeCollection = useCollectionGet({collectionId: client?.universeCollectionId},
    {enabled: Boolean(client?.universeCollectionId)})?.data;
  const isUniverse = Boolean(context?.universeCollectionId === universeCollection?.collectionId);
  const collection = isUniverse ? universeCollection : context;

  const entitiesTable = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    state: {
      ...internalState, // the state from start, and internal toggle
      ...state // the override state (nulls are cleaned), see onToggle
    }
  }), [internalState, state]);

  useImperativeHandle(ref, () => entitiesTable);

  // mutations
  const authorize = useAuthorize();

  const updateEvent = useEffectEvent(tableProvider.updaters?.updateData);
  const updateTagEvent = useEffectEvent(tableProvider.updaters?.updateTag);
  const updateTagGroupEvent = useEffectEvent(tableProvider.updaters?.updateTagGroup);

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const updateCsiEvent = useEffectEvent(tableProvider.updaters?.updateCsi);

  const openProfileEvent = useEffectEvent(tableProvider.openProfile);

  const columns = useMemo(() => {
    const columns = innerProps.columns ? [...innerProps.columns] : [];
    if (columnDefinitions) {
      columnDefinitions
        .filter((columnDef) =>  !columns.find((c) => c.header === columnDef.header || c.name === columnDef.name))
        .forEach((columnDef) => {
          const canEditColumn = (entity) => {
            return !(columnDef.readOnly ?? false) &&
              (onCanUpdateEvent ? onCanUpdateEvent?.(entity, columnDef) :
                authorize(columnDef.auth?.update ? {...columnDef.auth?.update, meta: {
                    ...columnDef.auth?.update?.meta,
                    entity
                  }} : {})) &&
              authorize({
                attribute: 'entity.update',
                meta: {entity}
              });
          }

          if (columnDef.name === 'name') {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header,
              size: 240,
              minSize: 80,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <NameCell {...props} />,
              Edit: (props) => <NameCellEdit {...props} columnDef={columnDef} fieldData={fieldData} update={updateEvent}/>,
            })
          } else if (columnDef.name === 'tasks') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: columnDef.header,
              size: 1,
              muiTableHeadCellProps: {
                align: 'center',
              },
              muiTableBodyCellProps: {
                align: 'center',
              },
              enableEditing: false,
              enableSorting: columnDef.sortable !== false,
              Cell: (props) => <TasksCell {...props} />
            });
          } else if (columnDef.name === 'timeline') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: columnDef.header,
              size: 160,
              minSize: 160,
              enableEditing: true,
              enableSorting: columnDef.sortable !== false,
              Cell: (props) => <TimelineCell {...props} />,
              Edit: (props) => <TimelineCellEdit {...props}
                                                 collection={!isUniverse ? collection : null}
                                                 canUpdate={canEditColumn(props.row.original)} />
            });
          } else if (columnDef.name === 'personalRelevancy') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: columnDef.header ?? columnDef.name,
              size: 150,
              minSize: 150,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <PersonalRelevancyCell {...props}
                                                      collection={!isUniverse ? collection : null} />,
              Edit: (props) => <PersonalRelevancyCellEdit {...props}
                                                          collection={!isUniverse ? collection : null}
                                                          updateCsi={updateCsiEvent}/>
            })
          } else if (columnDef.name === 'teamRelevancy') {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header ?? columnDef?.customField?.label,
              size: 150,
              minSize: 150,
              enableSorting: columnDef.sortable !== false,
              enableEditing: true,
              Cell: (props) => <TeamRelevancyCell {...props}
                                                  collection={!isUniverse ? collection : null} />,
              Edit: (props) => <TeamRelevancyCellEdit {...props}
                                                      collection={!isUniverse ? collection : null} />
            })
          } else if (columnDef.name === 'externalRelevancy') {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header ?? columnDef?.customField?.label,
              size: 150,
              minSize: 150,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <ExternalRelevancyCell {...props}
                                                      collection={!isUniverse ? collection : null}/>,
              Edit: (props) => <ExternalRelevancyCellEdit {...props}
                                                          collection={!isUniverse ? collection : null}
                                                          updateCsi={updateCsiEvent}/>
            })
          } else if (columnDef.name === 'dealflowStatus') {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header ?? columnDef?.customField?.label,
              size: 240,
              minSize: 240,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <DealflowStatusCell {...props} />,
              Edit: (props) => <DealflowStatusCellEdit {...props} updateCsi={updateCsiEvent}/>
            })
          } else if (columnDef.name === 'dealLeader') {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              size: 240,
              minSize: 240,
              header: columnDef.header ?? columnDef?.customField?.label,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <DealLeaderCell {...props} />,
              Edit: (props) => <DealLeaderCellEdit {...props} updateCsi={updateCsiEvent}/>
            })
          } else if (columnDef.name === 'collectionAnalysisScoreGraph' || columnDef.name === 'clientAnalysisScoreGraph') {
            const isClient = columnDef.name === 'clientAnalysisScoreGraph';
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header,
              size: 300,
              enableSorting: columnDef.sortable !== false,
              enableEditing: true,
              Cell: (props) => <AnalysisScoreCell {...props} collection={isClient ? universeCollection : collection}
                                                  canUpdate={canEditColumn(props.row.original) && authorize({
                                                    attribute: 'entity.update',
                                                    meta: {entity: props.row.original}
                                                  })}
                                                  openProfile={openProfileEvent}/>,
              Edit: (props) => <AnalysisScoreCellEdit {...props} columnDef={columnDef}
                                                      collection={isClient ? universeCollection : collection}
                                                      fieldData={fieldData}
                                                      canUpdate={canEditColumn(props.row.original) && authorize({
                                                        attribute: 'entity.update',
                                                        meta: {entity: props.row.original}
                                                      })}
                                                      openProfile={openProfileEvent}/>,
            })
          } else if (columnDef.traction) {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header,
              enableSorting: columnDef.sortable !== false,
              enableEditing: false,
              Cell: (props) => <TractionCell {...props} columnDef={columnDef}/>
            })
          } else {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <FieldCell {...props} columnDef={columnDef} fieldData={fieldData}/>,
              Edit: (props) => <FieldCellEdit {...props} columnDef={columnDef} fieldData={fieldData}
                                              update={updateEvent} updateTag={updateTagEvent}
                                              updateTagGroup={updateTagGroupEvent}/>
            })
          }
        });
    }

    return columns.sort((a, b) => {
      const columnDefA = columnDefinitions.find((columnDef) => a.accessorKey === utils.camelcase(columnDef.name));
      const columnDefB = columnDefinitions.find((columnDef) => b.accessorKey === utils.camelcase(columnDef.name));

      return columnDefA.position - columnDefB.position;
    });
  }, [authorize, innerProps.columns, columnDefinitions, fieldData, isUniverse, collection, universeCollection, openProfileEvent,
    onCanUpdateEvent, updateEvent, updateTagEvent, updateCsiEvent, updateTagGroupEvent]);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesTable ref={innerRef} {...innerProps}
                              dataKey={tableProvider.dataKey}
                              enableColumnDragging={!smDown}
                              enableColumnOrdering={!smDown}
                              enableColumnResizing={!smDown}
                              enablePinning={!smDown}
                              enableColumnActions={!smDown}
                              listState={tableProvider.listState}
                              columnState={tableProvider.columnState}
                              listSelection={!smDown ? tableProvider.listSelection : null}
                              onFetchMore={list.query.fetchNextPage}
                              onResefetch={list.query.refetch}
                              onCanRowClick={onCanRowClick}
                              onRowClick={onRowClick}
                              columns={columns}
                              data={list.data}
                              rowCount={list.meta?.resultsCount}
                              enableScrollReset={true}
                              state={{
                                isLoading: list.status.isLoading,
                                showProgressBars: list.status.isLoadingNext
                              }} />
});

EntitiesTable.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  state: PropTypes.object,
  columns: PropTypes.array,
  onCanUpdate: PropTypes.func,
  onCanRowClick: PropTypes.func,
  onRowClick: PropTypes.func
};

EntitiesTable.defaultProps = {
  enableSorting: true,
  enableEditing: true,
  enableParentScroll: true,
  enableBottomToolbar: false,
  enableStickyHeader: true,
  enableColumnDragging: true,
  enableColumnOrdering: true,
  enableColumnResizing: true,
  enablePinning: true
};

export default EntitiesTable;
