import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOptimistic} from 'helpers/hooks/utils';
import StyledCollectionEntitySectionPanel
  from 'components/organisms/SectionPanels/CollectionEntitySectionPanel/CollectionEntitySectionPanel.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import EntityQuestionnaireSectionPanelContent
  from 'components/organisms/SectionPanels/EntityQuestionnaireSectionPanelContent/EntityQuestionnaireSectionPanelContent';
import RelevancyContextCard from 'components/organisms/Cards/RelevancyContextCard/RelevancyContextCard';
import {
  useEntityCsi,
  useEntityPersonalRelevancy,
  useEntityExternalRelevancy
} from 'services/entity/entity.utils';
import DealflowStatusContextCard from 'components/organisms/Cards/DealflowStatusContextCard/DealflowStatusContextCard';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import CollectionSelectionDialog
  from 'components/organisms/Dialogs/CollectionSelectionDialog/CollectionSelectionDialog';
import EntityTimelineSectionPanelContent
  from 'components/organisms/SectionPanels/EntityTimelineSectionPanelContent/EntityTimelineSectionPanelContent';
import EntityTasksContextCard from 'components/organisms/Cards/EntityTasksContextCard/EntityTasksContextCard';
import CollectionEntityProfile from 'components/organisms/Profiles/CollectionEntityProfile/CollectionEntityProfile';
import EntityAddSectionPanelContent
  from 'components/organisms/SectionPanels/EntityAddSectionPanelContent/EntityAddSectionPanelContent';
import SectionPanelFooter from 'components/molecules/SectionPanels/SectionPanelFooter/SectionPanelFooter';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';

const CollectionEntitySectionPanel = React.forwardRef((props, ref) => {
  const {
    showProfile,
    newProfile,
    onNext,
    onClose,
    onCloseCard,
    ...innerProps
  } = useComponentProps(props, 'CollectionEntitySectionPanel');

  const profileProvider = useProfile();
  const isSuggestion = profileProvider?.view?.name === 'suggestions';
  const sectionDefinitions = profileProvider?.sectionDefinitions;
  const collection = profileProvider?.context?.data;

  const dialogControl = useDialogControl();
  const authorize = useAuthorize();

  const snackbar = useSnackbar();
  const setSettingsEvent = useEffectEvent(profileProvider?.setSettings);
  const openTaskEvent = useEffectEvent(profileProvider.openTask);
  const updateCsiEvent = useEffectEvent(profileProvider.updaters?.updateCsi);
  const entityUnAssignEvent = useEffectEvent(profileProvider.updaters?.unAssignItems);
  const entityAssignEvent = useEffectEvent(profileProvider.updaters?.assignItems);
  const entityAcceptEvent = useEffectEvent(profileProvider.updaters?.acceptItems);

  const onNextEvent = useEffectEvent(onNext);
  const onCloseEvent = useEffectEvent(onClose);
  const onCloseCardEvent = useEffectEvent(onCloseCard);
  const sections = useMemo(() => {
    if (sectionDefinitions) {
      const sections = [];

      sectionDefinitions
        .sort((a, b) => a.position - b.position)
        .forEach((sectionDef) => {
          const canEditSection = (entity) => {
            return !(sectionDef.readOnly ?? false) && authorize({...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, collection, entity}});
          }
          const canEditCard = (card, entity) => {
            return canEditSection(entity) && !(card.readOnly ?? false) && authorize({
              ...card.auth?.update,
              meta: {
                ...card.auth?.update?.meta,
                collection, entity}
            });
          }

          if (newProfile) {
            if (sectionDef.name === 'profile') {
              sections.push({
                ...sectionDef,
                Content: () => {
                  return <EntityAddSectionPanelContent key={newProfile}
                                                       collection={collection}
                                                       onNext={onNextEvent}
                                                       onClose={onCloseEvent}/>
                }
              });
            }
          } else {
            if (sectionDef.name === 'profile' && showProfile) {
              sections.push({
                ...sectionDef,
                Content: () => {
                  return <CollectionEntityProfile gap={8} columns={1}
                                                  onCloseCard={onCloseCardEvent}
                                                  onCanUpdate={canEditSection}/>
                }
              });
            } else if (sectionDef.name === 'options') {
              sections.push({
                ...sectionDef,
                cards: sectionDef.cards?.reduce((a, card) => {
                  if (card.name === 'personalRelevancy') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const relevancyValue = useEntityPersonalRelevancy(data, collection);
                        const [relevancy, toggleRelevancyOptimistic] = useOptimistic(relevancyValue || 0, updateCsiEvent);

                        const handleChange = (value) => {
                          return toggleRelevancyOptimistic?.(value, data, {
                            relevancy: value,
                            collection_id: collection?.collectionId
                          }).catch((err) => {
                            snackbar.show('Saving relevance failed', null,
                                {color: 'error', autoHideDuration: constants.delay.error});

                            throw err;
                          })
                        };

                        return <RelevancyContextCard relevancy={relevancy}
                                                     onChange={handleChange}
                                                     canUpdate={canEditCard(card, data)}
                                                     isLoading={isLoading}/>
                      }
                    });
                  } else if (card.name === 'externalRelevancy') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const relevancyValue = useEntityExternalRelevancy(data, collection);
                        const [relevancy, toggleRelevancyOptimistic] = useOptimistic(relevancyValue || 0, updateCsiEvent);

                        const handleChange = (value) => {
                          return toggleRelevancyOptimistic?.(value, data, {
                            relevancy: value,
                            collection_id: collection?.collectionId
                          }).catch((err) => {
                            snackbar.show('Saving relevance failed', null,
                                {color: 'error', autoHideDuration: constants.delay.error});

                            throw err;
                          })
                        };

                        return <RelevancyContextCard relevancy={relevancy}
                                                     title="External relevance"
                                                     onChange={handleChange}
                                                     canUpdate={canEditCard(card, data)}
                                                     isLoading={isLoading}/>
                      }
                    });
                  } else if (card.name === 'tasks') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const switched = Boolean(profileProvider?.state?.settings?.tasksSwitched);

                        const handleSwitch = (switched) => {
                          setSettingsEvent?.({tasksSwitched: switched});
                        }

                        const handleClick = (e, task) => {
                          if (!e.defaultPrevented) {
                            openTaskEvent?.(task.commentId);
                          }
                        }

                        return <EntityTasksContextCard entity={data}
                                                       collection={collection}
                                                       switched={switched}
                                                       onSwitch={handleSwitch}
                                                       onClick={handleClick}
                                                       canUpdate={canEditCard(card, data)}
                                                       isLoading={isLoading}/>
                      }
                    });
                  } else if (card.name === 'dealflowStatus') {
                    a.push({
                      ...card,
                      Card: ({data, isLoading}) => {
                        const csi = useEntityCsi(data);
                        const [statusId, toggleStatusOptimistic] = useOptimistic(csi?.status || 0, updateCsiEvent);

                        const handleChange = (value) => {
                          return toggleStatusOptimistic?.(value, data, {
                            status: value,
                            collectionId: collection.collectionId
                          }).catch((err) => {
                            snackbar.show('Saving status failed', null,
                                {color: 'error', autoHideDuration: constants.delay.error});

                            throw err;
                          })
                        };

                        return <DealflowStatusContextCard statusId={statusId}
                                                          onChange={handleChange}
                                                          canUpdate={canEditCard(card, data)}
                                                          isLoading={isLoading}/>
                      }
                    });
                  }

                  return a;
                }, []),
                Footer: ({data, isLoading}) => {
                  const collections = data?.collections;
                  const isPartOfCollection = Boolean(collections?.find((c) => +c.collectionId === +collection?.collectionId));

                  const buttonActions = useMemo(() => ([
                    {
                      auth: utils.createAuth({attribute: 'collection.update'}),
                      onClick: () => {
                        if (isSuggestion) {
                          entityUnAssignEvent?.({collectionId: collection.collectionId, entityIds: [data.entityId]})
                            .catch(() => {
                              snackbar.show(`Removing suggestion '${data.name}' from collection '${collection.name}' failed`, null,
                                {color: 'error', autoHideDuration: constants.delay.error});
                            });
                        } else {
                          const handleConfirm = () => {
                            return entityUnAssignEvent?.({entityId: data.entityId})
                              .catch(() => {
                                snackbar.show('Removing company from collection failed', null,
                                  {color: 'error', autoHideDuration: constants.delay.error});
                              });
                          }

                          const question = `Are you sure you want to remove '${data.name}' from '${collection.name}'?`;

                          dialogControl.show(<ConfirmDialog question={question}
                                                            explanation="The company will only be removed from this collection but will remain in the database"
                                                            onConfirm={handleConfirm}
                                                            ConfirmButtonProps={{
                                                              children: 'Remove company',
                                                              color: 'error'
                                                            }}/>, true);
                        }
                      },
                      label: isSuggestion ? 'Remove suggestion' : 'Remove from collection',
                      icon: Delete,
                      ActionButtonProps: {
                        color: 'error',
                        radius: 'round',
                        fullWidth: true
                      },
                      ButtonProps: {
                        disabled: !isSuggestion && !isPartOfCollection
                      }
                    },
                    {
                      auth: utils.createAuth({attribute: 'collection.update'}),
                      onClick: () => {
                        const handleSubmit = (collections) => {
                          if (collections.length > 0) {
                            const message = `Adding the company to the collection${collections.length === 1 ? '' : 's'}`;
                            return utils.observePromise(Promise.all(collections.map((collection) => {
                              return entityAssignEvent?.({
                                collectionId: collection.collectionId,
                                entityId: data.entityId
                              })
                            })), () => {
                              return snackbar.show(`${message}...`, null, {
                                color: 'info',
                                autoHideDuration: null
                              });
                            }, () => {
                              return snackbar.show(`The company was added to the collection${collections.length === 1 ? '' : 's'}`, null,
                                {color: 'success', autoHideDuration: constants.delay.success});
                            }, () => {
                              return snackbar.show(`${message} failed`, null,
                                {color: 'error', autoHideDuration: constants.delay.error});
                            }, (id) => {
                              snackbar.hide(id);
                            });
                          }
                        };

                        if (!isPartOfCollection) {
                          if (isSuggestion) {
                            entityAcceptEvent?.({
                              collectionId: collection?.collectionId,
                              entityId: data.entityId
                            })
                              .catch(() => {
                                snackbar.show(`Adding company '${data.name}' to collection '${collection.name}' failed`, null,
                                  {color: 'error', autoHideDuration: constants.delay.error});
                              });
                          } else {
                            entityAssignEvent?.({
                              collectionId: collection?.collectionId,
                              entityId: data.entityId
                            })
                              .catch(() => {
                                snackbar.show(`Adding company '${data.name}' to collection '${collection.name}' failed`, null,
                                  {color: 'error', autoHideDuration: constants.delay.error});
                              });
                          }
                        } else {
                          dialogControl.show(<CollectionSelectionDialog title="Add to collection"
                                                                        subtitle="For 1 company"
                                                                        collections={collections}
                                                                        hideCollections={true}
                                                                        onSubmit={handleSubmit}/>, true);
                        }
                      },
                      label: isPartOfCollection ? 'Add to other collection' : 'Add to collection',
                      icon: Add,
                      ActionButtonProps: {
                        color: 'primary',
                        radius: 'round',
                        fullWidth: true
                      }
                    }
                  ]), [data?.entityId, data?.name, collections, isPartOfCollection]);

                  return <SectionPanelFooter className="CollectionEntitySectionPanel-options-footer"
                                             orientation="vertical"
                                             buttons={buttonActions.map?.((action, idx) => {
                                               return <ActionButton key={idx} action={action} isLoading={isLoading}/>
                                             })} />
                }
              });
            } else if (sectionDef.name === 'questionnaire') {
              sections.push({
                ...sectionDef,
                Content: ({data, onDirty, isLoading}) => {
                  return <EntityQuestionnaireSectionPanelContent collection={collection}
                                                                 entity={data}
                                                                 canUpdate={canEditSection(data)}
                                                                 onDirty={onDirty}
                                                                 isLoading={isLoading}/>
                },
                hidden: ({data}) => {
                  return !Boolean(data?.hasActiveQuestions);
                }
              });
            } else if (sectionDef.name === 'timeline') {
              sections.push({
                ...sectionDef,
                Content: ({data, isLoading}) => {
                  return <EntityTimelineSectionPanelContent collection={collection}
                                                            entity={data}
                                                            canUpdate={canEditSection(data)}
                                                            isLoading={isLoading}/>
                }
              });
            }
          }
        });

      return sections;
    } else {
      return [];
    }
  }, [authorize, dialogControl, snackbar, sectionDefinitions, collection, showProfile, newProfile, isSuggestion,
    profileProvider?.state?.settings?.tasksSwitched, setSettingsEvent, onCloseEvent, onCloseCardEvent, onNextEvent,
    openTaskEvent, updateCsiEvent, entityUnAssignEvent, entityAssignEvent, entityAcceptEvent]);

  return <StyledCollectionEntitySectionPanel ref={ref} {...innerProps}
                                             sections={sections}
                                             onClose={onClose}
                                             onCloseCard={onCloseCard}
                                             showProfile={showProfile}
                                             newProfile={newProfile}/>
});

CollectionEntitySectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onClose: PropTypes.func,
  onCloseCard: PropTypes.func,
  showProfile: PropTypes.bool,
  newProfile: PropTypes.any
};

CollectionEntitySectionPanel.defaultProps = {};

export default CollectionEntitySectionPanel;
