import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useTableReset} from 'helpers/hooks/utils';
import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {useLinkNavigate, useSplitPath} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionEntitiesBarWrapper
  from 'components/templates/Wrappers/Collections/CollectionEntitiesBarWrapper/CollectionEntitiesBarWrapper.styles';
import Settings from '@mui/icons-material/Settings';
import {CheckList, Rejections, Suggestions} from 'assets/icons';
import Badge from 'components/atoms/Badges/Badge/Badge';
import {H4} from 'components/atoms/Text/Typography/Typography';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import FolderSharp from '@mui/icons-material/FolderSharp';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import {useCollectionTimelineList} from 'services/collection/timeline/timeline.hooks';
import CollectionNotificationList
  from 'components/organisms/Lists/CollectionNotificationList/CollectionNotificationList';
import CollectionEntitiesViewBar from 'components/organisms/Bars/CollectionEntitiesViewBar/CollectionEntitiesViewBar';
import {useCollectionRejectionList, useCollectionSuggestionList} from 'services/collection/entity/entity.hooks';
import constants from 'helpers/constants';
import UploadFile from '@mui/icons-material/UploadFile';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntitiesUploadDialog from 'components/organisms/Dialogs/EntitiesUploadDialog/EntitiesUploadDialog';
import {useCollectionUploadCreate} from 'services/collection/upload/upload.utils';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import FieldInline from 'components/molecules/Inlines/FieldInline/FieldInline';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import CallMerge from '@mui/icons-material/CallMerge';
import CollectionMergeDialog from 'components/organisms/Dialogs/CollectionMergeDialog/CollectionMergeDialog';
import {useCollectionMerge} from 'services/collection/collection.hooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/icons-material/List';
import Equalizer from '@mui/icons-material/Equalizer';
import {ArticleOutlined, TravelExplore} from '@mui/icons-material';

const CollectionEntitiesBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesBarWrapper');

  const profileProvider = useProfile();
  const tableProvider = useTable();

  const snackbar = useSnackbar();
  const navigate = useLinkNavigate();
  const dialogControl = useDialogControl();
  const mergeCollection = useCollectionMerge();
  const createUpload = useCollectionUploadCreate();
  const authorize = useAuthorize();

  const collection = tableProvider.context?.data;
  const selectedEntityId = tableProvider.selected()?.entityId;
  const isLoading = !collection;
  const isTableEmpty = tableProvider?.list?.data?.length === 0;

  const [pathPrefix, pathPostfix] = useSplitPath('entities');

  useTableReset(pathPrefix, pathPostfix);

  const suggestionList = useCollectionSuggestionList({
    collectionId: collection?.collectionId,
    feedUpdatedAt: collection?.feedUpdatedAt,
    pageSize: 0
  }, {
    enabled: collection?.collectionId > 0 && !tableProvider.isLoading() && utils.isDefined(tableProvider?.list?.data)
  });
  const suggestionCount = suggestionList.meta?.resultsCount ?? 0;

  const rejectionList = useCollectionRejectionList({
    collectionId: collection?.collectionId,
    pageSize: 0
  }, {
    enabled: collection?.collectionId > 0 && !tableProvider.isLoading() && utils.isDefined(tableProvider?.list?.data)
  });
  const rejectionCount = rejectionList.meta?.resultsCount ?? 0;

  const notificationList = useCollectionTimelineList({
    collectionId: collection?.collectionId,
    filter: [
      {id: 'notification', value: true},
      {id: 'mentioned', value: true},
      {id: 'excludedTypes', value: ['additions', 'status', 'csi', 'deal_leader']},
    ],
    pageSize: 0
  }, {
    enabled: collection?.collectionId > 0 && !tableProvider.isLoading() && utils.isDefined(tableProvider?.list?.data),
    matchCallback: () => {
      // custom match to refetch on all watches that are invalidated to update the number
      return true;
    }
  });
  const notificationCount = notificationList.meta?.resultsCount ?? 0;

  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const leftActions = useMemo(() => [
    {
      navigation: {
        to: '/collections'
      },
      tooltip: 'Back',
      icon: ArrowBack,
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      }
    },
  ], []);

  const openTableProfileEvent = useEffectEvent(tableProvider.openProfile);
  const openProfileEvent = useEffectEvent(profileProvider.openProfile);
  const rightActions = useMemo(() => {
    const actions = [];

    if (!(smDown || mdDown)) {
      actions.push({
        auth: utils.createAuth({attribute: 'collection.suggestions', meta: {collection: collection}}),
        onClick: (e) => {
          navigate({
            event: e,
            to: `?custom=suggestions`,
            keepSearchParams: false
          });
        },
        icon: Suggestions,
        badge: <Badge isLoading={isLoading}
                      max={999}
                      badgeContent={suggestionCount || '0'}
                      color="primary"/>,
        ...(xlUp ? {
          label: 'Suggestions',
          ActionButtonProps: {
            color: 'secondary',
            variant: 'outlined',
            plainBorder: true,
            radius: 'round',
            BadgeProps: {
              isLoading: rejectionList.status.isLoading
            }
          },
          ButtonProps: {
            disabled: !(suggestionCount > 0)
          }
        } : {
          ActionIconButtonProps: {
            color: 'default',
            variant: 'outlined',
            size: 'smaller',
            density: 'sparse',
            IconProps: {
              size: 'smaller'
            },
            BadgeProps: {
              isLoading: rejectionList.status.isLoading
            }
          },
          IconButtonProps: {
            disabled: !(suggestionCount > 0)
          }
        })
      });

      actions.push({
        auth: utils.createAuth({ attribute: 'collection.rejections' }),
        onClick: (e) => {
          navigate({
            event: e,
            to: `?custom=rejections`,
            keepSearchParams: false
          });
        },
        icon: Rejections,
        tooltip: 'Rejections',
        ActionIconButtonProps: {
          color: 'default',
          variant: 'outlined',
          size: 'smaller',
          density: 'sparse',
          IconProps: {
            size: 'smaller',
            color: rejectionCount > 0 ? 'error' : 'default'
          },
          BadgeProps: {
            isLoading: rejectionList.status.isLoading
          }
        },
        IconButtonProps: {
          disabled: !(rejectionCount > 0)
        },
        badge: <Badge badgeContent={rejectionCount || '0'}
                      max={999}
                      color={rejectionCount > 0 ? 'error' : 'light'}/>
      });

      actions.push({
        auth: utils.createAuth({ attribute: 'collection.notifications' }),
        onClick: () => {},
        icon: CheckList,
        tooltip: 'Notifications',
        popper: <CollectionNotificationList collection={collection} />,
        ActionIconButtonProps: {
          color: 'default',
          variant: 'outlined',
          size: 'smaller',
          density: 'sparse',
          IconProps: {
            size: 'smaller'
          },
          BadgeProps: {
            isLoading: notificationList.status.isLoading
          },
          DropDownPopperProps: {
            ContextPopperProps: {
              density: 'densest',
              placement: 'bottom-end',
              closeOnNavigate: true,
              strategy: 'fixed'
            }
          }
        },
        IconButtonProps: {
          disabled: isLoading
        },
        badge: <Badge badgeContent={notificationCount || '0'} color="light"/>
      });

      actions.push({
        auth: utils.createAuth({attribute: 'collection.merge', meta: {collection}}),
        tooltip: 'Merge collection',
        icon: CallMerge,
        onClick: (e) => {
          const handleSubmit = (data) => {
            return mergeCollection.mutation.mutateAsync({
              collectionId: data.targetCollectionId,
              mergedCollectionId: data.mergedCollectionId,
              deleteMergedGroupIds: data.deleteMergedGroupIds,
              deleteTargetGroupIds: data.deleteTargetGroupIds,
              deleteMergedSourceIds: data.deleteMergedSourceIds,
              deleteTargetSourceIds: data.deleteTargetSourceIds
            });
          };

          dialogControl.show(<CollectionMergeDialog collection={collection} onSubmit={handleSubmit} />, true);

          e.preventDefault();
        },
        ActionIconButtonProps: {
          color: 'default',
          variant: 'outlined',
          size: 'smaller',
          density: 'sparse',
          IconProps: {
            size: 'smaller'
          }
        },
        IconButtonProps: {
          disabled: isLoading
        }
      });
    }

    actions.push({
      auth: utils.createAuth({ attribute: 'collection.settings', meta: {collection: collection} }),
      onClick: () => {
        openProfileEvent?.();
      },
      icon: Settings,
      tooltip: 'Settings',
      ActionIconButtonProps: {
        color: 'default',
        variant: 'outlined',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      },
      IconButtonProps: {
        disabled: isLoading
      }
    });

    actions.push({
      label: !smDown ? 'Add company' : null,
      tooltip: smDown ? 'Add company' : null,
      auth: utils.createAuth({attribute: 'collection.entity.create', meta: {collection: collection} }),
      icon: Add,
      ActionButtonProps: !smDown ? {
        color: 'success',
        variant: 'contained',
        radius: 'round',
        DropDownPopperProps: {
          ContextPopperProps: {
            density: 'densest',
            transparent: true,
            stretch: true,
            strategy: 'fixed',
            offset: [0, 8]
          }
        }
      } : null,
      ActionIconButtonProps: smDown ? {
        color: 'success',
        variant: 'contained',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        },
        DropDownPopperProps: {
          ContextPopperProps: {
            density: 'densest',
            transparent: true,
            stretch: true,
            strategy: 'fixed',
            offset: [0, 8]
          }
        }
      } : null,
      children: [
        {
          label: !smDown ? 'Upload' : null,
          tooltip: smDown ? 'Upload' : null,
          icon: UploadFile,
          ActionIconButtonProps: smDown ? {
            color: 'primary',
            variant: 'contained',
            size: 'smaller',
            density: 'sparse',
            IconProps: {
              size: 'smaller'
            }
          }: null,
          onClick: () => {
            const handleSubmit = (upload) => {
              return createUpload({
                name: upload.name,
                params: upload.params,
                period: upload.period,
                subType: '',
                type: upload.type
              }, collection)
                .then(() => {
                  snackbar.show('File upload is scheduled', null,
                    {color: 'success', autoHideDuration: constants.delay.success});
                  dialogControl.hide();
                });
            };

            const handleClose = () => {
              dialogControl.hide();
            };

            dialogControl.show(<EntitiesUploadDialog onSubmit={handleSubmit}
                                                     EntitiesUploadWizardProps={{collection}}/>, true, handleClose);
          },
        },
        {
          label: !smDown ? 'Manual' : null,
          tooltip: smDown ? 'Manual' : null,
          icon: Add,
          ActionIconButtonProps: smDown ? {
            color: 'primary',
            variant: 'contained',
            size: 'smaller',
            density: 'sparse',
            IconProps: {
              size: 'smaller'
            }
          }: null,
          onClick: () => {
            openTableProfileEvent?.(-1);
          },
        }
      ]
    });

    return actions;
  }, [
    snackbar, navigate, dialogControl, smDown, mdDown, xlUp, createUpload, collection, suggestionCount,
    isLoading, rejectionCount, rejectionList.status.isLoading, mergeCollection.mutation,
    notificationCount, notificationList.status.isLoading, openProfileEvent, openTableProfileEvent
  ]);

  const toggleActions = useMemo(() => {
    return [
      {
        label: !(smDown || lgDown) ? 'Table' : null,
        tooltip: (smDown || lgDown) ? 'Table' : null,
        icon: (smDown || lgDown) ? List : null,
        navigation: {
          to: pathPrefix,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        }
      },
      {
        label: !(smDown || lgDown) ? 'Profile' : null,
        tooltip: (smDown || lgDown) ? 'Profile' : null,
        icon: (smDown || lgDown) ? ArticleOutlined : null,
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        },
        ToggleButtonProps: {
          disabled: isTableEmpty || !selectedEntityId
        }
      },
      {
        label: !(smDown || lgDown) ? 'Browser' : null,
        tooltip: (smDown || lgDown) ? 'Browser' : null,
        icon: (smDown || lgDown) ? TravelExplore : null,
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}/browser`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        },
        ToggleButtonProps: {
          disabled: isTableEmpty || !selectedEntityId
        }
      },
      {
        label: !(smDown || lgDown) ? 'Graphs' : null,
        tooltip: (smDown || lgDown) ? 'Graphs' : null,
        icon: (smDown || lgDown) ? Equalizer : null,
        navigation: {
          to: `${pathPrefix}/graphs`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        }
      },
    ];
  }, [pathPrefix, selectedEntityId, isTableEmpty, smDown, lgDown]);

  const nameFields = useMemo(() => [{
    name: 'name',
    placeholder: 'Type a collection name',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: collection?.name,
    required: true,
    FormFieldProps: {
      hiddenLabel: true,
      showTooltip: true,
      variant: 'outlined',
      size: 'small',
      InputLabelProps: {
        shrink: true,
      }
    }
  }], [collection?.name]);

  const handleNameChange = (field, value, onSuccess, onError) => {
    profileProvider.updaters.updateData(collection, {[field.name]: value})
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.();
      });
  }

  const renderTitle = () => {
    if (!xsDown) {
      return <Box className="CollectionEntitiesBarWrapper-title">
        <Icon icon={FolderSharp} isLoading={isLoading} color="primary"/>
        <H4 isLoading={isLoading} min={8} max={20}>
          {!isLoading ? <FieldInline fields={nameFields}
                                     canUpdate={authorize({attribute: 'collection.update', meta: {collection}})}
                                     onChange={handleNameChange}
                                     DropDownPopperProps={{
                                       ContextPopperProps: {
                                         offset: [-32, 0],
                                         minWidth: 340
                                       }
                                     }}/> : null}
        </H4>
      </Box>
    }
  }

  return <StyledCollectionEntitiesBarWrapper ref={ref} {...innerProps}
                                             ToolbarProps={{
                                               title: renderTitle(),
                                               leftActions,
                                               rightActions,
                                               toggleActions,
                                               ViewBarComponent: CollectionEntitiesViewBar,
                                               isLoading
                                             }}>
    {innerProps.children}
  </StyledCollectionEntitiesBarWrapper>
});

CollectionEntitiesBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesBarWrapper.defaultProps = {
  children: 'CollectionEntitiesBarWrapper text',
};

export default CollectionEntitiesBarWrapper;
