import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import Component from 'components/organisms/Utils/Component/Component';
import ThemeCard from 'components/molecules/Cards/ThemeCard/ThemeCard';
import StyledThemesList from 'components/organisms/Lists/ThemesList/ThemesList.styles';

const ThemesList = React.forwardRef((props, ref) => {
  const {
    theme,
    themes,
    Card,
    onClick,
    isLoading,
    ListProps,
    ThemeCardProps,
    ...innerProps
  } = useComponentProps(props, 'ThemesList');

  const ThemeCardDefault = ({theme, ...props}) => {
    return <ThemeCard themeData={theme}
                      fullHeight={innerProps.orientation === 'horizontal'}
                      fullWidth={innerProps.orientation !== 'vertical'}
                      {...props}/>
  };

  const CardMemo = useEffectItem(Card ?? ThemeCardDefault);
  const renderCard = (theme, state) => {
    return <Component Original={CardMemo}
                      themeData={theme}
                      selected={state.selected}
                      onClick={onClick}
                      isLoading={state.isLoading}
                      {...ThemeCardProps} />
  };

  const isItemEqual = (task, selected) => {
    return +theme.themeId === +selected.themeId;
  }

  return <StyledThemesList ref={ref} {...innerProps}
                           data={themes}
                           count={themes?.length}
                           dataKey="themeId"
                           isItemEqual={isItemEqual}
                           renderItem={renderCard}
                           isLoading={isLoading}
                           ListProps={{
                             track: true,
                             virtualize: true,
                             catchFocus: false,
                             ...ListProps
                           }}/>
});

ThemesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  theme: PropTypes.object,
  themes: PropTypes.object,
  Card: PropTypes.any,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  ListProps: PropTypes.object,
  ThemeCardProps: PropTypes.object
};

ThemesList.defaultProps = {
};

export default ThemesList;
