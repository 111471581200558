import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntityQuestionnaireSectionPanelContent
  from 'components/organisms/SectionPanels/EntityQuestionnaireSectionPanelContent/EntityQuestionnaireSectionPanelContent.styles';
import {
  useCollectionQuestionnaireList,
  useCollectionQuestionnaireStatsList
} from 'services/collection/questionnaire/questionnaire.hooks';
import utils from 'helpers/utils';
import DataList from 'components/organisms/Lists/DataList/DataList';
import {useCollectionQuestionList} from 'services/collection/question/question.hooks';
import QuestionnaireContextCard from 'components/organisms/Cards/QuestionnaireContextCard/QuestionnaireContextCard';

const EntityQuestionnaireSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    canUpdate,
    onDirty,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntityQuestionnaireSectionPanelContent');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const questionnaires = useCollectionQuestionnaireList({
    collectionId: collection?.collectionId,
    filter: [{id: 'active', value: true}],
    sort: [{id: 'startAt', desc: false}, {id: 'endAt', desc: false}, {id: 'createdAt', desc: false}],
    page: 0,
    pageSize: 1000,
    minimal: false
  }, {
    enabled: Boolean(collection?.collectionId)
  });

  const questionnaireStats = useCollectionQuestionnaireStatsList({
    collectionId: collection?.collectionId,
    questionnaireIds: questionnaires.data?.map((qn) => qn.questionnaireId)
  }, {
    refetchInvalidQuery: true, // refetch data if active always
    enabled: collection?.collectionId > 0 && questionnaires.data?.length > 0
  });

  const questionIds = useMemo(() => {
    if (questionnaires.data) {
      return utils.uniqueArray(
        questionnaires.data
          .reduce((a, qn) => a.concat(qn.questionIds), [])
          .sort((a, b) => +a - +b));
    } else {
      return [];
    }
  }, [questionnaires.data]);

  const questions = useCollectionQuestionList({
    collectionId: collection?.collectionId,
    filter: [{id: 'questionIds', value: questionIds}],
    page: 0,
    pageSize: questionIds.length
  }, {
    enabled: Boolean(collection?.collectionId) && questionIds.length > 0
  });

  const data = useMemo(() => {
    if (questionnaires.data && questions.data) {
      return questionnaires.data.reduce((a, questionnaire) => {
        (questionnaire.questionIds ?? []).forEach((qId, qIdx) => {
          const active = entity.activeQuestions?.find((q) => +q.questionId === +qId && +q.questionnaireId === +questionnaire.questionnaireId);
          const open = entity.openQuestions?.find((q) => +q.questionId === +qId && +q.questionnaireId === +questionnaire.questionnaireId);

          if (active || open) {
            const question = questions.data.find((q) => +q.questionId === +qId);
            if (question) {
              let q = a.find((q) => +q.questionId === +qId);
              if (!q) {
                q = {
                  id: `${entity.entityId}_${question.questionId}`,
                  questionId: +question.questionId,
                  questionnaires: [],
                  openQuestionnaireIds: [],
                  question,
                  open: false,
                  position: qIdx
                }
                a.push(q);
              }
              q.open = q.open || Boolean(open);
              q.questionnaires.push(questionnaire);
              if (Boolean(open)) {
                q.openQuestionnaireIds.push(questionnaire.questionnaireId);
              }
            }
          }
        });

        return a;
      }, [])
        .sort((a, b) => a.position - b.position)
    }
  }, [entity, questionnaires.data, questions.data]);

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.querySelector('.DataList-list')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [entity?.entityId]);

  const renderItem = (item, state) => {
    return <QuestionnaireContextCard entity={entity}
                                     collection={collection}
                                     question={item.question}
                                     questionnaires={item.questionnaires}
                                     openQuestionnaireIds={item.openQuestionnaireIds}
                                     questionnaireStats={questionnaireStats.data}
                                     variant={(item.loader || item.open) ? 'question' : 'statistics'}
                                     canUpdate={canUpdate}
                                     selected={state.selected}
                                     onDirty={onDirty}
                                     isLoading={state.isLoading}/>
  };

  const isContentLoading = isLoading || questionnaires.status.isLoading || questionnaireStats.status.isLoading ||
    questions.status.isLoading || !utils.isDefined(data);

  return <StyledEntityQuestionnaireSectionPanelContent ref={innerRef} {...innerProps}>
    <DataList data={data}
              count={data?.length}
              dataKey="id"
              renderItem={renderItem}
              loaderCount={8}
              isLoading={isContentLoading}
              ListProps={{
                virtualize: false,
                gap: 32
              }} />
  </StyledEntityQuestionnaireSectionPanelContent>
});

EntityQuestionnaireSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  entity: PropTypes.object,
  canUpdate: PropTypes.bool,
  onDirty: PropTypes.func,
  isLoading: PropTypes.bool
};

EntityQuestionnaireSectionPanelContent.defaultProps = {};

export default EntityQuestionnaireSectionPanelContent;
