import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import TimelineList from 'components/organisms/Lists/TimelineList/TimelineList';
import UpdateForm from 'components/organisms/Forms/UpdateForm/UpdateForm';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';
import Box from 'components/atoms/Layout/Box/Box';
import StyledTimelinePanel from 'components/organisms/Panels/TimelinePanel/TimelinePanel.styles';
import utils from 'helpers/utils';

const TimelinePanel = React.forwardRef((props, ref) => {
  const {
    timeline,
    canUpdate,
    onSubmit,
    variant,
    isLoading,
    TimelineListProps,
    ...innerProps
  } = useComponentProps(props, 'TimelinePanel');

  const isItemEqual = (update, selected) => {
    return +update.timelineId === +selected.timelineId;
  }

  return <StyledTimelinePanel ref={ref} {...innerProps}>
    <ListWrapper footer={canUpdate ? <UpdateForm className="TimelinePanel-form"
                                                 onSubmit={onSubmit}
                                                 variant={variant}
                                                 isLoading={isLoading}/> : null}>
      <Box className="TimelinePanel-feed">
        <TimelineList count={timeline?.meta?.resultsCount}
                      timeline={timeline.data}
                      dataKey="timelineId"
                      emptyText="No timeline items found"
                      isItemEqual={isItemEqual}
                      onFetchMore={timeline.query?.fetchNextPage}
                      isLoading={isLoading || timeline.status?.isLoading || !utils.isDefined(timeline.data)}
                      showProgressBar={timeline.status?.isLoadingNext}
                      {...TimelineListProps}/>
      </Box>
    </ListWrapper>
  </StyledTimelinePanel>
});

TimelinePanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  timeline: PropTypes.object,
  canUpdate: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  TimelineListProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'simple']), PropTypes.string])
};

TimelinePanel.defaultProps = {};

export default TimelinePanel;
