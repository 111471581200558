import utils from 'helpers/utils';
import logger from 'helpers/logger';
import createTheme from '@mui/material/styles/createTheme';
import shadows from 'theme/shadows/shadows.json';
import lightColors from 'theme/colors/light.json';
import constants from 'helpers/constants';
import {darken, lighten, parseToRgb, rgba} from 'polished';
import {css} from 'styled-components';

export function getPalette (palette) {
  const colors = palette === constants.palettes.light ? lightColors : [];

  if (palette === constants.palettes.dark) {
    const theme = createTheme({palette: {mode: 'dark'}});
    Object.keys(theme.palette).forEach((k) => {
      if (utils.isFunction(theme.palette[k])) {
        delete theme.palette[k];
      }
    });

    return theme.palette;
  }

  return colors.reduce((o, c) => {
    const names = c.name.split('/');

    if (!names[0]?.startsWith('_')) {
      let current = o;
      names.forEach((n, idx) => {
        if (n !== 'colors') {
          const t = n === 'contrast' ? 'contrastText' : n;
          current[t] = current[t] ?? (idx < names.length - 1 ? {} : c.color);
          current = current[n];
        }
      });
    }

    return o;
  }, {});
}

export function themeFactory (overrides, base = false) {
  const property = (theme, obj, value, defaultValue = null, deep = false) => {
    const checkColor = () => {
      if (value.startsWith('palette.')) {
        const split = value?.split('.');
        if (!split[1].startsWith('generated_')) {
          const p = utils.objectProp(obj, `${split[0]}.${split[1]}`, 'null');
          if (p === 'null') {
            split[1] = theme.assureColor(split[1]);
            value = split.join('.');
          } else {
            theme.assureColor(p);
          }
        }
      }
    };

    checkColor();

    let res = utils.objectProp(obj, value, defaultValue);

    try {
      if (utils.isFunction(res)) {
        res = res(theme);
      } else if (deep && utils.isObject(res)) {
        Object.keys(res).forEach((k) => {
          res[k] = property(theme, res, k, null, true)
        })
      }
      return res;
    } catch (err) {
      logger.trace('Failed to read theme prop', theme, obj, value, err);
    }
  };

  const convert2Css = (theme, source) => {
    source = utils.isString(source) ? theme.property(source, {}) : source;

    if (source) {
      if (utils.isString(source)) {
        return source;
      } else if (utils.isArray(source)) {
        return source.join('\n');
      } else {
        return Object.keys(source).map((k) => {
          const value = utils.isFunction(source[k]) ? source[k](theme) : source[k];

          if (!utils.isDefined(source[k])) {
            return k;
          } else if (k.length === 0) {
            return value;
          } else if (utils.isObject(source[k])) {
            if (Object.keys(source[k]).length > 0) {
              return `${k} { ${convert2Css(theme, source[k])} }`;
            } else {
              return k;
            }
          } else {
            return `${utils.underscore(k).replace(/_/g, '-')}: ${value};`;
          }
        }).join('\n');
      }
    } else {
      return '';
    }
  }

  function assureColor (theme, color) {
    const getStates = (main, states = null) => {
      return {
        hover: rgba(main, theme.property('palette.action.hoverOpacity')),
        selected: rgba(main, theme.property('palette.action.selectedOpacity')),
        focus: rgba(main, theme.property('palette.action.focusOpacity')),
        focusVisible: rgba(main, theme.property('palette.action.focusVisibleOpacity')),
        outlinedBorder: rgba(main, theme.property('palette.action.outlinedBorderOpacity')),
        ...states
      }
    }

    if (utils.isObject(color)) {
      if (!color.assured && color.main) {
        const main = utils.isFunction(color.main) ? color.main(theme) : color.main;
        if (main) {
          color.dark = color.dark ?? darken(theme.property(`palette.tonalOffset`), main);
          color.light = color.light ?? lighten(theme.property(`palette.tonalOffset`), main);
          color.contrastText = color.contrastText ?? theme.palette.getContrastText(main);

          color.states = getStates(main, color.states);
        }

        color.assured = true;

        return color;
      }
    } else {
      const colorHash = 'generated_' + utils.sha1(color);

      if (theme.palette[colorHash]) {
        return colorHash;
      } else if (color !== 'default' && utils.isColor(color)) {
        const themeColor = theme.property(`palette.${colorHash}`, colorHash);
        if (!utils.isObject(themeColor)) {
          theme.palette[colorHash] = {
            main: color,
            dark: darken(theme.property(`palette.tonalOffset`), color),
            light: lighten(theme.property(`palette.tonalOffset`), color),
            contrastText: theme.palette.getContrastText(color),
            states: getStates(color),
            assured: true
          };
        }

        return colorHash;
      }
    }

    return color;
  }

  function invertShadow (theme, shadow) {
    let res = theme.property(`shadows.${shadow}`);

    if (res) {
      const shadows = res.split(/,(?![^(]*\))/).map((s) => s.trim().split(' '));

      shadows.forEach((s) => {
        s[0] = s[0].startsWith('-') ? s[0].slice(1) : ('-' + s[0]);
        s[1] = s[1].startsWith('-') ? s[1].slice(1) : ('-' + s[1]);
      });

      res = shadows.map((s) => s.join(' ')).join(',');
    }

    return res;
  }

  function colorShadow (theme, color, shadow) {
    let res = theme.property(`shadows.${shadow}`);

    if (res) {
      const colorObj = utils.isString(color) ? theme.property(`palette.${color}`) : color;

      if (colorObj) {
        const shadowColor = utils.isString(colorObj) ? colorObj : colorObj.main;
        const shadows = res.split(/,(?![^(]*\))/).map((s) => s.trim().split(' '));

        shadows.forEach((s) => {
          const rgbaParsed = parseToRgb(s.slice(3).join(''));
          s[3] = rgba(shadowColor, rgbaParsed.alpha);
          s.splice(4, s.length - 3);
        });

        res = shadows.map((s) => s.join(' ')).join(',');
      }
    }

    return res;
  }

  /*
    color: {
      light: disabled color
      main: main color
      dark: hover color
      hover: override hover color (non accent types)
      hoverText: override hover text color (accent types)
      states: {
        hover: override (accent types),
        hoverText: override hover text color (non accent types)
      }
    }
   */
  const state2Color = (theme, color, state, type, target, flatten) => {
    const action = theme.property('palette.action');

    color = (!color || color === 'default') ? 'action' : color;

    // make specific colors important, action color has normal importance
    const important = (c, remove, force = false) => {
      if (remove) {
        return utils.isDefined(c) ? c.replace(' !important', '') : c;
      } else {
        if (force || color !== 'action') {
          return (utils.isDefined(c) && c.length > 0) ? `${c} !important` : c;
        } else {
          return c;
        }
      }
    }

    if (color !== 'inherit') {
      let colorObj = utils.isString(color) ? theme.property(`palette.${color}`) : color;
      const states = colorObj?.states ?? colorObj;

      if (colorObj) {
        let res = '';
        let colorText, colorBackground, colorBorder;
        let colorForeground, transparentBackground, transparentBorder;
        let actionColor, disabled, dark;

        switch (state) {
          case 'active':
            colorText = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined,
              constants.colorTypes.button
            ].includes(type);

            colorForeground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBackground = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.chip,
              constants.colorTypes.paper,
              constants.colorTypes.transparent,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined,
              constants.colorTypes.button
            ].includes(type);

            transparentBackground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBorder = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBorder = [
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            if (target === constants.colorTargets.foreground) {
              res = colorText ? (colorForeground ?
                (important(colorObj.activeText) ?? colorObj.main) :
                (important(states.activeText) ?? colorObj.contrastText)
              ) : '';
            } else if (target === constants.colorTargets.ripple) {
              res = important(states.activeRipple ?? colorObj.activeRipple ?? '');
            } else if (target === constants.colorTargets.background) {
              res = colorBackground ? (transparentBackground ?
                (important(states.active) ?? (colorObj.main && rgba(colorObj.main, action.activatedOpacity))) :
                  (important(colorObj.active) ?? colorObj.dark ?? colorObj.main)) : '';
            } else {
              res = colorBorder ?
                (transparentBorder ? (important(states.activeBorder) ?? (colorObj.outlinedBorder) ?? (colorObj.main && rgba(colorObj.main, action.outlinedBorderOpacity))) :
                  (important(colorObj.activeBorder) ?? colorObj.dark ?? colorObj.main)) : '';
            }
            break;
          case 'hover':
            colorText = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.transparent
            ].includes(type);

            colorForeground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBackground = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.button,
              constants.colorTypes.chip,
              constants.colorTypes.paper,
              constants.colorTypes.transparent,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBackground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBorder = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBorder = [
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            dark = ![
              constants.colorTypes.transparent
            ].includes(type);

            if (target === constants.colorTargets.foreground) {
              res = colorText ? (colorForeground ?
                  (important(colorObj.hoverText) ?? colorObj.main) :
                  (important(states.hoverText) ?? colorObj.contrastText)
              ) : '';
            } else if (target === constants.colorTargets.ripple) {
              res = important(states.hoverRipple ?? colorObj.hoverRipple ?? '');
            } else if (target === constants.colorTargets.background) {
              res = colorBackground ? (transparentBackground ?
                (important(states.hover) ?? (colorObj.main && rgba(colorObj.main, action.hoverOpacity))) :
                (important(colorObj.hover) ?? (dark ? (colorObj.dark ?? colorObj.main) : colorObj.main))) : '';
            } else {
              res = colorBorder ?
                (transparentBorder ? (important(states.hoverBorder) ?? (colorObj.outlinedBorder) ?? (colorObj.main && rgba(colorObj.main, action.outlinedBorderOpacity))) :
                  (important(colorObj.hoverBorder) ?? (dark ? (colorObj.dark ?? colorObj.main) : colorObj.main))) : '';
            }
            break;
          case 'focus':
            colorText = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.transparent,
              constants.colorTypes.accent,
              constants.colorTypes.accentText
            ].includes(type);

            colorForeground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBackground = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.transparent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.accentText,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBackground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBorder = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBorder = [
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            if (target === constants.colorTargets.foreground) {
              res = colorText ? (colorForeground ?
                  (important(colorObj.focusText) ?? colorObj.main) :
                  (important(states.focusText) ?? colorObj.contrastText)
              ) : '';
            } else if (target === constants.colorTargets.ripple) {
              res = important(states.focusRipple ?? colorObj.focusRipple ?? '');
            } else if (target === constants.colorTargets.background) {
              res = colorBackground ? (transparentBackground ?
                (important(states.focus) ?? (colorObj.main && rgba(colorObj.main, action.focusOpacity))) :
                (important(colorObj.focus) ?? colorObj.dark ?? colorObj.main)) : '';
            } else {
              res = colorBorder ?
                (transparentBorder ? (important(states.focusBorder) ?? (colorObj.outlinedBorder) ?? (colorObj.main && rgba(colorObj.main, action.outlinedBorderOpacity))) :
                  (important(colorObj.focusBorder) ?? colorObj.dark ?? colorObj.main)) : '';
            }
            break;
          case 'selected':
            colorText = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined,
              constants.colorTypes.button
            ].includes(type);

            colorForeground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBackground = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.chip,
              constants.colorTypes.paper,
              constants.colorTypes.transparent,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined,
              constants.colorTypes.button
            ].includes(type);

            transparentBackground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBorder = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBorder = [
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            dark = ![
              constants.colorTypes.transparent
            ].includes(type);

            if (target === constants.colorTargets.foreground) {
              res = colorText ? (colorForeground ?
                  (important(colorObj.selectedText) ?? colorObj.main) :
                  (important(states.selectedText) ?? colorObj.contrastText)
              ) : '';
            } else if (target === constants.colorTargets.ripple) {
              res = important(states.selectedRipple ?? colorObj.selectedRipple ?? '');
            } else if (target === constants.colorTargets.background) {
              res = colorBackground ? (transparentBackground ?
                (important(states.selected) ?? (colorObj.main && rgba(colorObj.main, action.selectedOpacity))) :
                (important(colorObj.selected) ?? (dark ? (colorObj.dark ?? colorObj.main) : colorObj.main))) : '';
            } else {
              res = colorBorder ?
                (transparentBorder ? (important(states.selectedBorder) ?? (colorObj.outlinedBorder) ?? (colorObj.main && rgba(colorObj.main, action.outlinedBorderOpacity))) :
                  (important(colorObj.selectedBorder) ?? (dark ? (colorObj.dark ?? colorObj.main) : colorObj.main))) : '';
            }
            break;
          case 'focusVisible':
            colorText = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.transparent,
              constants.colorTypes.accent,
              constants.colorTypes.accentText
            ].includes(type);

            colorForeground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.outlined
            ].includes(type);

            colorBackground = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBackground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            colorBorder = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBorder = [
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            if (target === constants.colorTargets.foreground) {
              res = colorText ? (colorForeground ?
                  (important(colorObj.focusVisibleText) ?? colorObj.main) :
                  (important(states.focusVisibleText) ?? colorObj.contrastText)
              ) : '';
            } else if (target === constants.colorTargets.ripple) {
              res = important(states.focusVisibleRipple ?? colorObj.focusVisibleRipple ?? '');
            } else if (target === constants.colorTargets.background) {
              res = colorBackground ? (transparentBackground ?
                (important(states.focusVisible) ?? (colorObj.main && rgba(colorObj.main, action.focusVisibleOpacity))) :
                (important(colorObj.focusVisible) ?? colorObj.dark ?? colorObj.main)) : '';
            } else {
              res = colorBorder ?
                (transparentBorder ? (important(states.focusVisibleBorder) ?? (colorObj.outlinedBorder) ?? (colorObj.main && rgba(colorObj.main, action.outlinedBorderOpacity))) :
                  (important(colorObj.focusVisibleBorder) ?? colorObj.dark ?? colorObj.main)) : '';
            }
            break;
          case 'disabled':
            colorText = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.input,
              constants.colorTypes.button,
              constants.colorTypes.chip,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined
            ].includes(type);

            colorForeground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined
            ].includes(type);

            colorBackground = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.button,
              constants.colorTypes.chip,
              constants.colorTypes.paper,
              constants.colorTypes.accent,
              constants.colorTypes.transparent
            ].includes(type);

            transparentBackground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined
            ].includes(type);

            colorBorder = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBorder = [
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            actionColor = [
              constants.colorTypes.button,
              constants.colorTypes.input,
              constants.colorTypes.paper,
              constants.colorTypes.accent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.outlined
            ].includes(type);

            disabled = ![
              constants.colorTypes.chip,
              constants.colorTypes.transparent
            ].includes(type);

            if (target === constants.colorTargets.foreground) {
              if (disabled) {
                res = colorText ? (actionColor ? action.disabled :
                  (colorForeground ?
                    (important(colorObj.disabledText) ?? (colorObj.main && rgba(colorObj.main, action.disabledOpacity))) :
                    (important(states.disabledText) ?? (colorObj.contrastText && rgba(colorObj.contrastText, action.disabledOpacity))))
                ) : '';
              } else {
                res = colorText ? (colorForeground ? (important(colorObj.disabledText) ?? colorObj.main) :
                    (important(states.disabledText) ?? colorObj.contrastText)) : '';
              }
            } else if (target === constants.colorTargets.ripple) {
              res = important(states.disabledRipple ?? colorObj.disabledRipple ?? '');
            } else if (target === constants.colorTargets.background) {
              if (disabled) {
                res = colorBackground ? (actionColor ? action.disabledBackground :
                    (transparentBackground ? (important(states.disabled) ?? (colorObj.main && rgba(colorObj.main, action.disabledOpacity))) :
                    (important(colorObj.disabledBackground) ?? colorObj.light ?? colorObj.main))) : '';
              } else {
                res = colorBackground ?
                  (transparentBackground ? (important(states.disabled) ?? states.selected ??
                  (colorObj.main && rgba(colorObj.main, action.selectedOpacity))) :
                    important(colorObj.disabled) ?? colorObj.main) : '';
              }
            } else {
              if (disabled) {
                res = colorBorder ? (actionColor ? (action.disabledBackground) :
                    (transparentBorder ? (important(states.disabledBorder) ?? (colorObj.main && rgba(colorObj.main, action.disabledOpacity))) :
                    (important(colorObj.disabledBorder) ?? colorObj.light ?? colorObj.main))) : '';
              } else {
                res = colorBorder ?
                  (transparentBorder ? (important(states.defaultBorder) ?? (colorObj.outlinedBorder) ?? (colorObj.main && rgba(colorObj.main, action.outlinedBorderOpacity))) :
                    (important(colorObj.defaultBorder) ?? colorObj.light ?? colorObj.main)) : '';
              }
            }
            break;
          default:
            colorText = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.chip,
              constants.colorTypes.button,
              constants.colorTypes.accentText,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined
            ].includes(type);

            colorForeground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentBackground,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined
            ].includes(type);

            colorBackground = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.chip,
              constants.colorTypes.button,
              constants.colorTypes.paper,
              constants.colorTypes.transparent
            ].includes(type);

            transparentBackground = [
              constants.colorTypes.state,
              constants.colorTypes.accent,
              constants.colorTypes.accentText,
              constants.colorTypes.accentBackground,
              constants.colorTypes.transparent,
              constants.colorTypes.outlined
            ].includes(type);

            colorBorder = [
              constants.colorTypes.base,
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            transparentBorder = [
              constants.colorTypes.state,
              constants.colorTypes.outlined
            ].includes(type);

            if (target === constants.colorTargets.foreground) {
              res = colorText ? (colorForeground ? (colorObj.defaultText ?? colorObj.main) :
                (states.defaultText ?? colorObj.contrastText)) : '';
            } else if (target === constants.colorTargets.ripple) {
              res = states.defaultRipple ?? colorObj.defaultRipple ?? '';
            } else if (target === constants.colorTargets.background) {
              res = colorBackground ?
                (transparentBackground ? (states.default ?? states.selected ??
                    (colorObj.main && rgba(colorObj.main, action.selectedOpacity))) :
                  (colorObj.default ?? colorObj.main)) : '';
            } else {
              res = colorBorder ? (transparentBorder ?
                (states.defaultBorder ?? (colorObj.outlinedBorder) ?? (colorObj.main && rgba(colorObj.main, action.outlinedBorderOpacity))) :
                (colorObj.defaultBorder ?? colorObj.main)) : '';
            }
        }

        if (flatten && res) {
          const wasImportant = res?.includes('!important');
          const flattened = utils.isNumber(flatten) ? rgba(utils.rgba2Rgb(important(res, true)), flatten) : utils.rgba2Rgb(important(res, true));
          return (wasImportant ? important(flattened, false, true) :
            utils.rgba2Rgb(res));
        } else if (res) {
          const forceImportant = state === 'disabled';
          return forceImportant ? important(important(res, true), false, true) : res;
        } else {
          return '';
        }
      }
    }

    return '';
  }

  const state2Selector = (base, child, state, prefix = '&', childPrefix = '') => {
    return utils.toArray(state ?? 'default').map((st) => {
      switch (st) {
        case 'active':
          return `${prefix}.${base}.${base}.${base}-active ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}.${base}.Mui-active ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}:active ${child ? `${childPrefix}.${child}` : ''}`;
        case 'hover':
          return `${prefix}.${base}.${base}.${base}-hover ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}.${base}.Mui-hover ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}:hover ${child ? `${childPrefix}.${child}` : ''}`;
        case 'focus':
          return `${prefix}.${base}.${base}.${base}-focused ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}.${base}.Mui-focused ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}:focus ${child ? `${childPrefix}.${child}` : ''}`;
        case 'selected':
          return `${prefix}.${base}.${base}.${base}-selected ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}.${base}.Mui-selected ${child ? `${childPrefix}.${child}` : ''}`;
        case 'focusVisible':
          return `${prefix}.${base}.${base}.${base}-focusVisible ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}.${base}.Mui-focusVisible ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}:focus-visible ${child ? `${childPrefix}.${child}` : ''}`;
        case 'disabled':
          return `${prefix}.${base}.${base}.${base}-disabled ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}.${base}.Mui-disabled ${child ? `${childPrefix}.${child}` : ''}, ${prefix}.${base}.${base}:disabled ${child ? `${childPrefix}.${child}` : ''}`;
        default:
          return `${prefix}.${base} ${child ? `${childPrefix}.${child}` : ''}`;
      }
    }).join(',');
  }

  const color2Css = (theme, base, child, color, options = {}) => {
    const {
      type,
      interactive,
      focusable,
      flatten,
      prefix,
      childPrefix
    } = options;

    let css = '';
    if (color !== 'inherit') {
      const action = theme.property('palette.action');
      const disabledOpacity = [
        constants.colorTypes.chip,
        constants.colorTypes.transparent
      ].includes(type);

      css += `
        ${state2Selector(base, child, 'default', prefix, childPrefix)} {
          color: ${state2Color(theme, color, 'default', type, constants.colorTargets.foreground, flatten)};
          background-color: ${state2Color(theme, color, 'default', type, constants.colorTargets.background, flatten)};
          border-color: ${state2Color(theme, color, 'default', type, constants.colorTargets.border, flatten)};
          
          .MuiTouchRipple-root {
            color: ${state2Color(theme, color, 'default', type, constants.colorTargets.ripple, flatten)};
          }
        }
      `;

      if (interactive) {
        css += `
          ${state2Selector(base, child, 'active', prefix, childPrefix)} {
            color: ${state2Color(theme, color, 'active', type, constants.colorTargets.foreground, flatten)};
            background-color: ${state2Color(theme, color, 'active', type, constants.colorTargets.background, flatten)};
            border-color: ${state2Color(theme, color, 'active', type, constants.colorTargets.border, flatten)};
            
            .MuiTouchRipple-root {
              color: ${state2Color(theme, color, 'active', type, constants.colorTargets.ripple, flatten)};
            }
          }
        `;

        css += `
          ${state2Selector(base, child, 'hover', prefix, childPrefix)} {
            color: ${state2Color(theme, color, 'hover', type, constants.colorTargets.foreground, flatten)};
            background-color: ${state2Color(theme, color, 'hover', type, constants.colorTargets.background, flatten)};
            border-color: ${state2Color(theme, color, 'hover', type, constants.colorTargets.border, flatten)};

            .MuiTouchRipple-root {
              color: ${state2Color(theme, color, 'hover', type, constants.colorTargets.ripple, flatten)};
            }
          }
        `;
      }

      if (interactive || focusable) {
        css += `
          ${state2Selector(base, child, 'focus', prefix, childPrefix)} {
            color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.foreground, flatten)};
            background-color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.background, flatten)};
            border-color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.border, flatten)};

            .MuiTouchRipple-root {
              color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.ripple, flatten)};
            }
          }
        `;

        css += `
          ${state2Selector(base, child, 'focusVisible', prefix, childPrefix)} {
            color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.foreground, flatten)};
            background-color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.background, flatten)};
            border-color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.border, flatten)};

            .MuiTouchRipple-root {
              color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.ripple, flatten)};
            }
          }
        `;
      }

      css += `
        ${state2Selector(base, child, 'selected', prefix, childPrefix)} {
          color: ${state2Color(theme, color, 'selected', type, constants.colorTargets.foreground, flatten)};
          background-color: ${state2Color(theme, color, 'selected', type, constants.colorTargets.background, flatten)};
          border-color: ${state2Color(theme, color, 'selected', type, constants.colorTargets.border, flatten)};

          .MuiTouchRipple-root {
            color: ${state2Color(theme, color, 'selected', type, constants.colorTargets.ripple, flatten)};
          }
          
          ${interactive ? `
            ${state2Selector(base, child, 'hover', prefix, childPrefix)}, 
            ${state2Selector(base, child, 'focus', prefix, childPrefix)} {
              color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.foreground, flatten)};
              background-color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.background, flatten)};
              border-color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.border, flatten)};
  
              .MuiTouchRipple-root {
                color: ${state2Color(theme, color, 'focus', type, constants.colorTargets.ripple, flatten)};
              }
            }
            
            ${state2Selector(base, child, 'focusVisible', prefix, childPrefix)} {
              color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.foreground, flatten)};
              background-color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.background, flatten)};
              border-color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.border, flatten)};
  
              .MuiTouchRipple-root {
                color: ${state2Color(theme, color, 'focusVisible', type, constants.colorTargets.ripple, flatten)};
              }
            }
          ` : ''}
        }
      `;

      css += `
        ${state2Selector(base, child, 'disabled', prefix, childPrefix)} {
          color: ${state2Color(theme, color, 'disabled', type, constants.colorTargets.foreground, flatten)};
          background-color: ${state2Color(theme, color, 'disabled', type, constants.colorTargets.background, flatten)};
          border-color: ${state2Color(theme, color, 'disabled', type, constants.colorTargets.border, flatten)};
          ${disabledOpacity ? `opacity: ${action.disabledOpacity};` : ''}

          .MuiTouchRipple-root {
            color: ${state2Color(theme, color, 'disabled', type, constants.colorTargets.ripple, flatten)};
          }
        }
      `;
    }

    return css;
  }

  const validFont = (font) => {
    return !utils.isString(font) || !['inherit', 'span', 'p'].includes(font);
  }

  const font2Size = (theme, font) => {
    if (validFont(font)) {
      const fontObj = utils.isString(font) ? theme.property(`typography.${font}`, null, false) : font;
      if (fontObj && fontObj.fontSize) {
        const fontSize = property(theme, fontObj, `fontSize`);
        return fontSize?.endsWith('rem') ?
          utils.toNumber(fontSize) * typography.htmlFontSize : utils.toNumber(fontSize);
      } else {
        return null;
      }
    }
  }

  const font2Css = (theme, font, options = {}) => {
    const {
      override
    } = options;

    let css = '';
    if (validFont(font)) {
      const fontObj = utils.isString(font) ? theme.property(`typography.${font}`) : font;

      if (fontObj) {
        css += `
          ${convert2Css(theme, utils.mergeObjects(fontObj, override))}
        `;
      }
    }

    return css;
  }

  const toPixels = (theme, value, font) => {
    font = (typeof font === 'string') ? font2Size(theme, font) : font;

    return utils.toNumber(value) * (font ?? typography.htmlFontSize);
  }

  const pickAColor = (theme, colorSet) => {
    const colors = theme.property(`colorSet.${colorSet}`);
    const count = Object.keys(colors ?? {}).length;

    return count > 0 ? colors[Object.keys(colors)[Math.floor(Math.random() * count)]] : null;
  }

  const pickAnIcon = (theme, iconSet) => {
    const icons = constants.icons[iconSet];
    const count = Object.keys(icons ?? {}).length;

    return count > 0 ? Object.keys(icons)[Math.floor(Math.random() * count)] : null;
  }

  const responsiveSizes = (theme, key, size, boundary) => {
    const breakpoints = ['lg', 'md', 'sm', 'xs'];
    const result = {};

    if (size > boundary) {
      const max = size;
      const min = boundary + ((size - boundary) / theme.responsiveFactor);

      const factor = (max - min) / breakpoints.length;
      breakpoints.forEach((bp, idx) => {
        const bps = theme.breakpoints.down(bp);
        result[bps] = {
          [key]: theme.fontSize(Math.round((max - (factor * (idx + 1))) * 1000) / 1000)
        }
      })
    }

    return result;
  }

  const theme = createTheme(utils.mergeObjects(themeBaseOverrides, overrides, true));

  theme.overrides = utils.clone(overrides, true);

  theme.property = (value, defaultValue, deep) => property(theme, theme, value, defaultValue, deep);
  theme.convert2Css = (object) => convert2Css(theme, object);
  theme.assureColor = (color) => assureColor(theme, color);
  theme.state2Selector = (base, child, state, prefix) =>
    state2Selector(base, child, state, prefix);
  theme.state2Color = (color, state, type, target, flatten) =>
    state2Color(theme, color, state, type, target, flatten);
  theme.color2Css = (base, child, color, options) =>
    color2Css(theme, base, child, color, options);
  theme.font2Size = (font) =>
    font2Size(theme, font)
  theme.font2Css = (font, options) =>
    font2Css(theme, font, options);
  theme.invertShadow = (shadow) => invertShadow(theme, shadow);
  theme.colorShadow = (color, shadow) => colorShadow(theme, color, shadow);
  theme.toPixels = (value, base) => toPixels(theme, value, base);
  theme.pickAColor = (colorSet) => pickAColor(theme, colorSet);
  theme.pickAnIcon = (iconSet) => pickAnIcon(theme, iconSet);
  theme.responsiveSizes = (key, size, boundary) => responsiveSizes(theme, key, size, boundary)

  // stabilize theme functions
  if (!base) {
    Object.keys(theme).forEach((k) => {
      if (utils.isObject(theme[k]) && !k.startsWith('unstable') && k !== 'overrides') {
        Object.keys(theme[k]).forEach((k1) => {
          if (!utils.isFunction(theme[k][k1]) || k === 'zIndex') {
            theme[k][k1] = theme.property(`${k}.${k1}`, '', true);
          }
        });
      }
    });

    // add responsive fonts
    const fonts = Object.keys(theme.typography)
      .map((k) => theme.typography[k])
      .filter((font) => utils.isDefined(font.fontSize));

    fonts.forEach((font) => {
      const fontSize = font2Size(theme, font);

      const sizes = theme.responsiveSizes('fontSize', fontSize, theme.typography.htmlFontSize);
      Object.keys(sizes).forEach((k) => {
        font[k] = {
          ...sizes[k],
          ...font[k]
        }
      });
    });

    // add constant colors
    const addColor = (color) => {
      color = utils.isFunction(color) ? color(theme) : color;
      if (utils.isObject(color)) {
        Object.keys(color).forEach((k) => {
          addColor(color[k]);
        })
      } else if (utils.isString(color)) {
        theme.assureColor(color);
      }
    };

    addColor(theme.colorSet);
  }

  return theme;
}

const radiusBase = 4;
const spacingBase = 8;
const fontBase = 14;
const typography = {
  htmlFontSize: 16,
  fontSize: 14, // fontSize/14 is the shrink factor
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
};
const responsiveFactor = 2; // low number small steps, high number big steps

const themeBaseOverrides = {
  radiusBase,
  spacingBase,
  responsiveFactor,
  palette: {
    action: {
      focusVisible: 'rgba(0, 25, 52, 0.2)',
      focusVisibleOpacity: 0.2,
      outlinedBorder: 'rgba(0, 25, 52, 0.5)',
      outlinedBorderOpacity: 0.5
    },
    contrast: { // contrast to dark surfaces
      main: (theme) => theme.property('palette.background.default'),
      states: {
        outlinedBorder: (theme) => rgba(theme.property('palette.background.default'), 0.2)
      }
    },
    selected: {
      main: (theme) => theme.property('palette.primary.main')
    },
    light: {
      main: (theme) => theme.property('palette.primary.states.focusVisible'),
      contrastText: (theme) => theme.property('palette.text.primary')
    },
    primaryBackground: {
      main: (theme) => rgba(theme.property('palette.primary.main'), 0.7)
    },
    greyScale: {
      main: (theme) => theme.property('palette.action.active')
    },
    badgeLight: {
      main: (theme) => theme.property('palette.divider'),
      contrastText: (theme) => theme.property('palette.text.disabled')
    },
    switch: {
      main: (theme) => rgba(theme.property('palette.action.focusVisible'), 1),
      states: {
        focus: (theme) => rgba(theme.property('palette.action.focusVisible'), 0.06),
        focusVisible: (theme) => rgba(theme.property('palette.action.focusVisible'), 0.06)
      }
    },
    creditsDefault: {
      dark: (theme) => darken(0.1, theme.property('palette.divider')),
      main: (theme) => theme.property('palette.action.selected'),
      contrastText: (theme) => theme.property('palette.text.primary')
    },
    dealflowDefault: {
      darker: (theme) => darken(0.5, theme.property('palette.divider')),
      dark: (theme) => darken(0.1, theme.property('palette.divider')),
      main: (theme) => theme.property('palette.action.selected'),
      contrastText: (theme) => theme.property('palette.text.primary')
    },
    tagGroupDefault: {
      dark: (theme) => darken(0.1, theme.property('palette.divider')),
      main: (theme) => theme.property('palette.action.selected'),
      contrastText: (theme) => theme.property('palette.text.primary')
    },
    relevancyHighButton: {
      light: (theme) => theme.property('palette.green.100'),
      main: (theme) => theme.property('palette.green.200'),
      dark: (theme) => theme.property('palette.green.300'),
      contrastText: (theme) => theme.property('palette.success.dark'),
      default: (theme) => theme.property('palette.green.100'),
      hover: (theme) => theme.property('palette.green.200'),
      active: (theme) => theme.property('palette.green.500'),
      states: {
        activeText: (theme) => theme.property('palette.primary.contrastText'),
        defaultRipple: (theme) => theme.property('palette.primary.contrastText')
      }
    },
    relevancyMediumButton: {
      light: (theme) => theme.property('palette.orange.100'),
      main: (theme) => theme.property('palette.orange.200'),
      dark: (theme) => theme.property('palette.orange.300'),
      contrastText: (theme) => theme.property('palette.warning.dark'),
      default: (theme) => theme.property('palette.orange.100'),
      hover: (theme) => theme.property('palette.orange.200'),
      active: (theme) => theme.property('palette.orange.500'),
      states: {
        activeText: (theme) => theme.property('palette.primary.contrastText'),
        defaultRipple: (theme) => theme.property('palette.primary.contrastText')
      }
    },
    relevancyLowButton: {
      light: (theme) => theme.property('palette.red.100'),
      main: (theme) => theme.property('palette.red.200'),
      dark: (theme) => theme.property('palette.red.300'),
      contrastText: (theme) => theme.property('palette.error.main'),
      default: (theme) => theme.property('palette.red.100'),
      hover: (theme) => theme.property('palette.red.200'),
      active: (theme) => theme.property('palette.red.500'),
      states: {
        activeText: (theme) => theme.property('palette.primary.contrastText'),
        defaultRipple: (theme) => theme.property('palette.primary.contrastText')
      }
    },
    relevancyNoneButton: {
      light: (theme) => theme.property('palette.grey.100'),
      main: (theme) => theme.property('palette.action.selected'),
      dark: (theme) => theme.property('palette.action.disabledBackground'),
      contrastText: (theme) => theme.property('palette.text.primary'),
      default: (theme) => theme.property('palette.grey.100'),
      hover: (theme) => theme.property('palette.action.selected'),
      active: (theme) => theme.property('palette.action.selected'),
      states: {
        activeText: (theme) => theme.property('palette.text.primary'),
        defaultRipple: (theme) => theme.property('palette.primary.contrastText')
      }
    }
  },
  colorSet: {
    basic: {
      lightblue: (theme) => theme.property('palette.primary.light'),
      blue: (theme) => theme.property('palette.primary.main'),
      darkBlue: (theme) => theme.property('palette.primary.dark'),
      lightGreen: (theme) => theme.property('palette.success.light'),
      green: (theme) => theme.property('palette.success.main'),
      darkGreen: (theme) => theme.property('palette.success.dark'),
      lightLime: (theme) => theme.property('palette.lime.400'),
      lime: (theme) => theme.property('palette.lime.600'),
      darkLime: (theme) => theme.property('palette.lime.800'),
      lightYellow: (theme) => theme.property('palette.amber.200'),
      yellow: (theme) => theme.property('palette.amber.500'),
      darkYellow: (theme) => theme.property('palette.amber.700'),
      lightOrange: (theme) => theme.property('palette.warning.light'),
      orange: (theme) => theme.property('palette.warning.main'),
      darkOrange: (theme) => theme.property('palette.warning.dark'),
      lightRed: (theme) => theme.property('palette.error.light'),
      red: (theme) => theme.property('palette.error.main'),
      darkRed: (theme) => theme.property('palette.error.dark'),
      lightPink: (theme) => theme.property('palette.pink.200'),
      pink: (theme) => theme.property('palette.pink.400'),
      darkPink: (theme) => theme.property('palette.pink.700'),
      lightPurple: (theme) => theme.property('palette.purple.200'),
      purple: (theme) => theme.property('palette.purple.400'),
      darkPurple: (theme) => theme.property('palette.purple.700')
    },
    tagGroup: (theme) => theme.property('colorSet.basic'),
    statusGroup: (theme) => theme.property('colorSet.basic'),
    deprecated: {
      ...constants.color.deprecated
    }
  },
  typography: {
    ...typography,
    h1: {
      fontWeight: 300,
      fontSize: (theme) => theme.fontSize(40),
      lineHeight: (theme) => theme.lineHeight(`${72/40}f`), // 72 based on 40
      letterSpacing: (theme) => theme.layout(-1.5),
    },
    h2: {
      fontWeight: 500,
      fontSize: (theme) => theme.fontSize(32),
      lineHeight: (theme) => theme.lineHeight(`${64/32}f`), // 64 based on 32
      letterSpacing: (theme) => theme.layout(-1),
    },
    h3: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(28),
      lineHeight: (theme) => theme.lineHeight(`${48/28}f`),
      letterSpacing: (theme) => theme.layout(0),
    },
    h4: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(24),
      lineHeight: (theme) => theme.lineHeight(`${32/24}f`),
      letterSpacing: (theme) => theme.layout(0),
    },
    h5: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(22),
      lineHeight: (theme) => theme.lineHeight(`${36/22}f`),
      letterSpacing: (theme) => theme.layout(0),
    },
    h6: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(18),
      lineHeight: (theme) => theme.lineHeight(`${28/18}f`),
      letterSpacing: (theme) => theme.layout(0),
    },
    body1: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(16),
      lineHeight: (theme) => theme.lineHeight(`${24/16}f`),
      letterSpacing: (theme) => theme.layout(0.15),
    },
    body2: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(14),
      lineHeight: (theme) => theme.lineHeight(`${20/14}f`),
      letterSpacing: (theme) => theme.layout(0),
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(16),
      lineHeight: (theme) => theme.lineHeight(`${24/16}f`),
      letterSpacing: (theme) => theme.layout(0.15),
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(14),
      lineHeight: (theme) => theme.lineHeight(`${20/14}f`),
      letterSpacing: (theme) => theme.layout(0.1),
    },
    overline: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(12),
      lineHeight: (theme) => theme.lineHeight('2.66f'),
      letterSpacing: (theme) => theme.layout(1),
    },
    caption: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(12),
      lineHeight: (theme) => theme.lineHeight('1.66f'),
      letterSpacing: (theme) => theme.layout(0.4),
    },
    chipLabel: {
      fontWeight: 400,
      fontSize: (theme) => theme.fontSize(14),
      lineHeight: (theme) => theme.lineHeight(`${18/14}f`),
      letterSpacing: (theme) => theme.layout(0.4),
    },
    tableHeader: {
      fontSize: (theme) => theme.fontSize(13),
      fontWeight: 500
    },
    tableBody: {
      fontSize: (theme) => theme.fontSize(14)
    },
    brand: {
      fontWeight: 600,
      fontSize: (theme) => theme.fontSize(24),
      letterSpacing: (theme) => theme.layout(-1),
      lineHeight: (theme) => theme.lineHeight(`0.8f`)
    },
    slogan: {
      fontWeight: 700,
      fontFamily: 'Poppins',
      fontSize: (theme) => theme.fontSize(48),
      lineHeight: (theme) => theme.lineHeight(`${56/48}f`),
      letterSpacing: (theme) => theme.layout(-1.5),
    }
  },
  zIndex: {
    context: (theme) => theme.zIndex.appBar - 25,
    header: (theme) => theme.zIndex.appBar - 30,
    pinned: (theme) => theme.zIndex.appBar - 40,
    popper: (theme) => theme.zIndex.modal,
    loader: (theme) => theme.zIndex.tooltip + 75,
    actionbar: (theme) => theme.zIndex.drawer - 10,
  },
  shape: {
    borderRadius: radiusBase,
  },
  shadows: [
    ...shadows
  ],
  breakpoints: {
    values: {
      xs: 500,
      sm: 600,
      ls: 812,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        enterDelay: constants.delay.short
      },
      styleOverrides: {
        tooltip: (theme) => theme.convert2Css(css`
          color: ${theme.property('palette.secondary.contrastText')};
          background-color: ${theme.property('palette.secondary.main')};
          box-shadow: ${theme.colorShadow('background.default', 2)};

          .MuiTooltip-arrow {
            color: ${theme.property('palette.secondary.main')};

            &:before {
              box-shadow: ${theme.colorShadow('background.default', 1)};
            }
          }
        `)
      }
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true, // performance thing server-side rendering
      },
    },
    TextStyles: {
      styleOverrides: {
        root: {
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: (theme) => theme.fontSize(16),
          lineHeight: (theme) => theme.lineHeight('1f'),
          fontWeight: (theme) => theme.typography.fontWeightRegular,
        }
      }
    }
  },
  spacing: (value) => {
    value = (typeof value === 'string' && value.endsWith('f')) ? (utils.toNumber(value) * typography.htmlFontSize) / spacingBase : value;
    return typeof value === 'string' ? value :
      (value != null ? utils.pixel2Em(value * spacingBase, typography.htmlFontSize) : null);
  },
  radius: (value) => {
    value = (typeof value === 'string' && value.endsWith('f')) ? (utils.toNumber(value) * typography.htmlFontSize) / radiusBase : value;
    return typeof value === 'string' ? value :
      (value != null ? (value * radiusBase) + 'px' : null);
  },
  layout: (value) => {
    value = (typeof value === 'string' && value.endsWith('sp')) ? utils.toNumber(value) * spacingBase : (
      (typeof value === 'string' && value.endsWith('f')) ? utils.toNumber(value) * typography.htmlFontSize : value
    );
    return typeof value === 'string' ? value :
      (value != null ? utils.pixel2Rem(value * (typography.fontSize / fontBase), typography.htmlFontSize) : null);
  },
  fontSize: (value) => {
    value = (typeof value === 'string' && value.endsWith('sp')) ? utils.toNumber(value) * spacingBase : (
      (typeof value === 'string' && value.endsWith('f')) ? utils.toNumber(value) * typography.htmlFontSize : value
    );
    return typeof value === 'string' ? value :
      (value != null ? utils.pixel2Rem(value * (typography.fontSize / fontBase), typography.htmlFontSize) : null);
  },
  lineHeight: (value) => {
    value = (typeof value === 'string' && value.endsWith('sp')) ? utils.toNumber(value) * spacingBase : (
      (typeof value === 'string' && value.endsWith('f')) ? utils.toNumber(value) * typography.htmlFontSize : value
    );
    return typeof value === 'string' ? value :
      (value != null ? utils.pixel2factor(value * (typography.fontSize / fontBase), typography.htmlFontSize) : null);
  },
  percentage: (value) => {
    value = (typeof value === 'string' && value.endsWith('sp')) ? utils.toNumber(value) * spacingBase : (
      (typeof value === 'string' && value.endsWith('f')) ? utils.toNumber(value) * typography.htmlFontSize : value
    );
    return typeof value === 'string' ? value :
      (value != null ? utils.pixel2percentage(value * (typography.fontSize / fontBase), typography.htmlFontSize) : null);
  },
};

export const themeBase = themeFactory(themeBaseOverrides, true);


