import React, {useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSwitchClientDialog from 'components/organisms/Dialogs/SwitchClientDialog/SwitchClientDialog.styles';
import SwitchClientForm from 'components/organisms/Forms/SwitchClientForm/SwitchClientForm';
import {H6, Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import {useAuthControl} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Box from 'components/atoms/Layout/Box/Box';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import {useLocation} from 'react-router-dom';
import {useLinkNavigate} from 'helpers/hooks/links';

const SwitchClientDialog = React.forwardRef((props, ref) => {
  const {
    onClose,
    ...innerProps
  } = useComponentProps(props, 'SwitchClientDialog');

  const authControl = useAuthControl();
  const location = useLocation();
  const navigate = useLinkNavigate();

  const [internalState, setInternalState] = useState({
    switching: false,
    error: null
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const doRedirect = () => {
    navigate({
      to: utils.validRedirect(location),
      state: {recover: true},
      replace: true
    });
  }

  const handleSuccess = () => {
    authControl.setSuspended(false);
    innerRef.current?.close?.(null, 'switched');
  };

  const handleSwitching = (switching) => {
    doRedirect();
    setInternalState(utils.updater({
      error: null,
      switching
    }, true));
    authControl.setSuspended(switching);
  };

  const handleError = (error) => {
    setInternalState(utils.updater({
      switching: false,
      error
    }, true));
    authControl.setSuspended(false);
  };

  const handleClose = (e, reason) => {
    if (!internalState.switching || reason === 'switched') {
      onClose?.(e, reason);
    }
  }

  return <StyledSwitchClientDialog ref={innerRef} {...innerProps}
                                   $switching={internalState.switching}
                                   title={'Select a client'}
                                   onClose={handleClose}
                                   info={internalState.error ? <Span color="error">{internalState.error}</Span> : null}
                                   form={<Box className="SwitchClientDialog-form-wrapper">
                                     <Box className="SwitchClientDialog-loading">
                                       <CircularProgress />
                                       <H6>Preparing client data</H6>
                                     </Box>
                                     <SwitchClientForm className="SwitchClientDialog-form"
                                                       autoFocus={true}
                                                       onSwitching={handleSwitching}
                                                       onSuccess={handleSuccess}
                                                       onError={handleError} />
                                   </Box>}/>
});

SwitchClientDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onClose: PropTypes.func
};

SwitchClientDialog.defaultProps = {};

export default SwitchClientDialog;
