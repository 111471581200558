import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import Card from 'components/atoms/Cards/Card/Card';
import Button from 'components/atoms/Buttons/Button/Button';
import Add from '@mui/icons-material/Add';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';
import constants from 'helpers/constants';
import Circle from '@mui/icons-material/Circle';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import ColorField from 'components/molecules/Fields/ColorField/ColorField';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import StyledDealflowStatusGroupProfileCardContent
  from 'components/organisms/Cards/DealflowStatusGroupProfileCardContent/DealflowStatusGroupProfileCardContent.styles';
import StatusesForm from 'components/organisms/Forms/StatusesForm/StatusesForm';
import StatusGroupCard from 'components/organisms/Cards/StatusGroupCard/StatusGroupCard';
import DealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon';
import IconField from 'components/molecules/Fields/IconField/IconField';

const DealflowStatusGroupProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    client,
    statusGroup,
    isDialog,
    fieldData,
    onValidating,
    onSubmit,
    onPatch,
    ...innerProps
  } = useComponentProps(props, 'DealflowStatusGroupProfileCardContent', {
    static: ['isAddNew', 'isEditing', 'isDeleted']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);
  const [formValues, setFormValues] = useState({});

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const isAddNew = !utils.isDefined(statusGroup?.groupId);
  const isEditing = content.state.isEditing;
  const isLoading = !utils.isDefined(statusGroup?.name);

  const randomColor = useMemo(() => innerProps.theme.pickAColor('statusGroup'), [innerProps.theme]);
  const randomIcon = useMemo(() => innerProps.theme.pickAnIcon('dealflow'), [innerProps.theme]);

  const fields = useMemo(() => {
    const fields = [];

    const initialColor = statusGroup?.color ?? (isAddNew ? randomColor : null);
    const initialIcon = statusGroup?.icon ?? (isAddNew ? randomIcon : null);

    fields.push({
      name: 'name',
      label: 'Name',
      type: constants.formFieldTypes.text,
      entity: 'statusGroup',
      formGroup: 'header',
      initial: statusGroup?.name,
      required: true,
      validate: (value, testContext) => {
        const duplicate = client?.dealflowGroups?.find((dfg) => +statusGroup?.groupId !== +dfg.groupId &&
          dfg.name.toLowerCase() === value.toLowerCase());

        if (duplicate) {
          return testContext.createError({message: `Enter a unique group name, '${value}' already exists`});
        } else {
          return true;
        }
      },
      FormFieldProps: {
        fullWidth: false,
        variant: 'inlineLabel',
        hiddenLabel: true,
        size: 'smaller'
      }
    });

    fields.push({
      title: 'Settings',
      formGroup: 'context',
      section: true
    });

    fields.push({
      name: 'description',
      label: 'Description',
      type: constants.formFieldTypes.textarea,
      entity: 'statusGroup',
      formGroup: 'context',
      initial: statusGroup?.description,
      required: false,
      FormFieldProps: {
        variant: 'staticLabel',
        hiddenLabel: true,
        size: 'smaller',
        minRows: 2
      }
    });

    fields.push({
      name: 'short',
      label: 'Short',
      type: constants.formFieldTypes.text,
      validation: [constants.formFieldValidationTypes.text,
        `${constants.formFieldValidationTypes.min}(1)`, `${constants.formFieldValidationTypes.max}(1)`],
      entity: 'statusGroup',
      formGroup: 'context',
      initial: statusGroup?.short || '?',
      required: false,
      FormFieldProps: {
        hidden: true,
        hiddenLabel: false,
        variant: 'inlineLabel',
        size: 'smaller',
      }
    });

    fields.push({
      name: 'color',
      label: 'Color',
      formGroup: 'context',
      entity: 'statusGroup',
      type: constants.formFieldTypes.popper,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      initial: initialColor,
      readOnly: false,
      FormFieldProps: {
        hiddenLabel: false,
        variant: 'inlineLabel',
        size: 'smallest',
        fullWidth: true,
        FormFieldComponent: ColorField,
        FormFieldProps: {
          colorSet: 'statusGroup',
          IconButtonProps: {variant: 'outlined', size: 'smaller', density: 'normal'},
          IconProps: {size: 'smaller'}
        },
        PopperProps: {
          ContextPopperProps: {
            density: 'dense',
            size: 'medium',
            placement: 'bottom-end'
          }
        },
        renderButton: (value, {ref, className, readOnly, disabled, onClick}) => {
          if (readOnly) {
            return <Icon icon={Circle} size="small" color={utils.deprecatedColor(value?.value ?? value) || 'dealflowDefault'}/>
          } else {
            return <IconButton ref={ref}
                               className={className}
                               variant="outlined"
                               size="smaller"
                               density="sparse"
                               color={utils.deprecatedColor(value?.value ?? value) || 'dealflowDefault'}
                               disabled={Boolean(disabled || readOnly)}
                               onClick={onClick}>
              <Icon icon={Circle} size="smaller"/>
            </IconButton>
          }
        }
      }
    });

    fields.push({
      name: 'icon',
      label: 'Icon',
      formGroup: 'context',
      entity: 'statusGroup',
      type: constants.formFieldTypes.popper,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      initial: initialIcon,
      readOnly: false,
      FormFieldProps: {
        hiddenLabel: false,
        variant: 'inlineLabel',
        size: 'smallest',
        fullWidth: true,
        FormFieldComponent: IconField,
        FormFieldProps: {
          iconSet: 'dealflow',
          IconButtonProps: {
            variant: 'outlined',
            size: 'smaller',
            density: 'normal',
            color: utils.deprecatedColor(formValues.color ?? statusGroup?.color ?? initialColor) ?? 'dealflowDefault'
          },
          IconProps: {
            size: 'smaller',
            color: utils.deprecatedColor(formValues.color ?? statusGroup?.color ?? initialColor) ?? 'dealflowDefault'
          }
        },
        PopperProps: {
          ContextPopperProps: {
            density: 'dense',
            size: 'medium',
            placement: 'bottom-end'
          }
        },
        renderButton: (value, {ref, className, readOnly, disabled, onClick}) => {
          if (readOnly) {
            return <DealflowIcon groupId={statusGroup?.groupId} size="smaller"
                                 icon={constants.icons.dealflow[(value?.value ?? value)]}
                                 color={utils.deprecatedColor(formValues.color ?? statusGroup?.color ?? initialColor) ?? 'dealflowDefault'}
                                 showTooltip={false}/>
          } else {
            return <IconButton ref={ref}
                               className={className}
                               variant="outlined"
                               size="smaller"
                               density="sparse"
                               color={utils.deprecatedColor(formValues.color ?? statusGroup?.color ?? initialColor) ?? 'dealflowDefault'}
                               disabled={Boolean(disabled || readOnly)}
                               onClick={onClick}>
              <DealflowIcon groupId={statusGroup?.groupId}
                            size="smaller"
                            icon={constants.icons.dealflow[(value?.value ?? value)]}
                            color={utils.deprecatedColor(formValues.color ?? statusGroup?.color ?? initialColor) ?? 'dealflowDefault'}
                            showTooltip={false} />
            </IconButton>
          }
        }
      }
    });

    fields.push({
      name: 'statuses',
      label: 'Statuses',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.status}))`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'statusGroup',
      valueProp: 'statuses',
      formGroup: 'content',
      Component: <StatusesForm />,
      initial: {
        value: statusGroup?.statuses ?? [],
        errors: false
      },
      required: true,
      FormFieldProps: {
        variant: 'staticLabel',
        hiddenLabel: false,
        autoFocus: false
      }
    });

    return fields
      .filter((f) => !f.readOnly || !utils.isEmpty(f.initial));
  }, [statusGroup, randomColor, randomIcon, isAddNew, client?.dealflowGroups, formValues.color]);

  const handleSubmit = (values, actions) => {
    const name = `statusGroup-${statusGroup?.groupId ?? 0}`;
    const fields = [{
      name
    }];

    const changes = {
      [name]: {
        ...statusGroup,
        groupId: statusGroup?.groupId ?? 0,
        ...values,
        statuses: values['statuses']
          .map((s) => ({
            ...s,
            statusId: s.statusId <= 0 ? null : s.statusId
          }))
      },
    };

    return onSubmit(changes, actions, fields, changes[name], true);
  }

  const handleValidating = (validating, dirty, errors) => {
    onValidating?.(validating, isAddNew || dirty, errors);
  }

  const onValidatingEvent = useEffectEvent(onValidating);
  useEffect(() => {
    if (isAddNew && isEditing) {
      onValidatingEvent?.(false, true, false);
    }
  }, [isAddNew, isEditing, onValidatingEvent]);

  const renderReadOnly = () => {
    return <StatusGroupCard statusGroup={statusGroup}
                            isLoading={isLoading} />
  };

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    setFormValues(utils.updater({[field.name]: (value?.value ?? value)}, true));
  }

  const renderForm = () => {
    const renderHeader = (renderedFields) => {
      return <DialogHeader title={<React.Fragment>{renderedFields}</React.Fragment>} />
    }

    const renderContext = (renderedFields) => {
      return <Box className="DealflowStatusGroupProfileCardContent-form-context">
        <Box className="Form-fields">
          {renderedFields}
        </Box>
      </Box>
    }

    const renderFormContent = (groups) => {
      const header = groups.find((g) => g.name === 'header');
      const context = groups.find((g) => g.name === 'context');
      const content = groups.find((g) => g.name === 'content');

      return <React.Fragment>
        {renderHeader(header.rendered)}
        <DialogContent className="ProfileCardDialog-content">
          <ContextWrapper context={renderContext(context.rendered)}
                          open={true}
                          size="smaller">
            <Box className="Form-fields DealflowStatusGroupProfileCardContent-form-content">
              {content.rendered}
            </Box>
          </ContextWrapper>
        </DialogContent>
      </React.Fragment>
    }

    return <InlineForm ref={formRef}
                       className="DealflowStatusGroupProfileCardContent-form"
                       fields={fields}
                       fieldData={fieldData}
                       renderContent={renderFormContent}
                       onSubmit={handleSubmit}
                       onChangeDirect={handleChangeDirect}
                       onValidating={handleValidating}/>
  }

  const renderNew = () => {
    return <Card className="DealflowStatusGroupProfileCardContent-new" fullWidth={true} fullHeight={true}>
      <Button variant="contained"
              startIcon={<Icon icon={Add} />}
              onClick={() => {
                card.edit()
              }}>
        Add group
      </Button>
    </Card>
  };

  delete innerProps.source;
  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isEditing: isEditing
  });

  return <StyledDealflowStatusGroupProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="DealflowStatusGroupProfileCardContent-content">
      {!isDialog ? (isAddNew ? renderNew() : renderReadOnly()) : renderForm()}
    </Box>
  </StyledDealflowStatusGroupProfileCardContent>
});

DealflowStatusGroupProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  client: PropTypes.object,
  content: PropTypes.object,
  statusGroup: PropTypes.object,
  isDialog: PropTypes.bool,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func
};

DealflowStatusGroupProfileCardContent.defaultProps = {
};

export default DealflowStatusGroupProfileCardContent;


