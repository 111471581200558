import React, {useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useUploadFiles} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Add from '@mui/icons-material/Add';
import utils from 'helpers/utils';
import StyledEntityFilesContextCard
  from 'components/organisms/Cards/EntityFilesContextCard/EntityFilesContextCard.styles';
import {
  useEntityUploadAdd,
  useEntityUploadDelete,
  useEntityUploadGet,
  useEntityUploadUpdate
} from 'services/entity/upload/upload.hooks';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import FileUploadDialog from 'components/organisms/Dialogs/FileUploadDialog/FileUploadDialog';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';

const EntityFilesContextCard = React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    canUpdate,
    ...innerProps
  } = useComponentProps(props, 'EntityFilesContextCard');

  const innerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [download, setDownload] = useState(null);

  useImperativeHandle(ref, () => innerRef.current);

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const [uploadState, setUploadState, uploadFiles] = useUploadFiles();

  const createFile = useEntityUploadAdd();
  const updateFile = useEntityUploadUpdate();
  const deleteFile = useEntityUploadDelete();

  const file = useEntityUploadGet({entityId: entity?.entityId, uploadId: download?.uploadId}, {
    ...constants.queryOptions.runOnceFresh,
    enabled: +entity?.entityId > 0 && +download?.uploadId > 0
  });

  const FileFieldProps = useMemo(() => {
    const fileState = uploadState ?
      Object.keys(uploadState).reduce((o, k) => {
        const split = k.split('_');
        o[split[1]] = uploadState[k];
        return o;
      }, {}) : null;

    return {
      validate: (value, testContext) => {
        const missing = (value ?? [])
          .some((f) => (f.description?.length ?? 0) === 0);

        if (missing) {
          return testContext.createError({message: 'Enter a valid description for all files'});
        } else {
          return true;
        }
      },
      FormFieldProps: {
        multiple: true,
        addDescription: true,
        status: fileState,
        minSize: 1,
        maxSize: constants.numbers.GB
      }
    }
  }, [uploadState]);

  const action = useMemo(() => ({
    auth: utils.createAuth({attribute: 'entity.file.create'}),
    onClick: (e) => {
      setOpen(true);
      e.preventDefault();
    },
    label: 'Upload a file',
    icon: Add
  }), []);

  const authorize = useAuthorize();

  const filesListProps = useMemo(() => ({
    onClick: (file) => {
      setDownload(file);
    },
    FileCardProps: {
      onDelete: (file) => {
        const handleConfirm = () => {
          setFiles((current) => current.filter((f) => +f.uploadId !== +file.uploadId));

          deleteFile.mutation.mutateAsync({entityId: entity.entityId, uploadId: file.uploadId})
            .catch(() => {
              setFiles(entity.fileUploads);
              snackbar.show('Removing file failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
        }

        dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this file?"
                                          explanation="The file will be removed permanently"
                                          onConfirm={handleConfirm}
                                          ConfirmButtonProps={{
                                            children: 'Remove file',
                                            startIcon: <Icon icon={Delete} />,
                                            color: 'error'
                                          }}/>, true);
      },
      onCanUpdate: (file, isDelete = false) => {
        return canUpdate && authorize({
          attribute: isDelete ? 'entity.file.delete' : 'entity.file.update',
          meta: { entity, file }
        });
      }
    }
  }), [entity, authorize, canUpdate, snackbar, dialogControl, deleteFile.mutation]);

  useLayoutEffect(() => {
    setFiles(entity?.fileUploads);
  }, [entity?.fileUploads]);

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.querySelector('.FilesContextCard-files')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [entity?.entityId]);

  useEffect(() => {
    if (file.status.isSuccess) {
      if (file?.data?.filename && file?.data?.downloadUrl) {
        utils.fileDownload(file.data.filename, file.data.downloadUrl);
      }
      setDownload(null);
    }
  }, [file?.data?.filename, file?.data?.downloadUrl, file.status.isSuccess]);

  const handleUploadChange = () => {
    setUploadState({});
  }

  const handleUploadSubmit = (files) => {
    return Promise.all(utils.toArray(files, true).map((file, idx) => {
      return createFile.mutation.mutateAsync({
        entityId: entity?.entityId,
        filename: file.name,
        description: file.description,
        filesize: file.size,
        filetype: file.type || constants.filetypes.default
      })
        .then((res) => {
          const upload = utils.camelcase(res.response.data.data);
          const filename = upload.filename;
          const uploadId = upload.fileUploadId;
          const uploadUrl = upload.uploadUrl;

          return uploadFiles([{file, id: `file_${idx}`, url: uploadUrl, name: filename}], true)
            .then(() => {
              return updateFile.mutation.mutateAsync({
                entityId: entity?.entityId,
                uploadId, finished: true
              });
            })
        })
    }))
      .then(() => {
        setUploadState({});
      });
  }

  const handleUploadClose = () => {
    setOpen(false);
  }

  const renderDialog = () => {
    if (open) {
      return <FileUploadDialog open={true}
                               onChange={handleUploadChange}
                               onSubmit={handleUploadSubmit}
                               onClose={handleUploadClose}
                               FileFieldProps={FileFieldProps} />
    }
  }

  return <StyledEntityFilesContextCard ref={innerRef} {...innerProps}
                                       action={action}
                                       files={files}
                                       FilesListProps={filesListProps}>
    {renderDialog()}
  </StyledEntityFilesContextCard>
});

EntityFilesContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  collection: PropTypes.object,
  canUpdate: PropTypes.bool
};

EntityFilesContextCard.defaultProps = {};

export default EntityFilesContextCard;
