import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledCollectionEntitiesGraphsPage = styled(Page)`
  padding: ${props => props.theme.spacing(3)};
  background-color: ${props => props.theme.property('palette.primary.states.hover')};
  
  .CollectionEntitiesGraphsPage {
    &-dealflowBreakdown {
      aspect-ratio: 536/468;
    }
    &-completeness {
      aspect-ratio: 758/470;
      min-height: ${props => props.theme.layout(346)};
    }
    &-recentChanges {
      aspect-ratio: 813/577;
      min-height: ${props => props.theme.layout(346)};
    }
    &-relevancyDistribution {
      min-height: ${props => props.theme.layout(346)};
    }
    &-tagDistribution {
      aspect-ratio: 480/520;
    }
    &-tasks-wrapper {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('3sp')};
      
      > *:not(:first-child) {
        flex-grow: 1;
      }
    }
    &-tasks {
      height: ${props => props.theme.layout(226)};
    }
    &-openTasks {
      min-height: ${props => props.theme.layout(440)};
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    padding: ${props => props.theme.spacing(2)};
    
    .CollectionEntitiesGraphsPage {
      &-dealflowBreakdown {
        max-height: ${props => props.theme.layout(444)};
      }
      &-tagDistribution {
        max-height: ${props => props.theme.layout(544)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesGraphsPage.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesGraphsPage.defaultProps = {
};

export default StyledCollectionEntitiesGraphsPage;
