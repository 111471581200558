import React, {useMemo} from 'react'
import ProfileProvider from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useClientAdd, useClientDelete, useClientGet} from 'services/client/client.hooks';
import {
  useMergeCardDefinitions,
  useMergeFieldData,
  useMergeView,
  useProviderDataState,
  useProviderView
} from 'helpers/hooks/utils';
import {useCollectionGet} from 'services/collection/collection.hooks';
import {
  useClientCallbacks,
  useClientGroups,
  useClientCollaboratorCreate,
  useClientCollaboratorDelete,
  useClientCollaboratorPatch,
  useClientPatch
} from 'services/client/client.utils';
import {
  useClientCustomFieldAdd,
  useClientCustomFieldCache,
  useClientCustomFieldDelete
} from 'services/client/customField/customField.hooks';
import constants from 'helpers/constants';
import {useCollectionPatch} from 'services/collection/collection.utils';
import {useCollectionTagGroupPatch} from 'services/collection/tagGroup/tagGroup.utils';
import {
  useCollectionTagGroupAdd,
  useCollectionTagGroupDelete,
  useCollectionTagGroupMove
} from 'services/collection/tagGroup/tagGroup.hooks';
import {useSourceCreate} from 'services/source/source.utils';
import {useSourceDelete} from 'services/source/source.hooks';
import utils from 'helpers/utils';
import {useClientStatusMove, useClientStatusPatch} from 'services/client/status/status.utils';
import {useClientStatusAdd, useClientStatusDelete} from 'services/client/status/status.hooks';
import {useParams} from 'react-router-dom';
import {useClientCustomFieldPatch} from 'services/client/customField/customField.utils';

const ClientProfileProvider = (props) => {
  const params = useParams();
  const currentClientId = useAuthClientId();
  const { clientId } = {clientId: currentClientId, ...params, ...props};

  const dataKey = props.dataKey ?? 'clientId';
  const clientView = useProviderView('client', props.skipView);
  const view = useMergeView(clientView, props.view);

  const client = useClientGet({clientId}, {enabled: Boolean(!props.data && clientId >= 0)});
  const collection = useCollectionGet({collectionId: client.data?.universeCollectionId},
    {enabled: !props.context && +client.data?.universeCollectionId > 0});

  const [data, onData] = useProviderDataState(props.data ?? client);
  const [context, onContext] = useProviderDataState(props.context ?? collection);

  const groups = useClientGroups(view, client?.data, collection?.data);

  // isLoading anything, is loading something definition related
  const isLoading = props?.isLoading || client.status?.isLoading || collection.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading || !client.status?.isSuccess || !collection.status?.isSuccess;

  const clientCustomFields = useClientCustomFieldCache({clientId: client.data?.clientId},
    {enabled: client.data?.clientId >= 0});
  const customFields = (clientCustomFields.data?.data ?? clientCustomFields.data);

  const clientCallbacks = useClientCallbacks();

  const clientFieldData = useMemo(() => ({
    currency: constants.data.lookup('currencies', client.data?.props?.currency),
    customFields: customFields,
    tagGroups: collection.data?.tagGroups,
    callbacks: clientCallbacks
  }), [collection.data?.tagGroups, clientCallbacks, customFields, client.data?.props?.currency]);
  const fieldData = useMergeFieldData(clientFieldData, props.fieldData);
  const cardDefinitions = useMergeCardDefinitions(groups, props.options?.cardDefinitions);

  const options = useMemo(() => {
    const options = {
      cardState: {
        options: {
          name: 'client',
          type: constants.appState.type.local
        }
      },
      graphState: {
        initial: {},
        options: {
          name: 'client',
          type: constants.appState.type.local
        }
      },
      cardDefinitions
    };

    return {
      ...(utils.mergeObjects(options, props.options)),
      cardDefinitions
    }
  }, [props.options, cardDefinitions]);

  const createData = useClientAdd();
  const deleteData = useClientDelete();
  const updateData = useClientPatch();
  const createCollaborator = useClientCollaboratorCreate();
  const deleteCollaborator = useClientCollaboratorDelete();
  const updateCollaborator = useClientCollaboratorPatch();
  const updateCollection = useCollectionPatch();
  const updateTagGroup = useCollectionTagGroupPatch();
  const createTagGroup = useCollectionTagGroupAdd();
  const deleteTagGroup = useCollectionTagGroupDelete();
  const moveTagGroup = useCollectionTagGroupMove();
  const updateCustomField = useClientCustomFieldPatch();
  const createCustomField = useClientCustomFieldAdd();
  const deleteCustomField = useClientCustomFieldDelete();
  const updateStatusGroup = useClientStatusPatch();
  const createStatusGroup = useClientStatusAdd();
  const deleteStatusGroup = useClientStatusDelete();
  const moveStatusGroup = useClientStatusMove();
  const createSource = useSourceCreate();
  const deleteSource = useSourceDelete();
  const updaters = useMemo(() => {
    return {
      createData: (data) => {
        return createData.mutation.mutateAsync(data);
      },
      deleteData: (data) => {
        return deleteData.mutation.mutateAsync(data);
      },
      updateData: updateData,
      createCollaborator: createCollaborator,
      deleteCollaborator: deleteCollaborator,
      updateCollaborator: updateCollaborator,
      updateCollection: updateCollection,
      createTagGroup: (data) => {
        return createTagGroup.mutation.mutateAsync({
          collectionId: collection.data?.collectionId,
          tagsGroup: utils.toArray(data).map((tg) => ({...tg, tags: utils.underscoreEx(tg.tags)}))
        });
      },
      deleteTagGroup:  (data) => {
        return deleteTagGroup.mutation.mutateAsync({
          collectionId: collection.data?.collectionId,
          groupId: data?.groupId
        });
      },
      updateTagGroup: updateTagGroup,
      moveTagGroup: (data) => {
        return moveTagGroup.mutation.mutateAsync({
          collectionId: collection.data?.collectionId,
          groupId: data?.groupId,
          pos: data?.pos
        });
      },
      createCustomField: (data) => {
        return createCustomField.mutation.mutateAsync({
          clientId: client.data?.clientId,
          ...data
        });
      },
      deleteCustomField:  (data) => {
        return deleteCustomField.mutation.mutateAsync({
          clientId: client.data?.clientId,
          fieldId: data?.fieldId
        });
      },
      updateCustomField: updateCustomField,
      createStatusGroup: (data) => {
        return createStatusGroup.mutation.mutateAsync({
          clientId: client.data?.clientId,
          group: {...utils.underscoreEx(data)}
        });
      },
      deleteStatusGroup: (data) => {
        return deleteStatusGroup.mutation.mutateAsync({
          clientId: client.data?.clientId,
          groupId: data?.groupId
        });
      },
      updateStatusGroup: updateStatusGroup,
      moveStatusGroup: moveStatusGroup,
      createSource: createSource,
      deleteSource: (data) => {
        return deleteSource.mutation.mutateAsync(data);
      },
      ...props.updaters
    }
  }, [props.updaters, createData.mutation, deleteData.mutation, updateData,
    createCollaborator, deleteCollaborator, updateCollaborator,
    updateCollection, moveTagGroup.mutation, updateTagGroup, createTagGroup.mutation,
    deleteTagGroup.mutation, createStatusGroup.mutation, deleteStatusGroup.mutation,
    updateStatusGroup, moveStatusGroup, createSource, deleteSource.mutation,
    updateCustomField, createCustomField.mutation, deleteCustomField.mutation,
    client.data?.clientId, collection.data?.collectionId]);

  return <ProfileProvider {...props}
                          view={view}
                          dataKey={dataKey}
                          context={context}
                          data={data}
                          onContext={onContext}
                          onData={onData}
                          options={options}
                          fieldData={fieldData}
                          updaters={updaters}
                          isDefinitionsLoading={isDefinitionsLoading}
                          isLoading={isLoading}>
    {props.children}
  </ProfileProvider>
};

ClientProfileProvider.propTypes = {
}

export default ClientProfileProvider;
