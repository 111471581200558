import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledAdvancedQueryFilter = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${props => props.theme.layout('1sp')};
  width: 100%;
  
  .AdvancedQueryFilter {
    &-form {
      width: unset;
      max-width: calc(100% - ${props => props.theme.layout(44)});
      
      .Form-fields {
        display: flex;
        flex-wrap: nowrap;

        .FormControl {
          .FormHelperText {
            * {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        
        .FormField-name {
          &-type {
            min-width: ${props => props.theme.layout(80)};
            max-width: ${props => props.theme.layout(80)};
            
            .TextField-disabled {
              .MuiInputBase-root {
                background-color: ${props => props.theme.property('palette.action.disabledBackground')};
              }
            }
          }

          &-filter {
            min-width: ${props => props.theme.layout(200)};
            max-width: ${props => props.theme.layout(200)};
          }

          &-operator {
            min-width: ${props => props.theme.layout(192)};
            max-width: ${props => props.theme.layout(192)};
          }
          
          &-between {
            align-self: flex-start;
            margin-top: ${props => props.theme.layout(10)};
            max-width: fit-content;
          }

          &-value {
            min-width: ${props => props.theme.layout(224)};
            
            &:not(.TextField-multiline) {
              width: 100% !important;
            }
            &.TextField-multiline {
              min-width: unset !important;
            }
          }

          &-valueFrom, &-valueTo {
            min-width: ${props => props.theme.layout(154)};
            max-width: ${props => props.theme.layout(154)};
          }
        }

        .InputContainer {
          .MuiInputBase-root {
            background-color: ${props => props.theme.property('palette.background.paper')};
          }
        }
      }
    }
  }
  
  &.AdvancedQueryFilter {
    > span:has(.AdvancedQueryFilter-delete) {
      align-self: flex-start;
      margin-top: ${props => props.theme.layout(2)};
    }
  }

  &.AdvancedQueryFilter-size-smaller {
    .AdvancedQueryFilter {
      &-form {
        .Form-fields {
          .FormField-name {
            &-filter {
              min-width: ${props => props.theme.layout(160)};
              max-width: ${props => props.theme.layout(160)};
            }

            &-operator {
              min-width: ${props => props.theme.layout(162)};
              max-width: ${props => props.theme.layout(162)};
            }
            
            &-valueFrom, &-valueTo {
              min-width: ${props => props.theme.layout(134)};
              max-width: ${props => props.theme.layout(134)};
            }
          }
        }
      }
    }
    
    > span:has(.AdvancedQueryFilter-delete) {
      margin-top: 0;
    }
  }
  
  ${ComponentStyles}
`;

StyledAdvancedQueryFilter.propTypes = {
  theme: PropTypes.object
};

StyledAdvancedQueryFilter.defaultProps = {}

export default StyledAdvancedQueryFilter;
