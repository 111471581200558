import React, {useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledAdvancedQueryFilter from 'components/organisms/Queries/AdvancedQueryFilter/AdvancedQueryFilter.styles';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Delete from '@mui/icons-material/Delete';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import EntityCard from 'components/molecules/Cards/EntityCard/EntityCard';

const AdvancedQueryFilter = React.forwardRef((props, ref) => {
  const {
    part,
    first,
    indent,
    size,
    parentType,
    canDelete,
    canUpdate,
    filterGroups,
    required,
    error,
    fieldData,
    onChange,
    onDelete,
    onError,
    onSwitch,
    ...innerProps
  } = useComponentProps(props, 'AdvancedQueryFilter', {
    static: ['first'],
    styled: ['indent']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const partRef = useRef(null);

  const advancedQueryFilter = useMemo(() => ({
    refs: {
      ref: innerRef,
      formRef,
      partRef
    },
    submit: (touch = true) => {
      return formRef.current?.submit(touch);
    },
    validate: (touch = true) => {
      return formRef.current?.validate(touch);
    },
    reset: (state) => {
      return formRef.current?.reset(state);
    }
  }), []);

  useImperativeHandle(ref, () => advancedQueryFilter);

  const [internalState, setInternalState] = useState({
    type: null,
    filter: null,
    value: null,
    operator: null
  });

  const filters = useMemo(() => {
    let filters = [];
    if (filterGroups) {
      filterGroups.sort((a, b) => {
        return a.position - b.position;
      }).forEach((fg) => {
        filters = filters.concat(fg.filters.sort((a, b) => {
          return a.position - b.position;
        }).map((f) => ({
          ...f,
          value: f.id
        })));
      })
    }

    return filters;
  }, [filterGroups]);

  useLayoutEffect(() => {
    if (filters?.length > 0 && partRef.current?.id !== part.id) {
      partRef.current = part;

      const filterArray = utils.object2Filter(part.filter ?? {}).filter((f) => f.id !== 'switch');
      const isWizard = utils.isDefined(part.wizard) && filterArray.length === 0;
      const isExamples = utils.isDefined(part.examples) && filterArray.length === 0;
      const isTerms = utils.isDefined(part.terms) && !isWizard && filterArray.length === 0;

      const filter = isTerms ? 'terms' : (
        isWizard ? 'wizard' : (
          isExamples ? 'examples' : (
            utils.toArray(filterArray).length > 0 ? filterArray[0].id : null
          )
        )
      );
      const filterDef = filters.find((f) => filter === f.value);

      let value = isTerms ? part.terms.map((t) => t.term) : (
        isWizard ? part.wizard : (
          isExamples ? part.examples : (
            utils.toArray(filterArray).length > 0 ? filterArray[0].value : null
          )
        )
      );

      if (!isTerms && !isWizard && !isExamples) {
        if (value) {
          const convertBack = (v) => {
            v = (v.toString().startsWith('-')) ? v.toString().slice(1) : v.toString();
            if (!filterDef?.staticOptions) {
              v = (v.toString().startsWith('*')) ? v.toString().slice(1) : v.toString();
              v = (v.toString().endsWith('*')) ? v.toString().slice(0, -1) : v.toString();
            }

            return v;
          }

          if (utils.isArray(value)) {
            value = value.map(convertBack).filter((v) => v !== '0');
          } else {
            value = convertBack(value);
            value = value === '0' ? null : value;
          }
        }
      }

      let valueFrom, valueTo;
      let termsFunctor = part.termsFunctor;
      let operator = part.filterOperator;
      const operatorDef = constants.data.lookup('queryFilterOperators', operator);
      if (isTerms) {
        if (termsFunctor === constants.query.termFunctors.andNot) {
          operator = constants.query.filterOperators.notContainsAll;
        } else if (termsFunctor === constants.query.termFunctors.orNot) {
          operator = constants.query.filterOperators.notContains;
        } else if (termsFunctor === constants.query.termFunctors.and) {
          operator = constants.query.filterOperators.containsAll;
        } else {
          operator = constants.query.filterOperators.contains;
        }
      } else if (operatorDef) {
        if (operatorDef.isBetween) {
          valueFrom = utils.toInt(value.toString().split(':')[0]);
          valueTo = utils.toInt(value.toString().split(':')[1]);
        } else if (operatorDef.value === constants.query.filterOperators.gt) {
          value = utils.toInt(value.toString().split(':')[0]) - 1;
        } else if (operatorDef.value === constants.query.filterOperators.gte) {
          value = utils.toInt(value.toString().split(':')[0]);
        } else if (operatorDef.value === constants.query.filterOperators.lt) {
          value = utils.toInt(value.toString().split(':')[1]) + 1;
        } else if (operatorDef.value === constants.query.filterOperators.lte) {
          value = utils.toInt(value.toString().split(':')[1]);
        }
      }

      const type = first ? constants.query.partTypes.if : (
        parentType ? parentType : part.queryType ?? constants.query.partTypes.and
      );

      setInternalState(utils.updater({
        type: type,
        filter: filterDef?.value,
        value: !operatorDef?.isBetween ? value : null,
        valueFrom: operatorDef?.isBetween ? valueFrom : null,
        valueTo: operatorDef?.isBetween ? valueTo : null,
        operator
      }, true));
    }
  }, [part, filters, first, parentType]);

  useEffect(() => {
    if (required && error) {
      return utils.observeTimeout(() => {
        try {
          formRef.current?.validate(true);
        } catch (e) {
          /* SQUASH */
        }
      }, constants.debounce.minimal)
    }
  }, [required, error]);

  const fields = useMemo(() => {
    const fields = [];
    const filterDef = filters.find((f) => internalState.filter === f.id);

    const operatorOptions = constants.data.queryFilterOperators.filter((qfo) => {
      return filterDef?.operators.find((o) => qfo.value === o);
    });
    const freeText = filterDef && operatorOptions.length === 0;
    const operator = internalState.operator ?? operatorOptions[0]?.value;
    const operatorDef = constants.data.lookup('queryFilterOperators', operator);
    const betweenOperators = constants.data.queryFilterOperators.filter((o) => o.isBetween);
    const valueOperators = constants.data.queryFilterOperators.filter((o) => !o.isBetween && !o.isEmpty);

    const queryPartTypes = constants.data.queryPartTypes.filter((type) => {
      return ![constants.query.partTypes.if, constants.query.partTypes.andNot, constants.query.partTypes.orNot].includes(type.value)
    });

    fields.push({
      name: 'type',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      required: true,
      initial: internalState.type,
      disabled: first || !canUpdate,
      FormFieldProps: {
        autoFocus: false,
        clearable: false,
        hiddenLabel: true,
        hiddenHelperText: true,
        size: size
      },
      options: queryPartTypes
    });

    fields.push({
      name: 'filter',
      placeholder: 'Filter',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      required: !first || required,
      initial: internalState.filter,
      disabled: !canUpdate,
      FormFieldProps: {
        hiddenLabel: true,
        autoFocus: false,
        clearable: false,
        size: size
      },
      options: filters
    });

    if (filterDef) {
      if (operatorOptions.length > 1) {
        fields.push({
          name: 'operator',
          placeholder: 'Operator',
          type: constants.formFieldTypes.autocomplete,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.value,
          required: Boolean(filterDef && canUpdate),
          initial: operator,
          disabled: !(filterDef && canUpdate),
          FormFieldProps: {
            hiddenLabel: true,
            autoFocus: false,
            clearable: false,
            size: size
          },
          options: operatorOptions
        });
      }

      const fieldType = (operatorDef?.multiple || filterDef?.staticOptions) ? constants.formFieldTypes.autocomplete :
        (filterDef?.type ?? constants.formFieldValidationTypes.text)
      const fieldValidation = operatorDef?.multiple ? constants.formFieldValidationTypes.list : (
        filterDef?.staticOptions ? constants.formFieldValidationTypes.text :
          (filterDef?.validation ?? constants.formFieldValidationTypes.text)
      );
      const fieldConversion = filterDef?.conversion ?? (
        filterDef?.staticOptions ? constants.formFieldConversionTypes.value : constants.formFieldConversionTypes.label
      );

      fields.push({
        ...filterDef,
        name: 'valueFrom',
        inlineLabel: 'From value',
        placeholder: 'From value',
        relation: `operator(${betweenOperators.map((o) => o.value).join(',')})`,
        type: fieldType,
        validation: fieldValidation,
        conversion: fieldConversion,
        required: Boolean(filterDef && canUpdate),
        initial: internalState.valueFrom,
        debounce: freeText ? (constants.formFieldTypes.textTypes.singleLine.includes(fieldType) ? constants.debounce.input : constants.debounce.multiline) : false,
        disabled: !(filterDef && canUpdate),
        FormFieldProps: {
          ...filterDef?.FormFieldProps,
          autoFocus: false,
          hiddenLabel: true,
          ...(filterDef?.staticOptions ? {
            multiple: false,
            size: size
          } : {
            /* text props */
          })
        }
      });

      fields.push({
        name: 'between',
        type: constants.formFieldTypes.text,
        relation: `operator(${betweenOperators.map((o) => o.value).join(',')})`,
        readOnly: true,
        initial: 'AND',
        FormFieldProps: {
          hiddenLabel: true,
          size: size
        }
      });

      fields.push({
        ...filterDef,
        name: 'valueTo',
        inlineLabel: 'To value',
        placeholder: 'To value',
        relation: `operator(${betweenOperators.map((o) => o.value).join(',')})`,
        type: fieldType,
        validation: fieldValidation,
        conversion: fieldConversion,
        required: Boolean(filterDef && canUpdate),
        initial: internalState.valueTo,
        debounce: freeText ? (constants.formFieldTypes.textTypes.singleLine.includes(fieldType) ? constants.debounce.input : constants.debounce.multiline) : false,
        disabled: !(filterDef && canUpdate),
        FormFieldProps: {
          ...filterDef?.FormFieldProps,
          autoFocus: false,
          hiddenLabel: true,
          ...(filterDef?.staticOptions ? {
            multiple: false,
            size: size
          } : {
            /* text props */
          })
        }
      });

      fields.push({
        ...filterDef,
        name: 'value',
        label: (freeText || operatorDef?.multiple || filterDef?.staticOptions) ? filterDef?.label : 'Value',
        inlineLabel: (freeText || operatorDef?.multiple || filterDef?.staticOptions) ? filterDef?.inlineLabel : 'Value',
        placeholder: (freeText || operatorDef?.multiple || filterDef?.staticOptions) ? (filterDef?.placeholder ?? 'Type here') : 'Value',
        relation: (utils.isDefined(filterDef) && operatorOptions.length > 1) ?
          `operator(${valueOperators.map((o) => o.value).join(',')})` : null,
        type: fieldType,
        validation: fieldValidation,
        conversion: fieldConversion,
        required: Boolean(filterDef && canUpdate),
        initial: internalState.value,
        debounce: freeText ? (constants.formFieldTypes.textTypes.singleLine.includes(fieldType) ? constants.debounce.input : constants.debounce.multiline) : false,
        disabled: !(filterDef && canUpdate),
        FormFieldProps: {
          ...filterDef?.FormFieldProps,
          multiple: Boolean(operatorDef?.multiple),
          clearable: filterDef?.staticOptions,
          createOption: !filterDef?.staticOptions,
          openDirect: filterDef?.staticOptions,
          openOnFocus: filterDef?.staticOptions,
          autoFocus: false,
          hiddenLabel: true,
          size: size,
          minRows: 1,
          minWidth: freeText ? 600 : (operatorOptions.length === 1 ? 336 : null),
          TagProps: {
            color: 'primary',
            variant: 'transparent'
          },
          renderOption: (option, state, props) => {
            if (filterDef.options === 'entities') {
              return <EntityCard entity={option.entity}
                                 variant="suggestion"
                                 radius="square"
                                 fullWidth={true}
                                 showLink={true}
                                 showStatus={false}
                                 showRelevancy={false}/>
            } else {
              return props.renderedOption;
            }
          }
        }
      });
    }

    return fields;
  }, [first, size, canUpdate, internalState, filters, required]);

  const onDeleteEvent = useEffectEvent(onDelete);
  const deleteAction = useMemo(() => ({
    tooltip: 'Remove part',
    icon: Delete,
    onClick: () => {
      onDeleteEvent?.(part);
    },
    ActionIconButtonProps: {
      size: 'smaller',
      density: 'sparse',
      variant: 'outlined',
      IconProps: {
        size: 'smaller'
      }
    },
    IconButtonProps: {
      disabled: !canDelete || !canUpdate || (first && !internalState.filter)
    }
  }), [onDeleteEvent, canDelete, canUpdate, part, first, internalState.filter]);

  const doSubmit = (values, actions) => {
    const filterDef = filters.find((f) => values['filter'] === f.value);
    const operatorDef = constants.data.lookup('queryFilterOperators', values['operator']);
    const isTerms = values['filter'] === 'terms';
    const isWizard = values['filter'] === 'wizard';
    const isExamples = values['filter'] === 'examples';

    const isExactMatch = (term) => {
      return (term.length > 1 && term[0] === term[term.length - 1] && (term[0] === '"')) ||
        term.trim().search(/[\s,-]/) !== -1;
    }

    let containsAll = operatorDef?.contains && operatorDef?.value?.endsWith('_ALL');
    let termsFunctor = isTerms ? (
      containsAll ? constants.query.termFunctors.and : constants.query.termFunctors.or
    ) : null;

    let value = values['value'];
    if (operatorDef) {
      if (operatorDef?.contains) {
        if (!filterDef.staticOptions) {
          value = utils.toArray(value).map((v) => isExactMatch(v) ? v : `*${v}*`);
        }
      } else if (operatorDef.isBetween) {
        value = `${values['valueFrom']}:${values['valueTo']}`;
      } else if (operatorDef.value === constants.query.filterOperators.gt) {
        value = `${utils.toInt(value) + 1}:${constants.numbers.maxInt}`;
      } else if (operatorDef.value === constants.query.filterOperators.gte) {
        value = `${value}:${constants.numbers.maxInt}`;
      } else if (operatorDef.value === constants.query.filterOperators.lt) {
        value = `${1}:${utils.toInt(value) - 1}`;
      } else if (operatorDef.value === constants.query.filterOperators.lte) {
        value = `${1}:${value}`;
      } else if (operatorDef.isEmpty) {
        value = '0';
      }
    }

    if (operatorDef?.isNot) {
      if (isTerms) {
        termsFunctor = termsFunctor === constants.query.termFunctors.and ? constants.query.termFunctors.andNot :
          constants.query.termFunctors.orNot;
      } else {
        value = utils.toArray(value).map((v) => `-${v}`);
      }
    }

    let filter = (isTerms || isWizard || isExamples) ? {} : [{id: filterDef.value, value: value}];
    if (!isTerms && !isWizard && !isExamples) {
      if (operatorDef?.contains) {
        if (containsAll) {
          filter = filter.concat([{id: 'switch', value: operatorDef?.isNot ? `!${filterDef.value}` : filterDef.value}]);
        }
      }
    }

    const newPart = {
      id: part?.id,
      queryType: internalState.type,
      wizard: isWizard ? values['value'] : null,
      examples: isExamples ? values['value'] : null,
      termsFunctor: termsFunctor,
      terms: isTerms ? utils.toArray(values['value']).map((v, idx) => ({
        id: utils.sha1(`${part?.id}_${idx}_${v}`),
        term: v
      })) : null,
      filterOperator: (isTerms || isWizard || isExamples) ? null : values['operator'],
      filter: utils.filter2Object(filter)
    }

    utils.asPromise(onChange)(newPart)
      .finally(() => {
        actions?.setSubmitting(false);
      });
  }

  const handleValidating = (validating, dirty, errors) => {
    onError?.(errors);
  }

  const handleChange = (field) => {
    if (!['filter', 'type'].includes(field.name)) {
      formRef.current?.submit(false);
    }
  }

  const handleError = (field, value) => {
    doSubmit({...internalState, [field.name]: value});
  }

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    if (field) {
      if (field.name === 'type') {
        setInternalState((current) => {
          if (current.type !== value.value) {
            onSwitch?.(part.id, value.value);
            return {
              ...current,
              type: value.value
            }
          } else {
            return current;
          }
        });
      } else if (field.name === 'filter') {
        setInternalState((current) => {
          if (current.filter !== value.value) {
            utils.retry(() => {
              formRef.current?.validate(true);
            }, 3);

            return {
              ...current,
              filter: value.value,
              value: null,
              valueFrom: null,
              valueTo: null,
              operator: null
            }
          } else {
            return current;
          }
        });
      }
    }
  }

  const handleSubmit = (values, actions) => {
    doSubmit(values, actions);
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledAdvancedQueryFilter ref={innerRef} {...innerProps}>
    <InlineForm ref={formRef}
                key={internalState.filter}
                className="AdvancedQueryFilter-form"
                fields={fields}
                fieldData={fieldData}
                onValidating={handleValidating}
                onChange={handleChange}
                onError={handleError}
                onChangeDirect={handleChangeDirect}
                onSubmit={handleSubmit}/>
    <ActionIconButton className="AdvancedQueryFilter-delete" action={deleteAction} />
  </StyledAdvancedQueryFilter>
});

AdvancedQueryFilter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  part: PropTypes.object,
  first: PropTypes.bool,
  size: PropTypes.string,
  parentType: PropTypes.string,
  canDelete: PropTypes.bool,
  canUpdate: PropTypes.bool,
  filterGroups: PropTypes.array,
  required: PropTypes.bool,
  error: PropTypes.bool,
  fieldData: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
  onSwitch: PropTypes.func
};

AdvancedQueryFilter.defaultProps = {
};

export default AdvancedQueryFilter;
