import logger from 'helpers/logger';
import validation from 'helpers/validation';
import {Dot} from 'assets/icons';
import constants from 'helpers/constants';
import utilsBasic from 'helpers/utils.basic';

function filterCount (filter, unique = true, system = false) {
  const activeIds = utilsBasic.toArray(filter)
    .filter((f) => {
      return !utilsBasic.isEmpty(f.value) && (system || !utilsBasic.isDefined(constants.filters.system[f.id]))
    }).map((f) => f.id);
  return unique ? utilsBasic.uniqueArray(activeIds).length : activeIds.length;
}

function bytesToFileSize (bytes) {
  if (bytes < constants.numbers.KB) {
    return `${bytes}B`;
  } else if (bytes < constants.numbers.MB) {
    const kb = Math.round(bytes / constants.numbers.KB * 100) / 100.0;
    return `${kb}KB`;
  } else if (bytes < constants.numbers.GB) {
    const mb = Math.round(bytes / constants.numbers.MB * 100) / 100.0;
    return `${mb}MB`;
  } else {
    const gb = Math.round(bytes / constants.numbers.GB * 100) / 100.0;
    return `${gb}GB`;
  }
}

function numberToLabel (n, decimals = 0) {
  if (+n < 1000) {
    return `${n}`;
  } else if (+n < 1000000) {
    const k = (+n / 1000).toFixed(decimals);
    return `${k}k`;
  } else if (+n < 1000000000) {
    const m = (+n / 1000000).toFixed(decimals);
    return `${m}m`;
  } else {
    const b = (+n / 1000000000).toFixed(decimals);
    return `${b}b`;
  }
}

function deprecatedColor (color) {
  if (utilsBasic.isColor(color, true)) {
    return color;
  } else {
    return constants.color.deprecated.graph[color?.replace('graph-', '')] ??
      constants.color.deprecated.color[color] ?? constants.color.deprecated.limit[color];
  }
}

function validators2FieldValidation (validators) {
  if (validators) {
    validators = utilsBasic.isArray(validators) ? validators : validators.split(',').map((o) => o.trim());
    let res = validators.reduce((a, validator) => {
      let converted;
      const match = validator.match(/([^0-9]+)([0-9]+)/);
      if (match) {
        converted = constants.formFieldValidationTypes.validatorMap[match[1]] + `(${match[2]})`;
      } else {
        converted = constants.formFieldValidationTypes.validatorMap[validator];
      }
      if (converted) {
        a.push(converted);
      }

      return a;
    }, []);

    const hasList = res.find((v) => v === constants.formFieldValidationTypes.list);
    if (hasList && res.length > 1) {
      res = [`${constants.formFieldValidationTypes.list}(${res
        .filter((v) => v !== constants.formFieldValidationTypes.list).join(',')}`];
    }

    return res;
  }

  return null;
}

function validators2FieldRequired (validators) {
  if (validators) {
    let required = false;
    utilsBasic.toArray(validators).forEach((validator) => {
      required = required || constants.formFieldValidationTypes.validatorRequired(validator);
    });

    return required;
  }
  return false;
}

function dataType2Renderer (dataType) {
  if (dataType) {
    return constants.fieldRenderers.dataTypeMap[utilsBasic.camelcase(dataType)];
  }

  return null;
}

function name2Renderer (name, entity) {
  if (name) {
    return constants.fieldRenderers.nameMap[entity]?.[utilsBasic.camelcase(name)];
  }

  return null;
}

function renderer2FieldType (renderer) {
  if (renderer) {
    return constants.formFieldTypes.rendererMap[utilsBasic.camelcase(renderer)];
  }

  return null;
}

function renderer2FieldFormat (renderer) {
  if (renderer) {
    return constants.formFieldFormatTypes.rendererMap[utilsBasic.camelcase(renderer)];
  }

  return null;
}

function renderer2FieldValidation (renderer) {
  if (renderer) {
    return constants.formFieldValidationTypes.rendererMap[utilsBasic.camelcase(renderer)];
  }

  return null;
}

function renderer2FieldConversion (renderer) {
  if (renderer) {
    return constants.formFieldConversionTypes.rendererMap[utilsBasic.camelcase(renderer)];
  }

  return null;
}

function renderer2FieldOptions (renderer) {
  if (renderer) {
    return constants.data.rendererMap[utilsBasic.camelcase(renderer)];
  }

  return null;
}


function field2FieldMin (field) {
  if (field.validation) {
    let min = null;
    utilsBasic.toArray(field.validation).forEach((validator) => {
      const split = validator.split('(');
      if (split.length > 1 && split[0] === constants.formFieldValidationTypes.min) {
        const n = utilsBasic.toNumber(split[1].replace(/[()]/g, ''));
        min = utilsBasic.isDefined(min) ? Math.max(min, n) : n;
      }
    });

    return min;
  }
  return null;
}

function field2FieldMax (field) {
  if (field.validation) {
    let max = null;
    utilsBasic.toArray(field.validation).forEach((validator) => {
      const split = validator.split('(');
      if (split.length > 1 && split[0] === constants.formFieldValidationTypes.max) {
        const n = utilsBasic.toNumber(split[1].replace(/[()]/g, ''));
        max = utilsBasic.isDefined(max) ? Math.min(max, n) : n;
      }
    });

    return max;
  }
  return null;
}

function field2NotInTheFuture (field) {
  if (field.validation) {
    let notInFuture = false;
    utilsBasic.toArray(field.validation).forEach((validator) => {
      const split = validator.split('(');
      if (split[0] === constants.formFieldValidationTypes.notInTheFuture) {
        notInFuture = true;
      }
    });

    return notInFuture;
  }
  return null;
}

function field2NotInThePast (field) {
  if (field.validation) {
    let notInFuture = false;
    utilsBasic.toArray(field.validation).forEach((validator) => {
      const split = validator.split('(');
      if (split[0] === constants.formFieldValidationTypes.notInThePast) {
        notInFuture = true;
      }
    });

    return notInFuture;
  }
  return null;
}

function fields2FormFields (fields, fieldData) {
  return fields.map((field) => {
    if (!field.section) {
      if (+field.tagGroupId) {
        const tg = fieldData?.tagGroups?.find((tg) => +tg.groupId === +field.tagGroupId);

        if (tg) {
          const fieldType = field.type ?? constants.formFieldTypes.list;
          field = {
            ...field,
            label: field.label ?? tg.name,
            inlineLabel: field.inlineLabel ?? tg.name.toLowerCase(),
            type: fieldType,
            options: field.options ?? tg.tags.map((t) => {
              const label = (field.map && field.map[t.value]) ? field.map[t.value] :
                (field.capitalize ? utilsBasic.upperFirst(t.value) : t.value);

              return {
                label: label,
                value: t.tagId,
                icon: Dot
              };
            }),
            validation: field.validation ?? (tg.multiselect ? 'list(int)' : 'int'),
            conversion: field.conversion ?? constants.formFieldConversionTypes.tag,
            FormFieldProps: utilsBasic.mergeObjects({
              size: 'smaller',
              multiple: tg.multiselect,
              hiddenIcons: false,
              readOnlyChip: true,
              deselect: fieldType === constants.formFieldTypes.list ? true : null,
              ChipProps: {
                size: 'small',
                variant: 'outlined',
                color: tg.color || 'secondary'
              }
            }, field.FormFieldProps)
          };
        }
      } else {
        const cf = field.entity === 'entity' ? fieldData?.customFields?.find?.((cf) => cf.name === field.name) : null;

        const name = cf?.name ?? field.name;
        const label = cf?.label ?? field.label;
        const description = cf?.tooltip ?? field.description;
        const inlineLabel = cf?.inlineLabel ?? field.inlineLabel ?? cf?.label?.toLowerCase?.() ?? field.label?.toLowerCase?.();

        const dataType = (cf?.dataType === 'url' ? cf?.dataType : null) ?? cf?.type ?? field.dataType;
        const renderer = (cf?.renderer !== 'default' ? cf?.renderer : null) ?? field.renderer ??
          name2Renderer(name, field.entity) ?? dataType2Renderer(dataType);
        const monetary = cf?.monetary ?? field.monetary ?? (
          renderer === constants.fieldRenderers.monetary ||
          name2Renderer(name, field.entity) === constants.fieldRenderers.monetary
        );

        const validators = cf?.validators ?? field.validators;

        field = {
          ...field,
          label: field.label ?? label ?? utilsBasic.upperFirst(name),
          inlineLabel: field.inlineLabel ?? inlineLabel,
          description: field.description ?? description,
          type: field.type ?? renderer2FieldType(renderer),
          format: field.format ?? renderer2FieldFormat(renderer),
          conversion: field.conversion ?? renderer2FieldConversion(renderer),
          options: field.options ?? renderer2FieldOptions(renderer),
          validation: field.validation ?? validators2FieldValidation(validators) ?? renderer2FieldValidation(renderer) ??
            constants.formFieldValidationTypes.text,
          monetary: monetary,
          required: field.required ?? validators2FieldRequired(validators)
        };
      }

      if (field.context) {
        if (utilsBasic.isString(field.context) && field.context.length > 0) {
          field.context = atob(field.context);
        }
      }

      if (field.link) {
        field.placeholder = field.placeholder ?? `${field.link?.label} url`;
        field.prefix = field.prefix ?? {
          link: (l) => {
            return utilsBasic.cleanExternalLink(utilsBasic.url(l?.label ?? l)?.href);
          },
          icon: field.link?.icon
        };

        if (field.type === constants.formFieldTypes.suggestion) {
          field.FormFieldProps = utilsBasic.mergeObjects({
            freeSolo: true,
            hideEmpty: true,
            preload: true,
            loadOnce: true,
            hideOpenClose: false,
            hiddenIcons: false,
            SuggestionCardProps: {
              icon: field.link.icon
            }
          }, field.FormFieldProps);
        }
      }

      if (field.monetary) {
        field.prefix = field.prefix ?? fieldData?.currency?.symbol;
        field.prefixOptions = field.prefixOptions ?? (field.options !== 'currencyConversions');

        // client doesn't have currency
        if (field.options === 'currencyConversions' && !fieldData?.currency?.symbol) {
          field.options = null;
        }

        field.FormFieldProps = utilsBasic.mergeObjects({
          freeSolo: true,
          loadEmpty: false,
          hideEmpty: true,
          autoComplete: false
        }, field.FormFieldProps);
      }

      if (utilsBasic.isDefined(field.options) && utilsBasic.isString(field.options)) {
        const split = field.options.split('(');
        const options = split[0];

        const fieldOptions = constants.data[options] ?? fieldData?.callbacks?.[options];

        if (!utilsBasic.isDefined(fieldOptions)) {
          logger.trace('Options not found', field)
        }
        field.options = fieldOptions;
      }

      if (field.options) {
        const fieldOptions = field.options;
        if (utilsBasic.isFunction(fieldOptions)) {
          const key = `${field.name}_${Date.now()}`;
          field.options = ({search, ids, filter, callback}) => {
            fieldOptions({
              key,
              search,
              ids: !field.filter ? ids :
                utilsBasic.toArray(ids, true).concat(utilsBasic.toArray(field.filter, true)),
              filter,
              context: field.context,
              callback: (results) => {
                callback(field.filter ? utilsBasic.filterOptions(results, field.filter) : results);
              }
            })
          }
        } else {
          field.options = field.filter ? utilsBasic.filterOptions(fieldOptions, field.filter) : fieldOptions;
        }
      }

      if (field.monetary && field.prefixOptions && fieldData?.currency?.symbol && field.options) {
        const fieldOptions = field.options;

        const prefixOptions = (options) => {
          return options.map((opt) => ({
            ...opt,
            label: `${fieldData?.currency?.symbol} ${opt.label}`
          }));
        }

        if (utilsBasic.isFunction(fieldOptions)) {
          field.options = ({search, ids, filter, callback}) => {
            fieldOptions({
              search,
              ids,
              filter,
              callback: (results) => {
                callback(prefixOptions(results));
              }
            })
          }
        } else {
          field.options = prefixOptions(fieldOptions);
        }
      }

      if (field.defaultOptions && field.options) {
        const fieldOptions = field.options;
        if (utilsBasic.isFunction(fieldOptions)) {
          field.options = ({search, ids, filter, callback}) => {
            fieldOptions({
              search,
              ids: utilsBasic.toArray(ids, true).concat(utilsBasic.toArray(field.filter, true)),
              filter,
              callback: (results) => {
                if (utilsBasic.isEmpty(search) && utilsBasic.isEmpty(ids) && utilsBasic.isEmpty(filter)) {
                  callback([...field.defaultOptions, ...results]);
                } else {
                  callback(results);
                }
              }
            })
          }
        } else {
          field.options = [...field.defaultOptions, ...fieldOptions];
        }
      }

      if (field.readOnly) {
        field.validation = null;
      }
    }

    return field;
  }).filter((_) => (_));
}

function initializeFormFields (fields, data) {
  return fields.reduce((a, field) => {
    const name = (field.customField?.name ?? field.name);
    const nameValue = name ? (utilsBasic.objectProp(data, field.path ?? '') ?? data)?.[utilsBasic.camelcase(name)] : null;

    const tagGroupId = (field.tagGroup?.groupId || field.tagGroupId);

    if (tagGroupId) {
      const selectedTags = data.tagGroups ? (data.tagGroups?.reduce((a, tg) => {
        if (+tg.groupId === +tagGroupId) {
          a = a.concat(tg.tags);
        }

        return a;
      }, [])) : nameValue;

      const initial = field.initial ?? (field.tagGroup?.multiselect ? selectedTags : selectedTags?.[0]);
      a.push({
        ...field,
        initial
      });
    } else if (field.question) {
      const numeric = Boolean(data?.questionnaireAnswers?.[field.question.questionId]?.numeric);
      const initial = field.initial ?? data?.questionnaireAnswers?.[field.question.questionId]?.value;
      a.push({
        ...field,
        initial: (numeric && utilsBasic.isNumber(initial)) ? utilsBasic.toNumber(initial).toFixed(2) : initial
      });
    } else if (name) {
      const initial = field.initial ?? nameValue;
      a.push({
        ...field,
        initial
      });
    } else {
      a.push(field);
    }
    return a;
  }, []);
}

function changedFormFields (original, changes, skipEmpty = true) {
  return Object.keys(changes).reduce((o, k) => {
    const originalValue = original[utilsBasic.camelcase(k)];
    const changedValue = changes[k];

    if (!skipEmpty || !(utilsBasic.isEmpty(changedValue) && utilsBasic.isEmpty(originalValue))) {
      let changed;
      if (utilsBasic.isDate(changedValue) || utilsBasic.isDate(originalValue)) {
        if (utilsBasic.isDate(new Date(changedValue)) && utilsBasic.isDate(new Date(originalValue))) {
          changed = (new Date(changedValue)).toISOString() !== (new Date(originalValue)).toISOString();
        } else {
          changed = true;
        }
      } else {
        changed = !utilsBasic.compare(changedValue, originalValue);
      }

      if (changed) {
        o[k] = changedValue;
      }
    }
    return o;
  }, {})
}

function formFieldValue2Value (value) {
  // autocomplete transformation see also conversion
  if (utilsBasic.isObject(value) && value.hasOwnProperty('value')) {
    return value.value;
  } else {
    return value;
  }
}

function fieldValue2ConvertedValue (field, value, reverse = false) {
  if (field.conversion) {
    let res;
    switch (field.conversion) {
      case constants.formFieldConversionTypes.none:
        res = value;
        break;
      case constants.formFieldConversionTypes.invert:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            return !utilsBasic.isEmpty(v) ? !v : v;
          } else {
            return !utilsBasic.isEmpty(v) ? !v : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.int:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            return !utilsBasic.isEmpty(v) ? v.toString() : v;
          } else {
            return !utilsBasic.isEmpty(v) ? Math.round(+v) : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.number:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            return !utilsBasic.isEmpty(v) ? v.toString() : v;
          } else {
            return !utilsBasic.isEmpty(v) ? +v : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.text:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            return utilsBasic.isObject(v) ? v.value : v;
          } else {
            return v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.date:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            return utilsBasic.isDate(v) ? v.toISOString() : v;
          } else {
            v = formFieldValue2Value(v);
            return !utilsBasic.isEmpty(v) ? (
              !utilsBasic.isDate(v) ? new Date(v) :
                new Date(v.getTime() - v.getTimezoneOffset() * 60 * 1000)
            ) : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.year:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            if (utilsBasic.isDate(v)) {
              return utilsBasic.toNumber(v.toISOString().slice(0, 4));
            } else {
              return !utilsBasic.isEmpty(v) ? utilsBasic.toNumber(v.toString().slice(0, 4)) : v;
            }
          } else {
            v = formFieldValue2Value(v);
            if (!utilsBasic.isEmpty(v)) {
              return utilsBasic.isDate(v) ? v : new Date(`${utilsBasic.fillYear(v)}-01-01T00:00:00.000Z`);
            } else {
              return v;
            }
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.location:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            if (utilsBasic.isObject(v)) {
              return v.country;
            } else {
              return v;
            }
          } else {
            v = formFieldValue2Value(v);
            return !utilsBasic.isEmpty(v) ? {country: v} : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.tag:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            if (utilsBasic.isObject(v)) {
              return {...v, value: utilsBasic.isDefined(v.tagId) ? +v.tagId : null, label: v.value?.toString().toLowerCase()};
            } else {
              return v;
            }
          } else {
            return !utilsBasic.isEmpty(v) ? (utilsBasic.isObject(v) ? {
                ...utilsBasic.cleanObject(v, true, false),
                tagId: utilsBasic.isDefined(v.value) ? +v.value : null,
                value: v.label?.toLowerCase()
              } : (utilsBasic.isString(v) ? {value: v?.toLowerCase()} : {tagId: v})) : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.collection:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            if (utilsBasic.isObject(v)) {
              return {...v, value: +v.collectionId, label: v.name};
            } else {
              return v;
            }
          } else {
            return !utilsBasic.isEmpty(v) ? (utilsBasic.isObject(v) ? {
                ...utilsBasic.cleanObject(v, true, false),
                collectionId: utilsBasic.isDefined(v.value) ? +v.value : null,
                name: v.label
              } : (utilsBasic.isString(v) ? {value: v} : {collectionId: v})) : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.labelObject:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            if (utilsBasic.isObject(v)) {
              return {...v, value: +v.labelId, label: v.label?.toLowerCase()};
            } else {
              return v;
            }
          } else {
            return !utilsBasic.isEmpty(v) ? (utilsBasic.isObject(v) ? {
                ...utilsBasic.cleanObject(v, true, false),
                labelId: utilsBasic.isDefined(v.value) ? +v.value : null,
                label: v.label?.toLowerCase()
              } : (utilsBasic.isString(v) ? {label: v?.toLowerCase()} : {labelId: v})) : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.author:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            if (utilsBasic.isObject(v)) {
              return v?.type === constants.user.types.agent ? 'System' :
                utilsBasic.personName(v.firstName, v.lastName);
            } else {
              return v;
            }
          } else {
            return !utilsBasic.isEmpty(v) ? (utilsBasic.isObject(v) ? {
              ...utilsBasic.cleanObject(v, true, false),
              userId: utilsBasic.isDefined(v.value) ? +v.value : null,
              label: v.label?.toLowerCase()
            } : (utilsBasic.isString(v) ? {label: v?.toLowerCase()} : {userId: v})) : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.value:
      case constants.formFieldConversionTypes.employees:
      case constants.formFieldConversionTypes.entityType:
      case constants.formFieldConversionTypes.businessModel:
      case constants.formFieldConversionTypes.fundingStatus:
      case constants.formFieldConversionTypes.fundingRound:
      case constants.formFieldConversionTypes.currency:
      case constants.formFieldConversionTypes.user:
      case constants.formFieldConversionTypes.plan:
      case constants.formFieldConversionTypes.component:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            return v;
          } else {
            return utilsBasic.isObject(v) ? v.value : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      case constants.formFieldConversionTypes.label:
      case constants.formFieldConversionTypes.industry:
      case constants.formFieldConversionTypes.link:
        res = utilsBasic.toArray(value).map((v) => {
          if (reverse) {
            return v;
          } else {
            return utilsBasic.isObject(v) ? (v.labelValue ?? v.label) : v;
          }
        });
        res = utilsBasic.isArray(value) ? res : res[0];
        break;
      default:
        logger.warn(`Field conversion not found for: ${field.name} - ${field.conversion}`);
        res = value;
    }

    return res;
  } else {
    return value;
  }
}

function fieldValue2FormattedValue (format, value, isClean = false, remove = false) {
  let clean;

  if (utilsBasic.isDefined(value)) {
    switch (format) {
      case constants.formFieldFormatTypes.percentageInt:
      case constants.formFieldFormatTypes.int:
        // drop everything after the decimal separator
        clean = isClean ? value : value.toString().replace(new RegExp(`[^-0-9\\${utilsBasic.decimalSeparator()}]`, 'g'), '')
          .replace(new RegExp('\\' + utilsBasic.decimalSeparator() + '.*', 'g'), '');

        if (remove) {
          return clean;
        } else {
          const formatted = utilsBasic.formatNumber(clean);

          if (format === constants.formFieldFormatTypes.percentageInt) {
            return formatted + '%';
          } else {
            return formatted
          }
        }
      case constants.formFieldFormatTypes.percentageNumber:
      case constants.formFieldFormatTypes.number:
        // replace decimal separator with '.'
        clean = isClean ? value : value.toString().replace(new RegExp(`[^-0-9\\${utilsBasic.decimalSeparator()}]`, 'g'), '')
          .replace(new RegExp('\\' + utilsBasic.decimalSeparator(), 'g'), '.');

        if (remove) {
          return clean;
        } else {
          const formatted = utilsBasic.formatNumber(clean);

          if (format === constants.formFieldFormatTypes.percentageNumber) {
            return formatted + '%';
          } else {
            return formatted
          }
        }
      case constants.formFieldFormatTypes.lower:
        return value ? value.toLowerCase() : value;
      case constants.formFieldFormatTypes.upper:
        return value ? value.toUpperCase() : value;
      default:
        return value;
    }
  } else {
    return value;
  }
}

function fields2ValidationSchema (fields) {
  const validationSchema = {};

  const transform = (type, current, previous) => {
    if (!utilsBasic.isArray(previous)) {
      if ([
        constants.formFieldValidationTypes.date
      ].includes(type)) {
        if (!utilsBasic.isDate(current) && utilsBasic.isNumber(previous)) {
          return new Date(`${utilsBasic.fillYear(previous)}-01-01T00:00:00.000Z`);
        }
      }
    }

    return current;
  }

  const wrapObjects = (type, current) => {
    return validation
      .lazy((value) => {
        if (![
          constants.formFieldValidationTypes.tag,
          constants.formFieldValidationTypes.label,
          constants.formFieldValidationTypes.collection,
          constants.formFieldValidationTypes.status,
          constants.formFieldValidationTypes.field,
          constants.formFieldValidationTypes.team,
          constants.formFieldValidationTypes.listObject,
          constants.formFieldValidationTypes.fieldMapping,
          constants.formFieldValidationTypes.teamMapping,
          constants.formFieldValidationTypes.listMapping,
          constants.formFieldValidationTypes.query,
          constants.formFieldValidationTypes.mapping,
          constants.formFieldValidationTypes.component
        ].includes(type)) {
          if (utilsBasic.isObject(value)) {
            if (value.hasOwnProperty('value')) {
              return validation.object({
                value: current
              })
            }
          }
        }

        return current;
      });
  }

  const addValidation = (current, field, type) => {
    const split = type.split('(');
    let options = split.length > 1 ? split[1].replace(')', '') : null;
    if (split.length > 2) {
      options = options + '(' + split.slice(2).join('(').slice(0, -1);
    }
    type = split[0];

    switch (type) {
      case constants.formFieldValidationTypes.text:
        current = current
          .string(`Enter ${field.inlineLabel ?? field.label}`);
        break;
      case constants.formFieldValidationTypes.int:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.number);
        }
        current = current.integer(`Enter a valid integer`);
        break;
      case constants.formFieldValidationTypes.number:
        current = current.number()
          .typeError(`Enter a valid number`)
          .nullable()
          .transform((_, val) => (val !== "" ? utilsBasic.toNumber(val) : null));
        break;
      case constants.formFieldValidationTypes.positive:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.number);
        }
        current = current
          .positive(`Enter a positive number`);
        break;
      case constants.formFieldValidationTypes.negative:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.number);
        }
        current = current
          .negative(`Enter a negative number`);
        break;
      case constants.formFieldValidationTypes.nonNegative:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.number);
        }
        current = current
          .positive(`Enter a positive number or zero`)
          .min(0, `Enter a positive number or zero`);
        break;
      case constants.formFieldValidationTypes.url:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        current = current.url('Enter a valid url / website')
          .hostname('Enter a valid url / website');
        break;
      case constants.formFieldValidationTypes.email:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        current = current.email(`Enter a valid email address`);
        break;
      case constants.formFieldValidationTypes.username:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        current = current.username(`Enter a valid username`);
        break;
      case constants.formFieldValidationTypes.phone:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        current = current.phone(`Enter a valid phone number`);
        break;
      case constants.formFieldValidationTypes.location:
      case constants.formFieldValidationTypes.country:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        current = current.country(`Enter a valid country`);
        break;
      case constants.formFieldValidationTypes.date:
        current = current
          .date('Enter a valid date')
          .typeError(`Enter a valid date`);
        break;
      case constants.formFieldValidationTypes.notInTheFuture:
        if (!current.type) {
          current = current
            .date(`Enter a valid date`)
            .typeError(`Enter a valid date`);
        }
        current = current
          .max(new Date(), "Enter a date not in the future")
        break;
      case constants.formFieldValidationTypes.notInThePast:
        if (!current.type) {
          current = current
            .date(`Enter a valid date`);
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        current = current
          .min(yesterday, "Enter a date not in the past")
        break;
      case constants.formFieldValidationTypes.year:
        if (!current.type) {
          current = current.mixed();
        }
        current = current.year(`Enter a valid year`);
        break;
      case constants.formFieldValidationTypes.password:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        current = current
          .min(8, 'Password should be of minimum 8 characters length')
          .matches(/[0-9]/, 'Password must contain at least one number')
          .matches(/[a-z]/, 'Password must contain at least one lowercase character')
          .matches(/[A-Z]/, 'Password must contain at least one uppercase character');
        break;
      case constants.formFieldValidationTypes.file:
        current = current.mixed()
          .file(`Enter a valid file of type (${field.FormFieldProps?.types?.join(', ')})`,
            field.FormFieldProps?.types)
          .fileSize(`Enter a file${field.FormFieldProps.multiple ? 's' : ''} between \${minSize} and \${maxSize}`,
            field.FormFieldProps?.minSize, field.FormFieldProps?.maxSize);
        break;
      case constants.formFieldValidationTypes.tag:
        current = current.mixed()
          .tag('Enter a valid tag');
        break;
      case constants.formFieldValidationTypes.status:
        current = current.mixed()
          .status('Enter a valid status');
        break;
      case constants.formFieldValidationTypes.field:
        current = current.mixed()
          .field('Enter a valid field');
        break;
      case constants.formFieldValidationTypes.team:
        current = current.mixed()
          .team('Enter a valid team');
        break;
      case constants.formFieldValidationTypes.label:
        current = current.mixed()
          .label('Enter a valid label');
        break;
      case constants.formFieldValidationTypes.entity:
        current = current.mixed()
          .collection('Enter a valid company');
        break;
      case constants.formFieldValidationTypes.collection:
        current = current.mixed()
          .collection('Enter a valid collection');
        break;
      case constants.formFieldValidationTypes.dealLeader:
        current = current.mixed()
          .dealLeader('Enter a valid deal leader');
        break;
      case constants.formFieldValidationTypes.dealflowStatus:
        current = current.mixed()
          .dealflowStatus('Enter a valid deal flow status');
        break;
      case constants.formFieldValidationTypes.listObject:
        current = current.mixed()
          .listObject('Enter a valid list');
        break;
      case constants.formFieldValidationTypes.fieldMapping:
        current = current.mixed()
          .fieldMapping('Enter a valid field mapping');
        break;
      case constants.formFieldValidationTypes.listMapping:
        current = current.mixed()
          .listMapping('Enter a valid list mapping');
        break;
      case constants.formFieldValidationTypes.teamMapping:
        current = current.mixed()
          .listMapping('Enter a valid team mapping');
        break;
      case constants.formFieldValidationTypes.list:
      case constants.formFieldValidationTypes.unique:
        current = current.array();

        if (type === constants.formFieldValidationTypes.unique) {
          current = current.unique('Enter unique values', options?.split?.(',')?.[0]);
        } else {
          current = current.list('Enter a list of values', options?.split?.(',')?.[0]);
        }
        current = current.min(field.required ? 1 : 0, `${utilsBasic.upperFirst(field.inlineLabel ?? field.label)} is required`);

        if (options) {
          let opt = validation;
          options.split(',').map((v) => v.trim()).forEach((v) => {
            opt = addValidation(opt, field, v)
              .transform((current, previous) => transform(v, current, previous));
            opt = wrapObjects(v, opt);
          });
          current = current.of(opt);
        }
        break;
      case constants.formFieldValidationTypes.boolean:
        current = current
          .bool(`Enter ${field.inlineLabel ?? field.label}`);
        break;
      case constants.formFieldValidationTypes.truthy:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.boolean);
        }

        current = current.truthy(`${utilsBasic.upperFirst(field.inlineLabel ?? field.label)} is required`)
        break;
      case constants.formFieldValidationTypes.min:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        if (current.type === 'string') {
          current = current.min(+options, `Enter min ${+options} character${+options === 1 ? '' : 's'}`);
        } else {
          current = current.min(+options, `Enter a valid number >= ${+options}`);
        }
        break;
      case constants.formFieldValidationTypes.max:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        if (current.type === 'string') {
          current = current.max(+options, `Enter max ${+options} character${+options === 1 ? '' : 's'}`);
        } else {
          current = current.max(+options, `Enter a valid number <= ${+options}`);
        }
        break;
      case constants.formFieldValidationTypes.match:
        if (!current.type) {
          current = addValidation(current, field, constants.formFieldValidationTypes.text);
        }
        let source = fields.find((f) => f.name === options);
        current = current.oneOf([validation.ref(source?.name, {map: formFieldValue2Value}), null], `Value must be equal to "${source?.inlineLabel ?? source.label}"`);
        break;
      case constants.formFieldValidationTypes.component:
        if (options) {
          let opt = validation;
          options.split(',').map((v) => v.trim()).forEach((v) => {
            opt = addValidation(opt, field, v)
              .transform((current, previous) => transform(v, current, previous));
          });
          current = current.object({ value: opt });
        } else {
          current = current.object();
        }
        current = current.component(`${utilsBasic.upperFirst(field.inlineLabel ?? field.label)} is not valid`);
        break;
      case constants.formFieldValidationTypes.query:
        if (!current.type) {
          current = current.mixed();
        }
        if (field.required) {
          current = current.query(`${utilsBasic.upperFirst(field.inlineLabel ?? field.label)} is required`, true);
        } else {
          current = current.query(`Enter a valid query`, false);
        }
        break;
      case constants.formFieldValidationTypes.mapping:
        if (!current.type) {
          current = current.mixed();
        }
        if (field.required) {
          current = current.mapping(`${utilsBasic.upperFirst(field.inlineLabel ?? field.label)} is required`, true);
        } else {
          current = current.mapping(`Enter a valid mapping`, false);
        }
        break;
      default:
        logger.warn('Field validation not found for:', type, field);
        current = current.mixed();
    }

    return current;
  };

  fields?.forEach((field) => {
    const validations = utilsBasic.toArray(field.validation);

    validations.forEach((v) => {
      const current = validationSchema[field.name] ?? validation;
      validationSchema[field.name] = addValidation(current, field, v);
    })

    if (validationSchema[field.name]) {
      if (utilsBasic.isFunction(field.validate)) {
        validationSchema[field.name] = validationSchema[field.name]
          .test(
            utilsBasic.upperFirst(field.inlineLabel ?? field.label),
            `${utilsBasic.upperFirst(field.inlineLabel ?? field.label)} is invalid`,
            field.validate
          );
      }

      if (field.required) {
        validationSchema[field.name] = validationSchema[field.name]
          .required(`${utilsBasic.upperFirst(field.inlineLabel ?? field.label)} is required`);
      } else {
        validationSchema[field.name] = validationSchema[field.name].notRequired();
      }

      validationSchema[field.name] = validationSchema[field.name].nullable();

      validationSchema[field.name] = validationSchema[field.name]
        .transform((current, previous) => transform(validations?.[0], current, previous));

      validationSchema[field.name] = wrapObjects(validations?.[0]?.split?.('(')?.[0], validationSchema[field.name]);
    }
  });

  return validation.object(validationSchema);
}

function fields2InitialValues (fields) {
  const initialValues = {};
  fields.forEach((f) => {
    const value = fieldValue2ConvertedValue(f, f.initial, true);

    const hasList = utilsBasic.toArray(f.validation).find((v) => v.match(new RegExp(`^${constants.formFieldValidationTypes.list}([\\W]|$)`, 'i')));
    if (hasList) {
      initialValues[f.name] = utilsBasic.isDefined(value) ? utilsBasic.toArray(value) : [];
    } else {
      initialValues[f.name] = utilsBasic.isDefined(value) ? value : '';
    }
  });

  return initialValues;
}

function createEvent (type, options) {
  let e;

  switch (type) {
    case 'click':
      e = new Event(type, options);
      e.button = 0;
      return e;
    case 'keydown':
    case 'keyup':
    case 'keypress':
      return new KeyboardEvent(type, options);
    default:
      return new Event(type, options);
  }
}

function refireEvent (e, target = null) {
  const event = new Event(e.type, e);
  event.metaKey = e.metaKey;
  event.ctrlKey = e.ctrlKey;
  event.altKey = e.altKey;
  event.shiftKey = e.shiftKey;

  event.refire = true;

  (target ?? e.target)?.dispatchEvent(event);
}

function retry (fn, max = 10, startDelay = 0, stop = null, count = 0) {
  return new Promise((resolve) => {
    const doIt = () => {
      if (!stop?.(count + 1)) {
        const res = fn(count + 1);
        if (!res) {
          max -= 1
          if (max > 0) {
            setTimeout(() => {
              retry(fn, max, 0, stop, count)
                .then((r) => resolve(r));
            }, constants.debounce.minimal * Math.pow(2, count));
            count += 1;
          } else {
            resolve(null);
          }
        } else {
          resolve(res);
        }
      } else {
        resolve(null);
      }
    }

    if (startDelay) {
      setTimeout(() => {
        doIt();
      }, startDelay);
    } else {
      doIt();
    }
  });
}

function observeEvent (element, event, cb, {timeout, capture, passive, doubleFire, fireOnRegister} = {}) {
  const debouncedHandle = timeout ? utilsBasic.debounce(cb, timeout) : cb;
  const handleEvent = (e) => {
    if (timeout && doubleFire) {
      cb();
    }
    debouncedHandle(e)
  }

  if (fireOnRegister) {
    cb();
    if (timeout && doubleFire) {
      debouncedHandle();
    }
  }

  element.addEventListener(event, handleEvent, {
    capture,
    passive,
  });
  return () => element.removeEventListener(event, handleEvent);
}

function observeTimeout (cb, timeout) {
  const timer = setTimeout(cb, timeout);
  return () => clearTimeout(timer);
}

function observeInterval (cb, timeout) {
  const timer = setInterval(cb, timeout);
  return () => clearInterval(timer);
}

function observeRetry (cb, max = 10, startDelay = 0, count = 0) {
  let stop = false;

  retry(cb, max, startDelay, () => stop, count).then();

  return () => {
    stop = true;
  }
}

function observeResize (element, cb, {timeout, capture = false, passive = true, fireOnRegister = true} = {}) {
  const memoizedCallback = utilsBasic.memoizeCallback({width: -1, height: -1}, cb);
  const onResize = (e) =>
    memoizedCallback({
      width: element === window ? element.innerWidth :
        element.clientWidth,
      height: element === window ? element.innerHeight :
        element.clientHeight,
    }, e)

  const debouncedResize = timeout ? utilsBasic.debounce((e) => {
    onResize(e);
  }, timeout) : onResize;

  if (!element) {
    return
  }

  if (fireOnRegister) {
    onResize();
  }

  let intervalId;
  if (element !== window) {
    intervalId = setInterval(onResize, constants.delay.minimal);
  }

  window.addEventListener('resize', debouncedResize, {
    capture: capture,
    passive: passive,
  })

  return () => {
    window.removeEventListener('resize', debouncedResize);
    clearInterval(intervalId);
  }
}

function observeScroll (element, cb, {
  timeout = null, speed = null, direction = 'both',
  capture = false, passive = true, fireOnRegister = true
} = {}) {
  const memoizedCallback = utilsBasic.memoizeCallback(direction === 'both' ? {x: -1, y: -1} :
    (direction === 'horizontal' ? {x: -1} : {y: -1}), cb);

  const onScroll = (e) => {
    const target = element;
    const scrollX = target === window ? target.scrollX : target.scrollLeft;
    const scrollY = target === window ? target.scrollY : target.scrollTop;

    memoizedCallback(direction === 'both' ? {x: scrollX, y: scrollY} :
      (direction === 'horizontal' ? {x: scrollX} : {y: scrollY}), e);
  }

  const stop = (prev) => {
    if (!utilsBasic.isDefined(speed)) {
      return {wait: true, data: null};
    } else {
      const target = element;
      const scrollX = target === window ? target.scrollX : target.scrollLeft;
      const scrollY = target === window ? target.scrollY : target.scrollTop;

      let wait = true;
      if (utilsBasic.isDefined(prev?.time)) {
        const scrollXSpeed = Math.abs(scrollX - prev.scrollX) / (Date.now() - prev.time);
        const scrollYSpeed = Math.abs(scrollY - prev.scrollY) / (Date.now() - prev.time);

        const scrollSpeed = direction === 'both' ? Math.max(scrollXSpeed, scrollYSpeed) :
          (direction === 'horizontal' ? scrollXSpeed : scrollYSpeed);

        wait = scrollSpeed > speed;
      }
      return {wait, data: {scrollX, scrollY, time: Date.now()}};
    }
  }

  const debouncedScroll = timeout ? utilsBasic.debounce((e) => {
    onScroll(e);
  }, timeout, stop) : onScroll;

  if (!element) {
    return
  }

  if (fireOnRegister) {
    onScroll();
  }

  element.addEventListener('scroll', debouncedScroll, {
    capture: capture,
    passive: passive,
  })

  return () => element.removeEventListener('scroll', debouncedScroll)
}

function observeMouseMove (element, cb, {timeout, capture = false, passive = true} = {}) {
  const memoizedCallback = utilsBasic.memoizeCallback({x: -1, y: -1}, cb);
  const onMove = (e) => {
    const clientX = e?.clientX || -1;
    const clientY = e?.clientY || -1;

    memoizedCallback({x: clientX, y: clientY}, e);
  }

  const debouncedMove = timeout ? utilsBasic.debounce((e) => {
    onMove(e);
  }, timeout) : onMove;

  if (!element) {
    return
  }

  element.addEventListener('mousemove', debouncedMove, {
    capture: capture,
    passive: passive,
  })

  return () => element.removeEventListener('mousemove', debouncedMove)
}

function observePromise (promise, onStart, onSuccess, onError, onEnd,
                         {startDelay = constants.delay.waiting, endDelay = 0} = {}) {
  return new Promise((resolve, reject) => {
    let id;
    let timeout = setTimeout(() => {
      resolve();
      id = onStart?.();
    }, startDelay);

    promise
      .then((res) => {
        if (id) {
          onSuccess(id, res);
        } else {
          resolve(res);
        }
      })
      .catch((err) => {
        if (id) {
          onError(id, err);
        } else {
          reject(err);
        }
      })
      .finally(() => {
        clearTimeout(timeout);
        setTimeout(() => {
          onEnd?.(id)
        }, endDelay);
      });
  });
}

function timeAgo (date, maxDays = 30, format = constants.dates.format) {
  if (utilsBasic.isDate(date)) {
    if ((Date.now() - date.getTime()) > (maxDays * (24 * 60 * 60 * 1000))) {
      return utilsBasic.dayjs(date).format(format);
    } else {
      return utilsBasic.dayjs(date).fromNow();
    }
  } else {
    return date;
  }
}

function daysAgo (date, maxDays = 30, format = constants.dates.format) {
  if (utilsBasic.isDate(date)) {
    if ((Date.now() - date.getTime()) > (maxDays * (24 * 60 * 60 * 1000))) {
      return utilsBasic.dayjs(date).format(format);
    } else {
      const diff = utilsBasic.dayjs(new Date()).diff(utilsBasic.dayjs(date), 'day');
      if (diff === 0) {
        return 'Today';
      } else if (diff === 1) {
        return 'Yesterday';
      } else {
        return `${diff} days ago`;
      }
    }
  } else {
    return date;
  }
}

const utils = {
  filterCount,

  bytesToFileSize,
  numberToLabel,

  deprecatedColor,

  validators2FieldValidation,
  validators2FieldRequired,
  dataType2Renderer,
  name2Renderer,
  renderer2FieldType,
  renderer2FieldFormat,
  renderer2FieldValidation,
  renderer2FieldConversion,
  renderer2FieldOptions,

  field2FieldMin,
  field2FieldMax,
  field2NotInTheFuture,
  field2NotInThePast,

  fields2FormFields,
  initializeFormFields,
  changedFormFields,
  formFieldValue2Value,
  fields2ValidationSchema,
  fieldValue2ConvertedValue,
  fieldValue2FormattedValue,
  fields2InitialValues,

  createEvent,
  refireEvent,

  retry,

  observeEvent,
  observeTimeout,
  observeInterval,
  observeRetry,
  observeResize,
  observeScroll,
  observeMouseMove,
  observePromise,

  timeAgo,
  daysAgo
}

export default utils;
