import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Save from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import StyledCommentDialog from 'components/organisms/Dialogs/CommentDialog/CommentDialog.styles';
import {useClientCallbacks} from 'services/client/client.utils';

const CommentDialog = React.forwardRef((props, ref) => {
  const {
    text,
    onChange,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'CommentDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);
  const clientCallbacks = useClientCallbacks();

  const fields = useMemo(() => ([{
    name: 'comment',
    placeholder: 'Type your comment',
    type: constants.formFieldTypes.markdown,
    validation: constants.formFieldValidationTypes.text,
    initial: text,
    required: true,
    FormFieldProps: {
      autoFocus: true,
      hiddenLabel: true,
      variant: 'outlined',
      minRows: 2,
      maxRows: 12,
      mentions: [{
        trigger: '@',
        data: (search, callback) => clientCallbacks.members({search, callback})
      }],
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        disableUnderline: true
      }
    }
  }]), [clientCallbacks, text]);

  const handleChange = (field, value) => {
    onChange?.(value);
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values['comment'])
      .then(() => {
        innerProps.onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setError('Saving comment failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e);
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              children={'Save'}
              startIcon={<Icon icon={Save}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledCommentDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title="Comment" />
    <DialogContent>
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="CommentDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledCommentDialog>
});

CommentDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  text: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

CommentDialog.defaultProps = {};

export default CommentDialog;
