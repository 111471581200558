import React, {useCallback, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledUpdateForm from 'components/organisms/Forms/UpdateForm/UpdateForm.styles';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Send from '@mui/icons-material/Send';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import {useClientCallbacks} from 'services/client/client.utils';

const UpdateForm = React.forwardRef((props, ref) => {
  const {
    onSubmit,
    variant,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'UpdateForm');

  const fieldRef = useRef(null);
  const formRef = useRef(null);

  const simple = variant === 'simple';

  const [inputDisabled, setInputDisabled] = useState(true);

  const clientCallbacks = useClientCallbacks();

  const snackbar = useSnackbar();

  const handleSubmit = (values, actions) => {
    onSubmit?.(values['update'])
      .then(() => {
        setInputDisabled(true);
        actions.resetForm();
      })
      .catch(() => {
        setInputDisabled(false);
        snackbar.show('Saving comment failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleChangeDirect = (e) => {
    e.target.value?.length > 0 ? setInputDisabled(false) : setInputDisabled(true);
  };

  const handleFocus = () => {
    fieldRef.current?.focus();
  };

  const handleSubmitClick = useCallback(() => {
    formRef.current?.submit();
  }, [formRef]);

  const fields = useMemo(() => ([{
    name: 'update',
    placeholder: 'Type your message',
    type: constants.formFieldTypes.markdown,
    validation: constants.formFieldValidationTypes.text,
    initial: null,
    postfix: simple ? <ActionIconButton action={{
      icon: Send,
      IconButtonProps: {
        disabled: inputDisabled,
        isLoading: isLoading,
        disableFocusRipple: true
      },
      onClick: () => {
        handleSubmitClick();
      }
    }} /> : null,
    FormFieldProps: {
      ref: fieldRef,
      autoFocus: false,
      hiddenLabel: true,
      variant: simple ? 'outlined' : 'standard',
      size: 'small',
      minRows: 1,
      maxRows: 8,
      mentions: [{
        trigger: '@',
        data: (search, callback) => clientCallbacks.members({search, callback})
      }],
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        disableUnderline: true
      }
    }
  }]), [simple, inputDisabled, handleSubmitClick, isLoading, clientCallbacks]);

  const sendAction = useMemo(() => ({
    icon: <Icon icon={Send} size="smaller" />,
    tooltip: 'Send',
    onClick: () => {
      handleSubmitClick();
    },
    IconButtonProps: {
      disabled: inputDisabled,
      size: 'smaller',
      density: 'sparse',
      color: 'primary',
      variant: 'contained',
      isLoading: isLoading
    }
  }), [inputDisabled, handleSubmitClick, isLoading]);

  return <StyledUpdateForm ref={ref} {...innerProps} onClick={handleFocus}>
    <InlineForm ref={formRef}
                className="UpdateForm-form"
                onSubmit={handleSubmit}
                onChangeDirect={handleChangeDirect}
                fields={fields}
                isLoading={isLoading}/>
    {!simple ? <Box className="UpdateForm-icons">
      <ActionIconButton action={sendAction} />
    </Box> : null}
  </StyledUpdateForm>
});

UpdateForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  inputDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'simple']), PropTypes.string])
};

UpdateForm.defaultProps = {};

export default UpdateForm;
