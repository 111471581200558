import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledEntityFinanceHistoryProfileCardContent
  from 'components/organisms/Cards/EntityFinanceHistoryProfileCardContent/EntityFinanceHistoryProfileCardContent.styles';
import Tabs from 'components/atoms/Tabs/Tabs/Tabs';
import Tab from 'components/atoms/Tabs/Tab/Tab';
import TabPanel from 'components/atoms/Tabs/TabPanel/TabPanel';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Typography, {P} from 'components/atoms/Text/Typography/Typography';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import Delete from '@mui/icons-material/Delete';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import Add from '@mui/icons-material/Add';
import Icon from 'components/atoms/Icons/Icon/Icon';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Link from 'components/atoms/Links/Link/Link';

const historyFields = [
  { name: 'revenue', label: 'Revenue' },
  { name: 'turnover', label: 'Turnover' },
  { name: 'operatingIncome', label: 'Operating income' },
  { name: 'operatingResult', label: 'Operating result' },
  { name: 'netProfit', label: 'Net profit', validation: constants.formFieldValidationTypes.int},
  { name: 'ebitda', label: 'EBITDA' },
  { name: 'ebit', label: 'EBIT' },
  { name: 'totalAssets', label: 'Total assets' },
  { name: 'totalEquity', label: 'Total equity' },
  { name: 'totalDebt', label: 'Total debt' },
];

const EntityFinanceHistoryProfileCardContent = React.forwardRef((props, ref) => {
  const {
    profile,
    card,
    content,
    entity,
    fieldData,
    isDialog,
    onValidating,
    onSubmit,
    onPatch,
    ...innerProps
  } = useComponentProps(props, 'EntityFinanceHistoryProfileCardContent', {
    static: ['isEditing']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);
  const tabsRef = useRef(null);
  const [internalState, setInternalState] = useState({});
  const [activeTab, setActiveTab] = useState(null);

  const dialogControl = useDialogControl();
  const snackbar = useSnackbar();

  // ProfileCardContent handles form state
  // fake form when empty triggers validating and is submittable
  useImperativeHandle(ref, () => formRef.current);

  const isEditing = content.state.isEditing;

  const history = useMemo(() => {
    return (entity?.financeHistory ?? [])
      .map((h) => utils.camelcase(h))
      .map((h) => ({...h, initial: h}))
      .filter((h) => isEditing || historyFields.some((f) => utils.isDefined(h[f.name])))
      .sort((a, b) => +a.year - +b.year);
  }, [entity?.financeHistory, isEditing]);

  useEffect(() => {
    setInternalState({
      initial: utils.clone(history, true),
      history: utils.clone(history, true)
    });

    setActiveTab(history.length > 0 ? (history.length - 1) : 0);

    if (isEditing) {
      utils.retry(() => {
        if (tabsRef.current) {
          const el = tabsRef.current.querySelector('.MuiTabs-scroller');
          if (el) {
            el.scrollTo({left: el.scrollWidth - el.clientWidth});
          }
        }
      }, 5);
    }
  }, [history, isEditing]);

  const fields = useMemo(() => {
    const fields = [];

    const activeHistory = internalState.history?.[activeTab];

    if (activeHistory) {
      if (isEditing) {
        fields.push({
          name: 'year',
          label: 'Year',
          entity: 'entity',
          type: constants.formFieldTypes.text,
          validation: constants.formFieldValidationTypes.year,
          validate: (value, testContext) => {
            const duplicate = +value !== +activeHistory?.year && internalState.history
              .find((h, idx) => idx !== activeTab && +h.year === +value);

            if (duplicate) {
              return testContext.createError({message: `Enter a unique year, ${value} already exists`});
            } else {
              return true;
            }
          },
          initial: activeHistory.initial?.year,
          required: true,
          readOnly: !isEditing,
          debounce: content.state.canPatch ? constants.debounce.input : false,
          FormFieldProps: {
            autoFocus: false,
            hiddenPlaceholder: !isEditing,
            variant: isEditing ? 'staticLabel' : 'inlineLabel'
          }
        });
      }

      historyFields
        .filter((f) => isEditing || utils.isDefined(activeHistory[f.name]))
        .forEach((f) => {
          fields.push({
            name: f.name,
            label: f.label,
            entity: 'entity',
            renderer: constants.fieldRenderers.monetary,
            type: constants.formFieldTypes.monetary,
            validation: f.validation ?? [constants.formFieldValidationTypes.int, constants.formFieldValidationTypes.nonNegative],
            conversion: constants.formFieldConversionTypes.value,
            format: constants.formFieldFormatTypes.int,
            prefix: fieldData?.currency?.symbol,
            initial: activeHistory.initial?.[f.name] ?? '',
            readOnly: !isEditing,
            options: 'currencyConversions',
            description: '',
            debounce: content.state.canPatch ? constants.debounce.input : false,
            FormFieldProps: {
              autoFocus: false,
              hiddenPlaceholder: !isEditing,
              variant: isEditing ? 'staticLabel' : 'inlineLabel'
            }
          });
        });
    }

    return fields;
  }, [content.state.canPatch, internalState.history, fieldData?.currency?.symbol, activeTab, isEditing]);

  const cleanHistory = (history) => {
    if (history) {
      return history.map((h) => {
        return utils.underscoreEx(
          utils.cleanObject(
            utils.filterObject(h, ['initial']),
            false
          )
        );
      })
        .sort((a, b) => +b.year - +a.year);
    } else {
      return history;
    }
  }

  const handleSubmit = (values, actions) => {
    const name = 'finance_history';
    const fields = [{
      name: 'finance_history'
    }];

    let newHistory = utils.clone(internalState.history, true);
    if (newHistory[activeTab]) {
      newHistory[activeTab] = {...newHistory[activeTab], ...values};
      newHistory.sort((a, b) => +a.year - +b.year);
      setInternalState(utils.updater({history: newHistory}, true));
    } else {
      newHistory = null;
    }

    const changes = {
      [name]: cleanHistory(newHistory),
    };

    onSubmit?.(changes, actions, fields);
  };

  const canPatch = Boolean(content.state.isEditing && !isDialog && !profile.state.isEditing);
  const handleChange = (field, value, onSuccess, onError) => {
    const historyField = {
      name: 'finance_history'
    };

    const currentTab = activeTab;
    const oldHistory = internalState.history;
    const newHistory = utils.clone(internalState.history, true);
    newHistory[activeTab][field.name] = value;
    newHistory[activeTab].initial[field.name] = value;

    if (field.name === 'year') {
      newHistory.sort((a, b) => +a.year - +b.year);
      setActiveTab(newHistory.findIndex((h) => +h.year === +value));
    }
    setInternalState(utils.updater({history: newHistory}, true));

    if (canPatch) {
      return onPatch?.(historyField, cleanHistory(newHistory), onSuccess, (err) => {
        setInternalState(utils.updater({history: oldHistory}, true));
        setActiveTab(currentTab);
        onError?.(err);
      });
    }
  };

  const handleAddClick = () => {
    const historyField = {
      name: 'finance_history'
    };

    let openYear = (internalState.history ?? []).reverse().reduce((y, h) => +h.year === +y ? y - 1 : y, (new Date()).getFullYear() - 1);
    if (openYear === 0) {
      openYear = (internalState.history ?? []).reverse().reduce((y, h) => +h.year === +y ? y + 1 : y, (new Date()).getFullYear() - 1);
    }

    const oldHistory = internalState.history;
    const newHistory = [{
      year: openYear,
      initial: {
        year: openYear
      }
    }]
      .concat(internalState.history ?? [])
      .sort((a, b) => +a.year - +b.year);

    const currentTab = activeTab;
    setActiveTab(newHistory.findIndex((h) => +h.year === openYear));
    setInternalState(utils.updater({history: newHistory}, true));

    if (canPatch) {
      return onPatch?.(historyField, cleanHistory(newHistory), null, () => {
        setActiveTab(currentTab);
        setInternalState(utils.updater({history: oldHistory}, true));
        snackbar.show('Adding year failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});
      });
    }
  }

  const handleRemoveClick = (e) => {
    const handleConfirm = () => {
      const historyField = {
        name: 'finance_history'
      };
      const oldHistory = internalState.history;
      const newHistory = (internalState.history ?? []).filter((h, idx) => idx !== activeTab);

      const currentTab = activeTab;
      setActiveTab((current) => {
        return newHistory.length > current ? current : ((current === 0) ? null : current - 1);
      });
      setInternalState(utils.updater({history: newHistory}, true));

      if (canPatch) {
        return onPatch?.(historyField, cleanHistory(newHistory), null, () => {
          setActiveTab(currentTab);
          setInternalState(utils.updater({history: oldHistory}, true));
          snackbar.show('Removing year failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });
      }
    };

    if (canPatch) {
      dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this year?"
                                        explanation="The year wil be removed from this company's history"
                                        onConfirm={handleConfirm}
                                        ConfirmButtonProps={{
                                          children: 'Remove year',
                                          startIcon: <Icon icon={Delete} />,
                                          color: 'error'
                                        }}/>, true);
    } else {
      handleConfirm();
    }
    e.preventDefault();
  }

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  }

  const handleValidating = (validating, dirty, errors) => {
    const isDirty = !utils.compare(internalState.initial, internalState.history) || dirty;

    onValidating?.(validating, isDirty, errors);
  }

  const renderHistoryForm = (h) => {
    const source = entity?.availableSources?.used?.['finance_history'];
    const diskSource = constants.data.lookup('diskSourceTypes', source);

    return <React.Fragment>
      <InlineForm ref={formRef}
                  className="EntityFinanceHistoryProfileCardContent-form"
                  fields={fields}
                  fieldData={fieldData}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  onValidating={handleValidating}/>
      {(!isEditing && diskSource?.link) ?
        <Box className="EntityFinanceHistoryProfileCardContent-info">
          <Icon icon={InfoOutlined} color="primary" size="smaller"/>
          <Typography variant="caption" color="text.secondary">
            Finance history is based on <Link href={utils.cleanExternalLink(diskSource.link)}
                                                target="_blank">{diskSource.label}</Link> data
            {h.info ? <React.Fragment>&nbsp;for {h.info.name}{h.info.consolidated ? ' (consolidated)' : ''}</React.Fragment> : null}
          </Typography>
        </Box> : null}
    </React.Fragment>
  }

  const renderYearSelector = () => {
    if (isEditing) {
      return <Box className="EntityFinanceHistoryProfileCardContent-years">
        <Typography disabled={content.state.isSubmitting} variant="subtitle1">History</Typography>
        {internalState.history?.length > 0 ? <Tabs ref={tabsRef}
                                                   variant="scrollable"
                                                   density="dense"
                                                   size="small"
                                                   value={activeTab ?? 0}
                                                   onChange={handleTabChange}
                                                   disabled={content.state.isSubmitting}
                                                   className="EntityFinanceHistoryProfileCardContent-tabs">
          {internalState.history?.map((h, idx) => {
            return <Tab key={idx} value={idx} label={h.year}/>
          })}
        </Tabs> : null}
        <ActionIconButton variant="outlined"
                          density="denser"
                          IconProps={{
                            size: 'tiny'
                          }}
                          action={{
                            icon: Add,
                            tooltip: 'Add year',
                            onClick: handleAddClick,
                            IconButtonProps: {
                              disabled: content.state.isSubmitting
                            }
                          }}/>
      </Box>
    } else if (internalState.history?.length > 0) {
      return <Box className="EntityFinanceHistoryProfileCardContent-years">
        <Typography variant="subtitle2">History</Typography>
        <Box className="EntityFinanceHistoryProfileCardContent-selector">
          <ActionIconButton variant="outlined"
                            density="denser"
                            IconProps={{
                              size: 'tiny'
                            }}
                            action={{
                              icon: ChevronLeft,
                              tooltip: 'Previous year',
                              onClick: () => setActiveTab((current) => current - 1),
                              IconButtonProps: {
                                disabled: activeTab <= 0
                              }
                            }}/>
          <Typography variant="body2">{internalState.history?.[activeTab]?.year}</Typography>
          <ActionIconButton variant="outlined"
                            density="denser"
                            IconProps={{
                              size: 'tiny'
                            }}
                            action={{
                              icon: ChevronRight,
                              tooltip: 'Next year',
                              onClick: () => setActiveTab((current) => current + 1),
                              IconButtonProps: {
                                disabled: activeTab >= internalState.history.length - 1
                              }
                            }}/>
        </Box>
      </Box>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: isEditing
  });

  return <StyledEntityFinanceHistoryProfileCardContent ref={innerRef} {...innerProps}>
    {renderYearSelector()}
    {internalState.history?.map((h, idx) => {
      return <TabPanel key={h.year}
                       className="EntityFinanceHistoryProfileCardContent-tab"
                       value={activeTab ?? 0}
                       index={idx}>
        {renderHistoryForm(h)}
        {isEditing ? <ActionButton color="error"
                                   variant="contained"
                                   action={{
                                     label: 'Remove year',
                                     icon: Delete,
                                     onClick: handleRemoveClick,
                                     ButtonProps: {
                                       disabled: content.state.isSubmitting
                                     }
                                   }} /> : null}
      </TabPanel>
    })}
    {!(internalState.history?.length > 0) ? <Box className="EntityFinanceHistoryProfileCardContent-empty">
      <InlineForm ref={formRef}
                  fields={fields}
                  fieldData={fieldData}
                  onSubmit={handleSubmit}
                  onValidating={handleValidating}/>
      <P>No financial history data</P>
    </Box> : null}
  </StyledEntityFinanceHistoryProfileCardContent>
});

EntityFinanceHistoryProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  profile: PropTypes.object,
  card: PropTypes.object,
  content: PropTypes.object,
  entity: PropTypes.object,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func
};

EntityFinanceHistoryProfileCardContent.defaultProps = {
};

export default EntityFinanceHistoryProfileCardContent;


