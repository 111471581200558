import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useEntityArticleList} from 'services/entity/article/article.hooks';
import StyledEntityArticlesProfileCardContent
  from 'components/organisms/Cards/EntityArticlesProfileCardContent/EntityArticlesProfileCardContent.styles';
import ArticlesList from 'components/organisms/Lists/ArticlesList/ArticlesList';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';

const EntityArticlesProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    entity,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntityPatentGraphProfileCardContent', {
    static: ['isEditing']
  });

  const innerRef = useRef(null);
  const searchFieldRef = useRef(null);
  const [internalState, setInternalState] = useState({
    search: null
  });

  useImperativeHandle(ref, () => innerRef.current);

  const articles = useEntityArticleList({
    entityId: entity?.entityId,
    page: 0,
    pageSize: 10,
    search: internalState.search
  }, {
    ...constants.queryOptions.infinite,
    enabled: Boolean(entity?.entityId)
  });

  const isDataLoading = isLoading || articles.status.isLoading;

  const fields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: internalState.search,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      autoFocus: false,
      size: 'smaller',
      radius: 'round'
    },
    prefix: <Icon icon={Search} />,
    postfix: internalState.search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        setInternalState(utils.updater({search: ''}, true));
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [internalState.search]);

  const handleSearch = (field, value) => {
    setInternalState(utils.updater({search: value}, true));
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntityArticlesProfileCardContent ref={innerRef} {...innerProps}>
    <InlineForm className="EntityArticlesProfileCardContent-search"
                onChange={handleSearch}
                fields={fields} />
    {(articles.data?.length > 0 || isDataLoading) ?
      <ArticlesList className="EntityArticlesProfileCardContent-articles"
                    isLoading={isDataLoading}
                    articles={articles} /> : null}
    {(articles.data?.length === 0 && !isDataLoading) ?
      <Box className="EntityArticlesProfileCardContent-empty">
        <P>No news article(s) found</P>
      </Box> : null}
  </StyledEntityArticlesProfileCardContent>
});

EntityArticlesProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  entity: PropTypes.object,
  isLoading: PropTypes.bool
};

EntityArticlesProfileCardContent.defaultProps = {
};

export default EntityArticlesProfileCardContent;


