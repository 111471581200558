import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useColumnState, useComponentProps, useEffectEvent, useListState} from 'helpers/hooks/utils';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import constants from 'helpers/constants';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import Table from 'components/organisms/Tables/Table/Table';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import SettingsHeader from 'components/molecules/Headers/SettingsHeader/SettingsHeader';
import FieldsActionbar from 'components/organisms/Snackbars/FieldsActionbar/FieldsActionbar';
import {
  useClientCustomFieldAdd,
  useClientCustomFieldDelete,
  useClientCustomFieldList
} from 'services/client/customField/customField.hooks';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import Delete from '@mui/icons-material/Delete';
import IconButtonTableCell from 'components/molecules/TableCells/IconButtonTableCell/IconButtonTableCell';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import FieldAddDialog from 'components/organisms/Dialogs/FieldAddDialog/FieldAddDialog';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import Edit from '@mui/icons-material/Edit';
import Chip from 'components/atoms/Chips/Chip/Chip';
import {useClientCustomFieldGroups, useClientCustomFieldPatch} from 'services/client/customField/customField.utils';
import utils from 'helpers/utils';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledFieldsSettingPage from 'components/pages/Setting/FieldsSettingPage/FieldsSettingPage.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const info = 'Add and manage custom data fields. Custom data fields are applied to all company profiles to store specific company information';

const FieldsSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'FieldsSettingPage');

  const [showFieldAddDialog, setShowFieldAddDialog] = useState(false);

  const profileProvider = useProfile();
  const collection = profileProvider.context?.data;

  const authorize = useAuthorize();
  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const fieldAdd = useClientCustomFieldAdd();
  const deleteField = useClientCustomFieldDelete();
  const patchField = useClientCustomFieldPatch();
  const updateCollectionEvent = useEffectEvent(profileProvider.updaters?.updateCollection);

  const client = useAuthClient();
  const hasAutoLookup = Boolean(client?.props?.autoLookupPeriod !== constants.services.periods.never) || Boolean(collection?.autoLookupPeriod !== constants.services.periods.never);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25
      },
      options: {
        name: 'fieldsSettings'
      }
    },
    columnState: {
      initial: {
        columnPinning: {
          left: !smDown ? ['label'] : []
        },
        columnVisibility: !smDown ? null : {
          groupName: false,
          tooltip: false,
          autoLookup: false,
          autoLookupDescription: false
        },
        columnSizing: {
          label: 170
        }
      },
      options: {
        name: 'fieldsSettings'
      }
    }
  }), [smDown]);

  const listState = useListState(options.listState?.initial, options.listState?.options);
  const columnState = useColumnState(options?.columnState?.initial, options?.columnState?.options);

  const clientId = useAuthClientId();
  const fieldList = useClientCustomFieldList({
    clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enabled: Boolean(clientId)
  });

  const clearEvent = useEffectEvent(columnState.clear);
  useEffect(() => {
    clearEvent?.();
  }, [clearEvent, smDown]);

  const handleAutoLookupConfirm = useCallback((onConfirm, onClose) => {
    const handleConfirm = () => {
      const service = constants.data.lookup('services', constants.services.types.autotags);

      return updateCollectionEvent(collection, {
        autoLookupPeriod: service?.period
      })
        .then(() => {
          onConfirm?.();
        })
        .catch(() => {
          return 'Activating auto lookup failed';
        });
    }

    const handleClose = (e, reason) => {
      if (reason === 'cancelButtonClick') {
        onConfirm?.();
      } else if (reason !== 'confirmButtonClick') {
        onConfirm?.();
      }
      dialogControl.hide();
    }

    dialogControl.show(<ConfirmDialog question="You have selected to auto lookup. Do you wish to activate the auto lookup service?"
                                      onConfirm={handleConfirm}
                                      CancelButtonProps={{
                                        children: 'No'
                                      }}
                                      ConfirmButtonProps={{
                                        children: 'Yes'
                                      }}/>, true, handleClose);
  }, [dialogControl, collection, updateCollectionEvent]);

  const handleChange = useCallback((row, cell) => (field, value, onSuccess, onError) => {
    const doChange = () => {
      onSuccess?.(); // optimistic
      cell.column.columnDef.optimistic.set(cell, value);
      patchField(row.original, {[field.name]: value}, true)
        .catch(() => {
          onError?.();
          cell.column.columnDef.optimistic.reset(cell);
        });
    }

    if (!hasAutoLookup && field.name === 'autoLookup' && value === true) {
      handleAutoLookupConfirm(doChange, () => { /* SQUASH */ });
    } else {
      doChange();
    }
  }, [patchField, handleAutoLookupConfirm, hasAutoLookup]);

  const dirtyEvent = useEffectEvent(profileProvider.dirty);
  const handleDirty = useCallback((dirty) => {
    dirtyEvent?.(dirty);
  }, [dirtyEvent]);

  const groups = useClientCustomFieldGroups();
  const columnsMemo = useMemo(() => {
    const canEditColumn = (field) => {
      return authorize({attribute: 'settings.fields.update'}) &&
        authorize({attribute: 'field.update', meta: {field}});
    }
    const columns = [];

    columns.push({
      accessorKey: 'label',
      id: 'label',
      header: 'Name',
      size: 140,
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'field.column.label.update', meta: {field: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.text,
          validation: constants.formFieldValidationTypes.text,
          required: true,
          initial: name
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={name} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({cell, row, column, table}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={name}
                              action={action}/>
      }
    });

    columns.push({
      accessorKey: 'groupName',
      id: 'groupName',
      header: 'Group',
      size: 120,
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'field.column.groupName.update', meta: {field: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const group = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.autocomplete,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.label,
          required: true,
          initial: group,
          options: groups,
          FormFieldProps: {
            createOption: true
          }
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={group ? <Chip label={utils.upperFirst(group)}/> : ''}/>}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({cell, row, column, table}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const group = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={group ? <Chip label={utils.upperFirst(group)}/> : ''}
                              action={action}/>
      }
    });

    columns.push({
      accessorKey: 'renderer',
      id: 'renderer',
      header: 'Type',
      size: 1,
      minSize: 60,
      enableEditing: false,
      Cell: ({cell}) => {
        const type = cell.getValue();
        const typeDef = constants.data.lookup('customFieldRendererTypes', type);
        return <TextTableCell title={utils.isDefined(typeDef?.label) ? typeDef.label : 'Text'} />
      }
    });

    columns.push({
      accessorKey: 'tooltip',
      id: 'tooltip',
      header: 'Description',
      enableSorting: false,
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'field.column.tooltip.update', meta: {user: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const description = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.markdown,
          validation: constants.formFieldValidationTypes.text,
          required: true,
          initial: description,
          FormFieldProps: {
            multiline: false,
            minRows: 2,
            maxRows: 2,
          }
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={description} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({cell, row, column, table}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const description = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={description}
                              action={action}/>
      }
    });

    columns.push({
      accessorKey: 'autoLookup',
      id: 'autoLookup',
      header: 'Auto lookup',
      size: 140,
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'field.column.autoLookup.update', meta: {field: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const autoLookup = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        const field = [
          {
            name: column.id,
            label: column.columnDef.header,
            type: constants.formFieldTypes.list,
            conversion: constants.formFieldConversionTypes.value,
            initial: autoLookup,
            options: 'toggleYesNoReverse',
            FormFieldProps: {
              ListProps: {
                catchFocus: false
              }
            }
          }
        ];

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={field}
                                   Anchor={<TextTableCell title={autoLookup ? 'Yes' : 'No'} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({cell, row, column, table}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const autoLookup = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        const setEditingCellEvent = useEffectEvent(() => table.setEditingCell(cell));
        const action = useMemo(() => ({
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            if (!e.defaultPrevented) {
              setEditingCellEvent?.();
              e.preventDefault();
            }
          }
        }), [enableEditing, setEditingCellEvent]);

        return <TextTableCell title={autoLookup ? 'Yes' : 'No'}
                              action={action}/>
      }
    });

    columns.push({
      accessorKey: 'autoLookupDescription',
      id: 'autoLookupDescription',
      header: 'Lookup description',
      inlineLabel: 'lookup description',
      placeholder: 'Describe the field value',
      enableSorting: false,
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'field.column.lookupDescription.update', meta: {user: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const lookupDescription = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.markdown,
          validation: constants.formFieldValidationTypes.text,
          required: true,
          initial: lookupDescription,
          FormFieldProps: {
            multiline: false,
            minRows: 2,
            maxRows: 2,
          }
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={lookupDescription} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({cell, row, column, table}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const lookupDescription = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={lookupDescription}
                              action={action}/>
      }
    });

    columns.push({
      id: 'delete',
      header: '',
      size: 65,
      minSize: 65,
      muiTableHeadCellProps: {
        align: 'right'
      },
      muiTableBodyCellProps: {
        align: 'right'
      },
      enableEditing: false,
      enableSorting: false,
      Cell: ({cell, row}) => {
        const field = cell.row.original;
        const enableEditing = canEditColumn(row.original);

        const action = {
          label: 'Remove field',
          tooltip: 'Remove field',
          icon: <Icon icon={Delete} size="smaller" />,
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) :
            utils.createAuth({attribute: 'field.delete', meta: {field}}),
          onClick: (e) => {
            const handleConfirm = () => {
              deleteField.mutation.mutateAsync({
                clientId,
                fieldId: field.fieldId
              }).catch(() => {
                snackbar.show('Remove field failed', null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              });
            };

            dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this field?"
                                              explanation="The field will be removed including data related to this field"
                                              challenge={field.label}
                                              challengeLabel="field name"
                                              onConfirm={handleConfirm}
                                              ConfirmButtonProps={{
                                                children: 'Remove field',
                                                startIcon: <Icon icon={Delete} />,
                                                color: 'error'
                                              }} />, true);
            e.preventDefault();
          }
        };

        return <IconButtonTableCell ActionIconButtonProps={{
                                      variant: 'outlined',
                                      size: 'smaller',
                                      density: 'sparse',
                                      showInactive: true,
                                      action: action }} />
      }
    });
    return columns;
  }, [
    dialogControl, authorize, snackbar, clientId,
    groups, handleChange, deleteField.mutation, handleDirty
  ]);


  const action = useMemo(() => ({
    label: !mdDown ? 'Add field' : null,
    tooltip: mdDown ? 'Add field' : null,
    icon: <Icon icon={Add} />,
    iconPosition: 'start',
    auth: !authorize({attribute: 'settings.fields.update'}) ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({attribute: 'field.create'}),
    onClick: (e) => {
      setShowFieldAddDialog(true);
      e.preventDefault();
    },
    ButtonProps: !mdDown ? {
      color: 'success'
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'success',
      variant: 'contained',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [authorize, mdDown]);

  const renderFieldAddDialog = () => {
    const handleSubmit = (field) => {
      return new Promise((resolve) => {
        const doSubmit = () => {
          resolve(fieldAdd.mutation.mutateAsync({clientId, ...field})
            .then(() => {
              setShowFieldAddDialog(false);
            }));
        }

        if (!hasAutoLookup && field.autoLookup === true) {
          handleAutoLookupConfirm(doSubmit, () => resolve(false));
        } else {
          doSubmit();
        }
      });
    };

    const handleClose = () => {
      setShowFieldAddDialog(false);
    };

    if (showFieldAddDialog) {
      return <FieldAddDialog open={true}
                             onSubmit={handleSubmit}
                             onClose={handleClose}/>
    }
  };

  const searchField = useMemo(() => ({
    placeholder: 'Search for a field'
  }), []);

  const renderSettingsHeader = () => {
    return <SettingsHeader className="FieldsSettingPage-header"
                           title={!smDown ? 'Fields' : null}
                           info={!smDown ? info : null}
                           search={listState.search}
                           searchField={searchField}
                           onSearch={listState.setSearch}
                           buttons={!mdDown ? <ActionButton action={action} /> : <ActionIconButton action={action} />} />
  }

  return <StyledFieldsSettingPage as={TableWrapper} {...innerProps}
                                  dividers={true}
                                  header={renderSettingsHeader()}
                                  borders={{left: !smDown ? '3sp' : '2sp', right: !smDown ? '3sp' : '2sp', bottom: !smDown ? '3sp' : '2sp'}}>
    <Table className="FieldsSettingPage-table"
           dataKey="fieldId"
           enableParentScroll={true}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={true}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={true}
           listState={listState}
           columnState={columnState}
           onFetchMore={fieldList.query.fetchNextPage}
           onRefetch={fieldList.query.refetch}
           columns={columnsMemo}
           data={fieldList.data}
           rowCount={fieldList.meta?.resultsCount}
           state={{
             isLoading: fieldList.status.isLoading,
             showProgressBars: fieldList.status.isLoadingNext
           }}
           muiTableHeadCellProps={{align: 'left'}}
           muiTableBodyCellProps={{align: 'left'}}
           ActionbarComponent={<FieldsActionbar />}/>
    {renderFieldAddDialog()}
  </StyledFieldsSettingPage>
};

FieldsSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

FieldsSettingPage.defaultProps = {};

export default FieldsSettingPage;
