import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const StyledFieldInlineEdit = styled(Box)`
  > .Icon, > .Logo {
    margin-right: ${props => props.theme.layout('1sp')};
  }

  &.FieldInlineEditEdit {
    max-width: ${props => props.theme.layout(320)};
    max-height: min(${props => props.theme.layout(340)}, 48vh);

    ${props => utils.isDefined(props.$minWidth) ? css`
      max-width: unset;
    ` : css``}

    &:has(.SuggestionField) {
      max-width: unset;
    }

    &:has(.CloudField) {
      overflow: hidden;
    }
  }
  
  .Form {
    padding: ${props => props.theme.spacing(2)};
    &:has(.ListField) {
      padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
    }
    
    .Form-fields {
      height: 100%;
      
      .FormField {
        min-width: ${props => props.theme.layout(240)};
        
        &.ListField {
          &:not(.ListField-showSearch) {
            min-width: unset;
          }
        }

        &.SuggestionField {
          min-width: ${props => props.theme.layout(320)};
        }

        &.TextField-multiline {
          min-width: ${props => props.theme.layout(320)};
        }

        &.CloudField {
          max-width: ${props => props.theme.layout(380)};
          min-width: ${props => props.theme.layout(380)};
        }
      }
    }
  }
  
  .MuiFormControl-root {
    position: relative;
    &.CheckboxField, &.SwitchField {
      .FormControlLabel {
        margin-left: ${props => props.theme.layout(-8)};
      }  
      .Checkbox {
        padding: ${props => props.theme.layout(6)};
        svg {
          font-size: ${props => props.theme.fontSize(20)};
        }
      }
      .MuiFormHelperText-root:has(*) {
        bottom: ${props => props.theme.layout(-12)};
      }
    }
    
    .MuiFormHelperText-root:has(*) {
      display: flex;
      width: 100%;
      bottom: ${props => props.theme.layout(-14)};
      height: ${props => props.theme.layout(16)};

      > * {
        width: 1px;
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        p {
          display: contents;
        }
      }
    }
  }
  ${ComponentStyles}
`;

StyledFieldInlineEdit.propTypes = {
  theme: PropTypes.object
};

StyledFieldInlineEdit.defaultProps = {}

export default StyledFieldInlineEdit;
