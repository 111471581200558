import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import StyledTimelineList from 'components/organisms/Lists/TimelineList/TimelineList.styles';
import TimelineCard from 'components/molecules/Cards/TimelineCard/TimelineCard';
import Component from 'components/organisms/Utils/Component/Component';
import {useClientCallbacks} from 'services/client/client.utils';

const TimelineList = React.forwardRef((props, ref) => {
  const {
    gap,
    timeline,
    onEdit,
    Card,
    TimelineCardProps,
    ...innerProps
  } = useComponentProps(props, 'TimelineList');

  const clientCallbacks = useClientCallbacks();

  const TimelineCardDefault = ({item, ...props}) => {
    return <TimelineCard item={item}
                         {...props}/>
  };

  const CardMemo = useEffectItem(Card ?? TimelineCardDefault);
  const renderCard = (item, state) => {
    return <Component Original={CardMemo}
                      item={item}
                      selected={state.selected}
                      onEdit={onEdit}
                      callbacks={clientCallbacks}
                      isLoading={state.isLoading}
                      {...TimelineCardProps} />
  };

  return <StyledTimelineList ref={ref} {...innerProps}
                             data={timeline}
                             dataKey="timelineId"
                             renderItem={renderCard}
                             ListProps={{
                               virtualize: true,
                               gap: gap ? gap : 20
                             }} />
});

TimelineList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  gap: PropTypes.number,
  timeline: PropTypes.array,
  onEdit: PropTypes.func,
  Card: PropTypes.any,
  TimelineCardProps: PropTypes.object
};

TimelineList.defaultProps = {
};

export default TimelineList;
