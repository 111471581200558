import React, {useEffect, useRef} from 'react';
import StyledLoginForm from 'components/organisms/Forms/LoginForm/LoginForm.styles';
import {useAuthLogin} from 'services/auth/auth.hooks';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import Form from 'components/organisms/Forms/Form/Form';
import constants from 'helpers/constants';

const pages = {
  0: {
    title: 'Log in',
    fields: {
      email: {
        label: 'Email',
        inlineLabel: 'email',
        type: 'text',
        validation: 'email',
        initial: null,
        required: true,
        FormFieldProps: {
          autoFocus: true,
          hiddenLabel: true,
          inputProps: {
            autocomplete: 'username'
          }
        }
      },
      password: {
        label: 'Password',
        inlineLabel: 'password',
        type: 'password',
        validation: 'text',
        initial: null,
        required: true,
        FormFieldProps: {
          hiddenLabel: true,
          inputProps: {
            autocomplete: 'current-password'
          }
        }
      }
    }
  }
};

const LoginForm = (props) => {
  const innerProps = useComponentProps(props, 'LoginForm');

  const doLogin = useAuthLogin();

  const formRef = useRef(null);

  useEffect(() => {
    const formik = formRef.current?.formik;

    if (doLogin.status.isSuccess) {
      formik.setSubmitting(false);
    }

    const error = doLogin.status.error;
    if (error?.response?.status < constants.http.status.serverError) {
      formik.setErrors({
        email: 'Invalid email / password combination',
        password: 'Invalid email / password combination'
      });
    }
  }, [doLogin.status.isSuccess, doLogin.status.error]);

  const handleSubmit = (values) => {
    doLogin.mutation.mutate(values);
  };

  const handleChange = () => {
    doLogin.status.clearAll();
  };

  return <StyledLoginForm {...innerProps}>
    <Form ref={formRef}
          pages={pages}
          onChange={handleChange}
          onSubmit={handleSubmit}
          SubmitButtonProps={{children: 'Login'}}/>
    <Box className="auth-links">
      <ActionLink action={{
        label: 'Forgot password?',
        navigation: {
          to: `/auth/forgot`,
        }
      }}/>
    </Box>
  </StyledLoginForm>;
};

LoginForm.propTypes = {};

LoginForm.defaultProps = {};

export default LoginForm;
