import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOptimistic} from 'helpers/hooks/utils';
import StyledTaskCard from 'components/molecules/Cards/TaskCard/TaskCard.styles';
import Typography from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ActionCheckbox from 'components/molecules/Inputs/ActionCheckbox/ActionCheckbox';
import {useAuthTeamId, useAuthUserId} from 'services/auth/auth.utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import FormField from 'components/organisms/Fields/FormField/FormField';
import {useClientTeamMembers} from 'services/client/team/team.utils';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import People from '@mui/icons-material/People';
import Person from '@mui/icons-material/Person';
import {FlagOutlined} from '@mui/icons-material';
import TeamMembersAvatarGroup from 'components/molecules/Avatars/TeamMembersAvatarGroup/TeamMembersAvatarGroup';
import {useTaskCompanyAction, useTaskLocation, useTaskResponsibility} from 'services/comment/comment.utils';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const TaskCard = React.forwardRef((props, ref) => {
  const {
    task,
    isLoading,
    showText,
    showLocation,
    showDone,
    onClick,
    onToggle,
    onCanUpdate,
    orientation,
    variant,
    size,
    callbacks,
    ...innerProps
  } = useComponentProps(props, 'TaskCard', {
    variable: ['orientation'],
    static: ['isLoading', 'showDone']
  });

  const compact = variant === 'compact';
  const vertical = orientation === 'vertical';

  const userId = useAuthUserId();
  const authorize = useAuthorize();

  const snackbar = useSnackbar();

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);

  const [isResponsible, isDone] = useTaskResponsibility(task, userId);
  const [done, toggleDoneOptimistic] = useOptimistic(isDone, utils.asPromise(onToggle));

  const taskLocation = useTaskLocation(task);
  const taskAction = useTaskCompanyAction(task);

  const mentions = useMemo(() => [{
    trigger: '@',
    data: (search, callback) => callbacks.members({search, callback})
  }], [callbacks]);

  const toggleMarkDoneAction = useMemo(() => {
    const type = constants.data.lookup('taskTypes', task?.actionItem?.type);

    const canToggle = isResponsible && (!type || type.value === constants.task.types.other);
    const canToggleTask = canToggle && (onCanUpdateEvent ? onCanUpdateEvent?.(task, true) : true) && authorize({
      attribute: 'task.markDone', meta: {task}
    });

    return {
      label: 'Done',
      tooltip: 'Done',
      auth: !canToggleTask ? utils.createAuth({attribute: 'system.null'}) : null,
      icon: <Icon icon={CheckCircle} color={'action.focus'}/>,
      checkedIcon: <Icon icon={CheckCircle} color={'success'}/>,
      active: done,
      onClick: (e) => {
        toggleDoneOptimistic?.(!done, task, !done)
          .then(() => !done)
          .catch(() => {
            snackbar.show('Marking task failed', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          });

        e.stopPropagation();
        e.preventDefault();
      }
    }
  }, [authorize, snackbar, onCanUpdateEvent, task, isResponsible, done, toggleDoneOptimistic]);

  const teamId = useAuthTeamId();
  const teamMembers = useClientTeamMembers(teamId, true);
  const responsible = useMemo(() => {
    if (teamMembers) {
      let responsible = utils.toArray(task.actionItem?.responsible)
        .map((r) => teamMembers.find((tm) => +tm.userId === +r.userId))
        .filter((_) => (_));
      if (responsible.length === 0) {
        responsible = [constants.client.team.allMembers];
      }
      return responsible;
    }
  }, [task.actionItem?.responsible, teamMembers]);

  const renderTextField = (text, field) => {
    return <FormField field={{
                        name: `task_${field}`,
                        type: constants.formFieldTypes.markdown
                      }}
                      className="text"
                      mentions={mentions}
                      value={text}
                      fullWidth={false}
                      readOnly={true}
                      hiddenHelperText={true}
                      hiddenLabel={true}
                      hiddenPlaceholder={true}/>
  }

  const renderTitle = () => {
    return <React.Fragment>
      {renderTextField(task.actionItem?.title ?? 'Task', 'title')}{!compact ? <React.Fragment> for  <ActionLink action={taskAction} /></React.Fragment> : null}
    </React.Fragment>
  }

  const renderLocation = () => {
    const to = taskLocation.value === constants.task.locations.collection ?
      `/collections/${task.collection.collectionId}/entities` : `/${taskLocation.value}`;

    const locationAction = {
      label: taskLocation.label,
      navigation: {
        to: to,
        resetSearchParams: true
      },
      icon: taskLocation.icon
    };

    if (showLocation) {
      return <ActionChip className="TaskCard-location"
                         action={locationAction}
                         variant="outlined"
                         color="primary"
                         size={vertical ? 'medium' : 'small'}
                         isLoading={isLoading} />
    }
  }

  const renderTags = () => {
    const collaborationType = constants.data.lookup('taskCollaborationTypes', task.actionItem?.collaborationType) ??
      constants.data.lookup('taskCollaborationTypes', constants.task.collaborationTypes.collective);

    return <ChipList className="TaskCard-tags">
      {!vertical ? renderLocation() : null}
      <Typography className="TaskCard-tags-collaborate"
                  variant="caption"
                  color="text.secondary"
                  isLoading={isLoading}>
        <Icon size="smaller"
              icon={collaborationType.value === constants.task.collaborationTypes.collective ? People : Person}/>
        {collaborationType.label}
      </Typography>
    </ChipList>
  }

  const renderDue = () => {
    if (task.actionItem?.dueDate) {
      return <Typography className="TaskCard-due"
                         variant="caption" color="text.secondary">
        <Icon size="smaller" icon={FlagOutlined}/>
        {utils.dayjs(new Date(task.actionItem?.dueDate)).format('D MMM')}
      </Typography>
    }
  }

  const handleClick = (e) => {
    onClick?.(e, task);
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTaskCard ref={ref} {...innerProps}
                         isLoading={isLoading}
                         variant="outlined"
                         onClick={handleClick}>
    <Box className="TaskCard-content">
      {vertical ? renderLocation() : null}
      <Box className="TaskCard-title">
        <Typography isLoading={isLoading} variant="subtitle1">
          {renderTitle()}
        </Typography>
        {showDone ? <ActionCheckbox className="TaskCard-title-check"
                                    isLoading={isLoading}
                                    action={toggleMarkDoneAction}
                                    size={(vertical && size !== 'small') ? 'medium' : 'small'}
                                    density="dense"
                                    showInactive={false} /> : null}
      </Box>
      <Box className="TaskCard-subject">
        {(showText && task.text) ? <Typography isLoading={isLoading} variant="body2">
          {renderTextField(task.text, 'text')}
        </Typography> : null}

        <TeamMembersAvatarGroup className="TaskCard-responsible"
                                teamMembers={responsible}
                                size="small"
                                max={6}
                                isLoading={isLoading}/>
      </Box>
    </Box>

    <Box className="TaskCard-footer">
      {renderTags()}
      <TeamMembersAvatarGroup className="TaskCard-responsible"
                              teamMembers={responsible}
                              size="small"
                              max={3}
                              isLoading={isLoading}/>
      {renderDue()}
    </Box>
  </StyledTaskCard>
});

TaskCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  task: PropTypes.object,
  isLoading: PropTypes.bool,
  showText: PropTypes.bool,
  showLocation: PropTypes.bool,
  showDone: PropTypes.bool,
  callbacks: PropTypes.object,
  onClick: PropTypes.func,
  onToggle: PropTypes.func,
  onCanUpdate: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'compact']), PropTypes.string]),
  orientation: PropTypes.oneOfType([PropTypes.oneOf(['vertical', 'horizontal']), PropTypes.string]),
};

TaskCard.defaultProps = {
  orientation: 'horizontal',
  showLocation: true
};

export default TaskCard;
