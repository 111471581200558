import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import Checkbox from 'components/atoms/Inputs/Checkbox/Checkbox';
import FormControlLabel from 'components/atoms/Labels/FormControlLabel/FormControlLabel';
import {P, Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import dom from 'helpers/dom';
import StyledListField from 'components/molecules/Fields/ListField/ListField.styles';
import FormLabel from 'components/atoms/Labels/FormLabel/FormLabel';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import Radio from 'components/atoms/Inputs/Radio/Radio';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import TextField from 'components/molecules/Fields/TextField/TextField';
import constants from 'helpers/constants';
import InputAdornment from 'components/atoms/Inputs/InputAdornment/InputAdornment';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Clear from '@mui/icons-material/Clear';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import {withMemo} from 'helpers/wrapper';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';

const ListField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    value,
    size,
    placeholder,
    icon,
    hiddenPlaceholder,
    hiddenSelectionHelper,
    hiddenIcons,
    helperText,
    autoFocus,
    multiple,
    loadEmpty,
    catchFocus,
    options,
    initialSearch,
    showSearch,
    createOption,
    format,
    isLoading,
    limitOptions,
    forceFiltering,
    emptyText,
    deselect,
    readOnlyChip,
    indeterminate,
    onBlur,
    onChange,
    onFilterOption,
    renderOption,
    renderOptionLabel,
    renderReadOnlyOption,
    renderInputAdornment,
    readOnlyOptionClick,
    readOnlyAction,
    inputProps,
    InputProps,
    InputLabelProps,
    FormHelperTextProps,
    ChipListProps,
    ChipProps,
    IconProps,
    ListProps,
    ListItemProps,
    SearchFieldProps,
    ...innerProps
  } = useComponentProps(props, 'ListField', {
    static: ['disabled', 'focused', 'error', 'fullHeight', 'multiple', 'showSearch'],
    children: ['checkbox', 'label', 'helper']
  });

  const innerRef = useRef(null);
  const searchRef = useRef(null);
  const selectionRef = useRef([]);
  const [focusActive, setFocusActive] = React.useState(false);

  const [internalState, setInternalState] = useState({
    inputValue: initialSearch ?? '',
    isLoading: false,
    internalOptions: {searchValue: null, options: []},
    filteredOptions: {inputValue: null, searchValue: null, options: []}
  });

  const listField = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    state: {
      ...internalState, // the state from start, and internal toggle
      ...utils.cleanObject({isLoading}) // the override state (nulls are cleaned), see onToggle
    },
    reset: () => {
      setInternalState((current) => ({
        ...current,
        internalOptions: {
          searchValue: null,
          options: []
        }
      }));
    }
  }), [internalState, isLoading]);

  useImperativeHandle(ref, () => listField);

  const isOptionEqualToValue = useCallback((option, value) => {
    return utils.matchOptionToValue(option, value);
  }, []);

  const cacheSelectedResults = useCallback((values, lookup = true) => {
    selectionRef.current = !lookup ? values : values
      .map((v) => {
        return selectionRef.current.find((option) => isOptionEqualToValue(option, v)) ??
          (utils.isObject(v) ? {...v, undefinedOption: (utils.isEmpty(v.label) || utils.isEmpty(v.value)) && !v.createdOption} :
            {label: v.toString(), value: v, undefinedOption: !utils.isEmpty(v)});
      });
  }, [isOptionEqualToValue]);

  cacheSelectedResults(utils.toArray(value, true));

  const optionsIsFn = utils.isFunction(options);
  const optionsEvent = useEffectEvent(options);
  const optionsMemo = useEffectItem(optionsIsFn ? null : options);

  const indexOptions = useCallback((options) => {
    return (options || []).map((opt, idx) => {
      if (utils.isObject(opt)) {
        return {...opt, index: idx};
      } else {
        return {label: opt, value: opt, index: idx};
      }
    });
  }, []);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('input'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const doChange = (value) => {
    onChange?.({
      target: {
        name: name,
        value: value ?? ''
      }
    });

    cacheSelectedResults(utils.toArray(value, true));
  };

  const handleFocus = (e) => {
    innerProps.onFocus?.(e);
    setFocusActive(!innerProps.readOnly);
  };

  const handleBlur = (e) => {
    setFocusActive(false);

    if (focusActive) {
      if (innerRef.current && !dom.isPartOfParent(e.relatedTarget, innerRef.current.querySelector('.InputContainer'))) {
        onBlur?.({
          target: {
            name: name
          }
        });
      }
    }
  };

  const handleChange = (option) => (e) => {
    if (e?.target?.name) {
      const checked = e.target.checked;
      if (option) {
        let value;
        if (multiple) {
          if (option.allSelectedOption && checked) {
            value = [option];
          } else {
            const selectedOption = selection.find((opt) => isOptionEqualToValue(opt, option));
            if (indeterminate && !checked && selectedOption && !selectedOption.indeterminate) {
              value = selection.map((opt) => {
                return !isOptionEqualToValue(opt, option) ? opt : {
                  ...opt,
                  indeterminate: true
                };
              });
            } else {
              value = !checked ? selection.filter((opt) => !isOptionEqualToValue(opt, option)) :
                selection.filter((opt) => !isOptionEqualToValue(opt, option)).concat(option);
            }
          }
        } else {
          if (!isOptionEqualToValue(selection, option)) {
            value = option;
          } else {
            value = deselect ? '' : (selection ?? '');
          }
        }

        doChange(value);
      }
    }
  };

  const selection = useMemo(() => {
    const getOption = (v) => {
      const opt = listField.state.internalOptions.options?.find((opt) => isOptionEqualToValue(opt, v)) ??
        selectionRef.current.find((opt) => isOptionEqualToValue(opt, v));
      if (opt) {
        if (utils.isObject(v)) {
          return {...v, ...opt};
        } else {
          return opt;
        }
      } else {
        if (utils.isObject(v)) {
          return {...v, undefinedOption: (utils.isEmpty(v.label) || utils.isEmpty(v.value)) && !v.createdOption};
        } else if (utils.isDefined(v) && utils.isString(v)) {
          return {label: v.toString(), value: v, undefinedOption: !utils.isEmpty(v)};
        } else if (utils.isDefined(v)) {
          return {label: '???', value: v, undefinedOption: !utils.isEmpty(v)};
        } else {
          return null;
        }
      }
    }

    let selection;
    if (multiple) {
      selection = (typeof value === 'string' && value) ?
        value.split(',') : value;
      selection = selection ? utils.toArray(selection, true)
        .map((v) => getOption(v)).filter((_) => (_)) : [];
      cacheSelectedResults(utils.toArray(selection, true), false);
      selection = selection.filter((opt) => !optionsIsFn || !opt.undefinedOption);
    } else {
      selection = getOption(value);

      cacheSelectedResults(utils.toArray(selection, true), false);
      selection = (!optionsIsFn || !selection?.undefinedOption) ? selection : null;
    }

    return selection;
  }, [value, listField.state.internalOptions.options, multiple, isOptionEqualToValue, cacheSelectedResults, optionsIsFn]);

  const applyFiltering = !optionsIsFn || forceFiltering;
  const onFilterOptionEvent = useEffectEvent(onFilterOption);
  const filterOptions = useCallback((opts, inputValue, searchValue) => {
    opts = opts.filter((opt) => {
      const filter = onFilterOptionEvent?.(opt);
      return !opt.createdOption && (utils.isDefined(filter) ? filter : true);
    });

    const value = inputValue?.toString()?.trim?.();
    if (value?.length > 0) {
      opts = applyFiltering ? opts.filter((opt) => {
        return opt.alwaysVisibleOption || (opt?.label ?? opt).toLowerCase().includes(value.toLowerCase())
      }) : opts;

      if (!listField.state.isLoading && Boolean(createOption)) {
        const filter = onFilterOptionEvent?.(value);
        if (!opts.find((opt) => isOptionEqualToValue(opt, value)) &&
            (!utils.isDefined(filter) || filter) &&
            !utils.toArray(selectionRef.current, true).find((opt) => isOptionEqualToValue(opt, value))) {
          const option = {
            label: value,
            createdOption: true,
            value: null,
            ...(utils.isFunction(createOption) ? createOption(value) : {})
          };

          opts.push(option);
        }
      }
    }

    setInternalState((current) => {
      if (current.filteredOptions.inputValue !== inputValue || current.filteredOptions.searchValue !== searchValue || !utils.compare(current.filteredOptions.options, opts)) {
        return {...current, filteredOptions: {inputValue, searchValue, options: indexOptions(opts)}};
      } else {
        return current;
      }
    });
    return opts;
  }, [createOption, applyFiltering, onFilterOptionEvent, isOptionEqualToValue, indexOptions, selectionRef, listField.state.isLoading]);

  const getOptions = useCallback((inputValue, callback) => {
    optionsEvent?.({search: inputValue, callback});
  }, [optionsEvent]);

  const debouncedOptions = useMemo(() => {
    return utils.debounce((inputValue, callback) => {
      getOptions(inputValue, callback);
    }, constants.debounce.input);
  }, [getOptions]);

  const searchValue = listField.state.inputValue?.toString()?.trim();
  const undefinedOptions = useEffectItem(utils.toArray(selectionRef.current, true).filter((s) => s.undefinedOption));

  useEffect(() => {
    let active = true;

    if (optionsIsFn) {
      if (!innerProps.readOnly && listField.state.internalOptions.searchValue !== searchValue) {
        if ((loadEmpty && undefinedOptions.length === 0) || searchValue || listField.state.internalOptions.searchValue) {
          setInternalState(utils.updater({
            isLoading: true
          }, true));

          const optionsFn = !utils.isEmpty(searchValue) ? debouncedOptions : getOptions;
          optionsFn(searchValue, (results) => {
            if (active) {
              if (!searchValue) {
                const notAvailable = utils.toArray(selectionRef.current, true)
                  .filter((opt1) => !opt1.undefinedOption && !results.find((opt2) => isOptionEqualToValue(opt1, opt2)));
                results = notAvailable.concat(results);
              }

              setInternalState((current) => {
                if (current.internalOptions.searchValue !== searchValue || !utils.compare(current.internalOptions.options, results)) {
                  return {
                    ...current,
                    isLoading: false,
                    internalOptions: {
                      searchValue: searchValue,
                      options: indexOptions(results.map((_) => (_)))
                    }
                  };
                } else {
                  return {...current, isLoading: false};
                }
              });
            } else {
              setInternalState(utils.updater({
                isLoading: false
              }, true));
            }
          });
        } else {
          setInternalState((current) => {
            const results = utils.toArray(selectionRef.current, true)
              .filter((opt) => !opt.undefinedOption);
            if (current.internalOptions.searchValue !== searchValue || !utils.compare(current.internalOptions.options, results)) {
              return {
                ...current,
                isLoading: false,
                internalOptions: {
                  searchValue: '',
                  options: results
                }
              };
            } else {
              return {...current, isLoading: false};
            }
          });
        }
      }
    }

    return () => {
      active = false;
    };
  }, [optionsIsFn, multiple, loadEmpty, undefinedOptions.length, innerProps.readOnly, selectionRef, debouncedOptions, getOptions,
    isOptionEqualToValue, searchValue, indexOptions, listField.state.internalOptions.searchValue]);

  useEffect(() => {
    let active = true;

    if (optionsIsFn) {
      if (undefinedOptions.length > 0) {
        optionsEvent?.({
          search: '',
          ids: undefinedOptions.map((opt) => `${opt.value ?? opt.label ?? opt}`),
          callback: (results) => {
            if (active) {
              setInternalState((current) => {
                if (!utils.compare(current.internalOptions.options, results)) {
                  return {
                    ...current,
                    isLoading: false,
                    internalOptions: {
                      searchValue: null,
                      options: indexOptions(results.map((_) => (_)))
                    }
                  };
                } else {
                  return {...current, isLoading: false};
                }
              });
            } else {
              setInternalState(utils.updater({
                isLoading: false
              }, true));
            }
          }
        });
      }
    }

    return () => {
      active = false;
    };
  }, [optionsIsFn, undefinedOptions, debouncedOptions, optionsEvent, indexOptions, isOptionEqualToValue]);

  useLayoutEffect(() => {
    if (!optionsIsFn) {
      const results = optionsMemo ?? utils.toArray(selectionRef.current, true)
        .filter((opt) => !opt.undefinedOption);
      setInternalState((current) => {
        const options = indexOptions(results.map((_) => (_)));
        if (!utils.compare(current.internalOptions.options, options)) {
          return {
            ...current,
            internalOptions: {
              searchValue: searchValue,
              options: options
            }
          };
        } else {
          return current;
        }
      });
    }
  }, [optionsIsFn, optionsMemo, indexOptions, searchValue, selectionRef]);

  const handleInputValueChange = (e) => {
    setInternalState(utils.updater({
      inputValue: e.target.value
    }, true));
  }

  const handleInputKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      dom.focusElement(dom.getParent(e.target, null, 'ListField-container').querySelector('.ListField-list'));
      e.preventDefault();
    }
  }

  useEffect(() => {
    filterOptions(listField.state.internalOptions.options, searchValue, listField.state.internalOptions.searchValue);
  }, [searchValue, listField.state.internalOptions.searchValue, listField.state.internalOptions.options, filterOptions]);

  const renderReadOnly = () => {
    const chipSize = size === 'smaller' ? 'small' : size;
    const iconSize = size === 'medium' ? 'small' : 'smaller';
    const useChip = readOnlyChip ?? multiple;
    if (multiple) {
      if (selection) {
        if (useChip) {
          return <ChipList className="ListField-readOnly ListField-readOnly-chips Input-readOnly"
                           {...ChipListProps}>
            {utils.toArray(selection, true)
              .sort((a, b) => a.index - b.index)
              .map((v, idx) => {
                if (renderReadOnlyOption) {
                  return renderReadOnlyOption(v, {readOnly: true, format, placeholder});
                } else {
                  const optionIcon = icon ?? ChipProps?.icon ?? v.icon;
                  const optionAction = utils.isFunction(readOnlyAction) ? readOnlyAction(v) :
                    (readOnlyAction ?? v.action);

                  const action = {
                    label: utils.fieldValue2FormattedValue(format, v.label, true, false),
                    icon: (optionIcon && !hiddenIcons) ?
                      <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null,
                    ChipProps: utils.filterObject(ChipProps, ['icon']),
                    onClick: readOnlyOptionClick?.(v),
                    ...optionAction
                  }

                  return <ActionChip key={idx} size={chipSize} action={action} showInactive={true}/>
                }
              })}
          </ChipList>
        } else {
          return <Box className="ListField-readOnly Input-readOnly">
            {utils.toArray(selection, true)
              .sort((a, b) => a.index - b.index)
              .map((v, idx) => {
                if (renderReadOnlyOption) {
                  return renderReadOnlyOption(v, {readOnly: true, format, placeholder});
                } else {
                  const optionIcon = icon ?? v.icon;
                  return <Box key={idx}>
                    {(optionIcon && !hiddenIcons) ? <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null}
                    <Span>{utils.fieldValue2FormattedValue(format, v.label, true, false)}</Span>
                  </Box>
                }
              })}
          </Box>
        }
      }
    } else if (useChip) {
      const render = (option) => {
        if (renderReadOnlyOption) {
          return renderReadOnlyOption(option, {readOnly: true, format, placeholder, hiddenPlaceholder, iconSize});
        } else {
          const optionIcon = icon ?? option?.icon;

          if (option) {
            const optionAction = utils.isFunction(readOnlyAction) ? readOnlyAction(option) :
              (readOnlyAction ?? option.action);

            const action = {
              label: utils.fieldValue2FormattedValue(format, option.label, true, false),
              icon: (optionIcon && !hiddenIcons) ?
                <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null,
              ChipProps: utils.filterObject(ChipProps, ['icon']),
              onClick: readOnlyOptionClick?.(option),
              ...optionAction
            }

            return <ActionChip size={chipSize} action={action} showInactive={true}/>
          } else {
            return <React.Fragment>
              {(optionIcon && !hiddenIcons) ?
                <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null}
              {(placeholder && !hiddenPlaceholder) ?
                <Span className="placeholder">{placeholder}</Span> : <Span>&nbsp;</Span>}
            </React.Fragment>
          }
        }
      }

      return <Box className="ListField-readOnly ListField-readOnly-chips Input-readOnly">
        {render(selection)}
      </Box>
    } else {
      const render = (option) => {
        if (renderReadOnlyOption) {
          return renderReadOnlyOption(option, {readOnly: true, format, placeholder, hiddenPlaceholder, iconSize});
        } else {
          const optionIcon = icon ?? option?.icon;
          const optionLabel = option?.label;
          return <React.Fragment>
            {(option && optionLabel) ? InputProps?.startAdornment : null}
            {(option && optionIcon && !hiddenIcons) ?
              <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null}
            {(option && optionLabel) ? <Span>{utils.fieldValue2FormattedValue(format, option.label, true, false)}</Span> :
              ((placeholder && !hiddenPlaceholder) ? <Span className="placeholder">{placeholder}</Span> :
                <Span>&nbsp;</Span>)}
            {InputProps?.endAdornment}
          </React.Fragment>
        }
      }

      return <Box className="ListField-readOnly Input-readOnly">
        {render(selection)}
      </Box>
    }
  }

  const renderList = () => {
    let uncheckedCount = 0;
    const showOption = (checked, idx) => {
      const maxUnchecked = (limitOptions || 0) - utils.toArray(selection).length;
      uncheckedCount += !checked ? 1 : 0;

      return !utils.isDefined(limitOptions) || limitOptions === false || !showSearch || (
        (searchValue?.length > 0 && idx < limitOptions) ||
        (searchValue?.length === 0 && checked) ||
        (searchValue?.length === 0 && !checked && uncheckedCount <= maxUnchecked)
      )
    }

    const renderEmpty = () => {
      if (showSearch && listField.state.filteredOptions.options?.length === 0) {
        return <Box className="ListField-empty">
          <P>{listField.state.isLoading ? 'Searching...' : (emptyText ?? 'No options found')}</P>
        </Box>
      }
    }

    if (!multiple) {
      return <List id={id}
                   name={name}
                   className="ListField-list"
                   track={true}
                   catchFocus={catchFocus && !showSearch}
                   {...ListProps}>
        {listField.state.filteredOptions.options.map((option, idx) => {
          const selectedOption = utils.toArray(selection).find((opt) => isOptionEqualToValue(opt, option));
          const checked = Boolean(selectedOption);
          const disabled = Boolean(selectedOption?.disabled ?? option.disabled) || innerProps.disabled;
          if (showOption(checked, idx)) {
            const name = `${id}:${option.value ?? option}`;
            const renderedOption = <FormControlLabel value={checked}
                                                     label={renderOptionLabel ? renderOptionLabel(option, {checked, disabled}) : (option?.label ?? option)}
                                                     disabled={disabled}
                                                     control={<Radio id={name}
                                                                     name={name}
                                                                     checked={checked}
                                                                     size={size}
                                                                     density="dense"
                                                                     className="ListField-radio"
                                                                     disabled={disabled}
                                                                     onClick={handleChange(option)}
                                                                     inputProps={inputProps}
                                                                     {...InputProps}/>}
                                                     {...InputLabelProps}
                                                     className={utils.classNames('ListField-radio-label', InputLabelProps?.className)}/>

            return <ListItem key={idx} density="densest" {...ListItemProps}>
              {renderOption ? renderOption(option, {
                name, checked, disabled, multiple,
                InputLabelProps, inputProps, InputProps,
                renderedOption,
                onChange: handleChange(option)
              }) : renderedOption}
            </ListItem>
          } else {
            return null;
          }
        }).filter((_) => (_))}

        {renderEmpty()}
      </List>
    } else {
      const allIsSelected = Boolean(utils.toArray(selection).find((opt) => opt.allSelectedOption));

      return <List id={id}
                   name={name}
                   className="ListField-list"
                   track={true}
                   catchFocus={catchFocus && !showSearch}
                   {...ListProps}>
        {listField.state.filteredOptions.options.map((option, idx) => {
          const selectedOption = utils.toArray(selection).find((opt) => isOptionEqualToValue(opt, option));
          const checked = Boolean(selectedOption) && (!allIsSelected ? true : option.allSelectedOption);
          const indeterminate = checked && selectedOption.indeterminate;
          const disabled = (allIsSelected && !option.allSelectedOption) || Boolean(selectedOption?.disabled ?? option.disabled) || innerProps.disabled;
          if (showOption(checked, idx)) {
            const name = `${id}:${option.value ?? option}`;
            const renderedOption = <FormControlLabel value={checked}
                                                     label={renderOptionLabel ? renderOptionLabel(option, {checked, disabled}) : (option?.label ?? option)}
                                                     disabled={disabled}
                                                     control={<Checkbox id={name}
                                                                        name={name}
                                                                        checked={checked}
                                                                        indeterminate={indeterminate}
                                                                        disabled={disabled}
                                                                        size={size}
                                                                        density="dense"
                                                                        className="ListField-checkbox"
                                                                        onChange={handleChange(option)}
                                                                        inputProps={inputProps}
                                                                        {...InputProps}/>}
                                                     {...InputLabelProps}
                                                     className={utils.classNames('ListField-checkbox-label', InputLabelProps?.className)}/>
            return <ListItem key={idx} density="densest" focusable={false} {...ListItemProps}>
              {renderOption ? renderOption(option, {
                name, checked, disabled, multiple,
                InputLabelProps, inputProps, InputProps,
                renderedOption,
                onChange: handleChange(option)
              }) : renderedOption}
            </ListItem>
          } else {
            return null;
          }
        }).filter((_) => (_))}

        {renderEmpty()}
      </List>
    }
  }

  const clearAction = useMemo(() => ({
    icon: <Icon icon={Clear}/>,
    onClick: () => {
      setInternalState(utils.updater({inputValue: ''}, true));
      dom.focusElement(searchRef.current?.querySelector?.('input'));
    }
  }), []);

  const renderAdornment = () => {
    const adornment = [];
    if (listField.state.isLoading) {
      adornment.push(<CircularProgress className={`ListField-endAdornment loader ${listField.state.inputValue ? 'second' : ''}`} />);
    } else if (InputProps?.endAdornment) {
      adornment.push(InputProps?.endAdornment);
    }

    const renderedAdornment = adornment.concat(listField.state.inputValue ? <InputAdornment position="end">
      <ActionIconButton action={clearAction} />
    </InputAdornment> : [])
      .map((child, idx) => utils.cloneElement(child, {key: idx}))

    if (renderInputAdornment) {
      return renderInputAdornment({
        value: selection,
        inputValue: listField.state.inputValue,
        readOnly: innerProps.readOnly,
        disabled: innerProps.disabled,
        isLoading: listField.state.isLoading,
        options: listField.state.filteredOptions.options,
        renderedAdornment: renderedAdornment,
        InputProps
      });
    } else {
      return renderedAdornment;
    }
  }

  innerProps.focused = innerProps.focused ?? focusActive;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledListField ref={innerRef} {...innerProps}
                          onFocus={handleFocus}
                          onBlur={handleBlur}>
    <FormLabel size={size}
               {...utils.cleanObject({
                 htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
               })}
               className={utils.classNames('ListField-label', InputLabelProps?.className)}
               {...InputLabelProps}>
      {label}
    </FormLabel>
    <InputContainer className="ListField-container">
      {(!innerProps.readOnly && showSearch) ? <TextField ref={searchRef}
                                                         className="ListField-search"
                                                         value={listField.state.inputValue}
                                                         onChange={handleInputValueChange}
                                                         onKeyDown={handleInputKeyDown}
                                                         InputProps={{
                                                           ...InputProps,
                                                           endAdornment: renderAdornment()
                                                         }}
                                                         inputProps={{
                                                           ...inputProps
                                                         }}
                                                         {...utils.cleanObject({
                                                           autoFocus,
                                                           format,
                                                           hiddenLabel: true,
                                                           hiddenHelperText: true,
                                                           fullWidth: innerProps.fullWidth,
                                                           readOnly: innerProps.readOnly,
                                                           variant: innerProps.variant,
                                                           disabled: innerProps.disabled,
                                                           placeholder: placeholder,
                                                           size: size === 'medium' ? 'small' : (size === 'small' ? 'smaller' : 'smallest')
                                                         })}
                                                         {...SearchFieldProps}/> : null}
      {!innerProps.readOnly ? renderList() : null}
      {innerProps.readOnly ? renderReadOnly() : null}

      <FormHelperText component="div" {...FormHelperTextProps}
                      className={utils.classNames('ListField-helper', FormHelperTextProps?.className)}>
        {((!helperText || helperText.props?.children === '') && !multiple && !hiddenSelectionHelper && selection?.helper) ? selection?.helper : helperText}
      </FormHelperText>
    </InputContainer>
  </StyledListField>
}));

ListField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  variant: PropTypes.string,
  placeholder: PropTypes.any,
  icon: PropTypes.any,
  hiddenPlaceholder: PropTypes.bool,
  hiddenSelectionHelper: PropTypes.bool,
  hiddenIcons: PropTypes.bool,
  value: PropTypes.any,
  size: PropTypes.string,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  multiple: PropTypes.bool,
  loadEmpty: PropTypes.bool,
  catchFocus: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  createOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  format: PropTypes.string,
  initialSearch: PropTypes.string,
  showSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  limitOptions: PropTypes.any,
  applyFiltering: PropTypes.bool,
  emptyText: PropTypes.string,
  readOnlyChip: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFilterOption: PropTypes.func,
  renderOption: PropTypes.func,
  renderOptionLabel: PropTypes.func,
  renderReadOnlyOption: PropTypes.func,
  renderInputAdornment: PropTypes.func,
  readOnlyOptionClick: PropTypes.func,
  readOnlyAction: PropTypes.any,
  inputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  ChipListProps: PropTypes.object,
  ChipProps: PropTypes.object,
  IconProps: PropTypes.object,
  ListProps: PropTypes.object,
  ListItemProps: PropTypes.object,
  SearchFieldProps: PropTypes.object
};

ListField.defaultProps = {
  inputProps: {},
  catchFocus: false,
  size: 'small',
  limitOptions: 10,
  loadEmpty: true,
  hiddenIcons: true
};

export default ListField;
