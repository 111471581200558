import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Menu from '@mui/icons-material/Menu';
import ArrowForward from '@mui/icons-material/ArrowForward';
import EntityHeading from 'components/molecules/Headings/EntityHeading/EntityHeading';
import StyledEntityBrowserBar from 'components/organisms/Bars/EntityBrowserBar/EntityBrowserBar.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import MenuOpen from '@mui/icons-material/MenuOpen';
import List from '@mui/icons-material/List';
import Box from 'components/atoms/Layout/Box/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import AnalysisScoreInline from 'components/molecules/Inlines/AnalysisScoreInline/AnalysisScoreInline';
import {useCollectionGet} from 'services/collection/collection.hooks';
import {Span} from 'components/atoms/Text/Typography/Typography';

const EntityBrowserBar = React.forwardRef((props, ref) => {
  const {
    tableAction,
    prevAction,
    nextAction,
    sideBarAction,
    fields,
    center,
    onOpen,
    density,
    ...innerProps
  } = useComponentProps(props, 'EntityBrowserBar', {
    static: ['showScore', 'showSideBar', 'isLoading']
  });

  const profileProvider = useProfile();
  const tableProvider = useTable();

  const client = useAuthClient();
  const context = profileProvider.context?.data;
  const entity = profileProvider.data?.data;

  const universeCollection = useCollectionGet({collectionId: client?.universeCollectionId},
    {enabled: Boolean(client?.universeCollectionId)})?.data;
  const isUniverse = Boolean(context?.universeCollectionId === universeCollection?.collectionId);
  const collection = isUniverse ? universeCollection : context;

  const isLoading = profileProvider.isLoading();

  const prevEntity = tableProvider.prev();
  const nextEntity = tableProvider.next();

  const fieldData = profileProvider.fieldData;
  const showScore = collection?.tagGroups?.some((tg) => tg.hasPoints);
  const showSideBar = Boolean(profileProvider.state?.showSidebar?.['browser']);

  const authorize = useAuthorize();

  const sideBarActionMemo = useMemo(() => ({
    tooltip: 'Show companies',
    icon: showSideBar ? MenuOpen : Menu,
    ActionIconButtonProps: {
      showInactive: true,
    },
    onClick: sideBarAction?.onClick ? ((e) => {
      sideBarAction.onClick(e)
    }) : () => {
      profileProvider?.toggleSidebar?.('browser');
    },
    ...sideBarAction,
  }), [profileProvider, sideBarAction, showSideBar]);

  const tableActionMemo = useMemo(() => {
    return tableAction ? {
      tooltip: 'Open in table',
      icon: List,
      ActionIconButtonProps: {
        showInactive: true,
      },
      ...tableAction,
    } : null
  }, [tableAction]);

  const prevActionMemo = useMemo(() => ({
    tooltip: 'Previous company',
    icon: ArrowBack,
    keyboard: 'Arrow Left',
    auth: (prevEntity && !profileProvider.state.isDirty) ? null : utils.createAuth({attribute: 'system.null'}),
    navigation: !prevAction?.onClick ? {
      to: `../../${prevEntity?.entityId}/browser`,
      keepSearchParams: true
    } : null,
    active: false,
    ActionIconButtonProps: {
      showInactive: true
    },
    onClick: prevAction?.onClick ? ((e) => {
      prevAction.onClick(e, prevEntity)
    }) : null,
    ...utils.filterObject(prevAction, ['onClick'])
  }), [profileProvider.state.isDirty, prevEntity, prevAction]);

  const nextActionMemo = useMemo(() => ({
    tooltip: 'Next company',
    icon: ArrowForward,
    keyboard: 'Arrow Right',
    auth: (nextEntity && !profileProvider.state.isDirty) ? null : utils.createAuth({attribute: 'system.null'}),
    navigation: !nextAction?.onClick ? {
      to: `../../${nextEntity?.entityId}/browser`,
      keepSearchParams: true
    } : null,
    active: false,
    ActionIconButtonProps: {
      showInactive: true
    },
    onClick: nextEntity && nextAction?.onClick ? ((e) => {
      nextAction.onClick(e, nextEntity)
    }) : null,
    ...utils.filterObject(nextAction, ['onClick'])
  }), [profileProvider.state.isDirty, nextEntity, nextAction]);

  const fieldsMemo = useMemo(() => {
    if (entity) {
      const defaultFields = [
        {name: 'total_funding', entity: 'entity'},
        {name: 'date_founded', entity: 'entity'},
        {name: 'employees', entity: 'entity'},
        {
          name: isUniverse ? 'client_analysis_similarity' : 'collection_analysis_similarity',
          label: 'Similarity',
          entity: 'entity'
        }
      ];

      let flds = fields ?? defaultFields;
      flds = flds.map((f) => ({
        ...f,
        readOnly: true,
        FormFieldProps: {
          hiddenHelperText: true,
          autoFocus: false,
          fullWidth: false,
          variant: 'inlineLabel',
          ...f.FormFieldProps
        }
      }));

      flds = utils.initializeFormFields(flds, entity);
      flds.forEach((f) => {
        if (utils.isEmpty(f.initial)) {
          f.initial = f.emptyValue;
        }
      });

      return flds.filter((f) => !utils.isEmpty(f.initial));
    }
  }, [entity, fields, isUniverse]);

  const renderTitle = () => {
    if (!showSideBar) {
      return <EntityHeading className="EntityBrowserBar-heading"
                            entity={entity}
                            canUpdate={true}
                            showLinks={true}
                            isLoading={isLoading} />
    }
  }

  const renderScore = () => {
    const handleClick = (e) => {
      (onOpen ?? tableProvider.openProfile)?.(entity?.entityId, isUniverse ? {activeCard: 'clientTags', hideProfile: true} :
        {activeCard: 'collectionTags', hideProfile: true});

      e.preventDefault();
    }

    const handleEdit = () => {
      (onOpen ?? tableProvider.openProfile)?.(entity?.entityId, isUniverse ? {activeCard: 'clientTags', hideProfile: true} :
        {activeCard: 'collectionTags', hideProfile: true});
    }

    const canUpdate = authorize({attribute: 'collection.entity.field.collectionTags.update'})
    return showScore ? <AnalysisScoreInline className="EntityBrowserBar-scoreBar"
                                            entity={entity}
                                            isLoading={isLoading}
                                            collection={isUniverse ? universeCollection : context}
                                            fieldData={fieldData}
                                            onClick={canUpdate ? handleClick : null}
                                            onEdit={canUpdate ? handleEdit : null}/> : null;
  }

  const renderCenter = () => {
    return <Box className="EntityBrowserBar-center">
      {!isLoading ? <InlineForm className="EntityBrowserBar-center-form"
                                isLoading={isLoading}
                                fields={fieldsMemo}
                                fieldData={fieldData} /> :
        <Box className="EntityBrowserBar-center-loading">
          <Box className="EntityBrowserBar-center-loading-fields">
            <Span min={12} max={12} isLoading={true} />
            <Span min={12} max={12} isLoading={true} />
            <Span min={12} max={12} isLoading={true} />
            <Span min={12} max={12} isLoading={true} />
          </Box>
        </Box>}
      {center}
      {renderScore()}
    </Box>
  }

  const tableIsLoading = tableProvider.isLoading();
  const fetchNextEvent = useEffectEvent(tableProvider.list?.query?.fetchNextPage);
  useEffect(() => {
    if (!tableIsLoading && entity?.entityId && tableProvider.list?.data) {
      if (tableProvider.list?.meta?.resultsCount > tableProvider.list.data.length) {
        const idx = tableProvider.list.data.findIndex((e) => +e.entityId === +entity.entityId);
        if (idx !== -1 && idx > (tableProvider.list.data.length - 3)) {
          fetchNextEvent?.();
        }
      }
    }
  }, [entity?.entityId, tableProvider.list?.meta?.resultsCount, tableProvider.list?.data, tableIsLoading, fetchNextEvent]);

  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  innerProps.title = innerProps.title ?? renderTitle();
  innerProps.leftActions = innerProps.leftActions ?? (
    lgDown ? [] : [sideBarActionMemo]
  );
  innerProps.rightActions = innerProps.rightActions ?? (tableActionMemo ? [tableActionMemo] : [])
    .concat([
      prevActionMemo,
      nextActionMemo
    ]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    showScore,
    showSideBar
  });

  return <StyledEntityBrowserBar ref={ref} {...innerProps}
                                 center={renderCenter()}
                                 $centerColumns={Math.ceil(fieldsMemo?.length / 2)}/>
});

EntityBrowserBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tableAction: PropTypes.object,
  prevAction: PropTypes.object,
  nextAction: PropTypes.object,
  fields: PropTypes.array,
  other: PropTypes.any,
  onOpen: PropTypes.func
};

EntityBrowserBar.defaultProps = {
};

export default EntityBrowserBar;
