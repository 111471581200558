import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell.styles';
import utils from 'helpers/utils';
import FormField from 'components/organisms/Fields/FormField/FormField';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import {withMemo} from 'helpers/wrapper';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';

const FieldTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    fieldData,
    action,
    active,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'FieldTableCell', {
    static: ['canUpdate']
  });

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  const formFields = useMemo(() => {
    return fields.map((field) => {
      const formField = utils.fields2FormFields([field], fieldData)[0];
      const initialValues = utils.fields2InitialValues([formField]);
      return {
        ...formField,
        value: initialValues[formField.name],
        readOnly: true,
        FormFieldProps: {
          ...formField.FormFieldProps,
          size: 'smaller',
          fullWidth: true,
          minWidth: null,
          maxWidth: null,
          variant: 'inlineLabel',
          hiddenLabel: true,
          hiddenHelperText: true,
          hiddenPlaceholder: !Boolean(formField.feedback?.show || formField.info),
          ...([
            constants.formFieldTypes.link,
            constants.formFieldTypes.phone,
            constants.formFieldTypes.email
          ].includes(field.type) ? {showLinkIcon: true} : null)
        }
      };
    });
  }, [fields, fieldData]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    canUpdate: Boolean(itm)
  });

  const feedback = formFields.find((f) => f.feedback?.show)?.feedback;
  const info = formFields.find((f) => f.info)?.info;

  return <StyledFieldTableCell ref={ref} {...innerProps} onClick={handleClick}>
    {feedback?.show ? <Tooltip title={feedback.tooltip}
                               enterDelay={0}
                               placement="bottom">
      <Icon className="FieldTableCell-feedback"
            icon={formFields[0].feedback.icon}
            color={formFields[0].feedback.color ?? 'error'}
            size="smaller"/>
    </Tooltip> : info ? <Tooltip title={info}
                                 enterDelay={0}
                                 variant="emphasis"
                                 placement="bottom">
      <Icon className="FieldTableCell-info"
            icon={InfoOutlined}
            color="light.dark"
            size="smaller"/>
    </Tooltip> : null}
    {formFields
      .filter((formField) => Boolean(formField.feedback?.show || formField.info) || !utils.isEmpty(formField?.value))
      .map((formField, idx) => {
        return <FormField key={idx}
                          className="FieldTableCell-field"
                          field={formField}
                          value={formField.value}
                          {...formField.FormFieldProps}/>
      })}
    {formFields
      .filter((formField) => Boolean(formField.feedback?.show || formField.info) || !utils.isEmpty(formField?.value)).length === 0 ?
      <Box className="FieldTableCell-empty"></Box> : null}
    {itm ? <Icon className="FieldTableCell-edit" icon={itm?.icon} size="tiny"/> : null}
  </StyledFieldTableCell>
}));

FieldTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  active: PropTypes.bool,
  action: PropTypes.object,
  onClick: PropTypes.func
};

FieldTableCell.defaultProps = {
};

export default FieldTableCell;


