import {useRecoilState, useSetRecoilState} from 'recoil';
import {useCallback, useEffect, useLayoutEffect, useMemo} from 'react';
import {useServiceStore} from 'components/organisms/Providers/ServiceProvider/ServiceProvider';
import {useServiceInvalidateQuery, useServiceStatus} from 'stores/hooks/service';
import {useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import utils from 'helpers/utils';

export function useServiceState (path, atom) {
  const store = useServiceStore(path);
  const setRefresh = useSetRecoilState(store.atoms.processRefresh);

  const [state, setState] = useRecoilState(atom);

  // handleCache invalid items in store
  useEffect(() => {
    if (state?.used?.length > 0) {
      setRefresh({items: state?.used});
    }
  }, [state?.used, setRefresh]);

  const stateMemo = useMemo(() => {
    return utils.filterObject(state, 'used');
  }, [state]);

  return [stateMemo, setState];
}

export function useServiceValue (path, atom) {
  const [state] = useServiceState(path, atom);

  return state;
}

export function useServiceSetValue (path, atom) {
  const [, setState] = useServiceState(path, atom);

  return setState;
}

export function useServiceDataById (path, id, context) {
  const store = useServiceStore(path);
  return useServiceValue(path, store.atoms.dataById({id, context}));
}

export function useServiceDataByIdEx (path, id, params, dataType) {
  const store = useServiceStore(path);

  const dt = dataType ?? constants.dataTypes.entity;
  const context = store?.apiPathContext(store.apiPath(dt), dt, {[store.key]: id, ...params});

  return useServiceDataById(path, id, context);
}

export function useServiceSetData (path) {
  const store = useServiceStore(path);

  const setState = useServiceSetValue(path, store.atoms.processData(['setDataById']));

  return useCallback((data, context) => {
    setState({
      data: {meta: {time: new Date()}, data},
      opts: {dataContext: context}
    });
  }, [setState]);
}

export function useServiceSetDataEx (path) {
  const store = useServiceStore(path);

  const setState = useServiceSetData(path);

  return useCallback((data, params, dataType) => {
    const dt = dataType ?? constants.dataTypes.entity;
    const context = data ?
      store?.apiPathContext(store.apiPath(dt), dt, {[store.key]: data[store.key], ...params}) : null;

    setState(data, context);
  }, [store, setState]);
}

export function useServiceDataResult (path, type, keys) {
  const store = useServiceStore(path);
  return useServiceValue(path, store.atoms.processData([type].concat(keys)));
}

export function useServiceData (path, keys, query, options = {}) {
  const store = useServiceStore(path);

  const [data, setData] = useServiceState(path, store.atoms.processData(keys));
  const setStatus = useSetRecoilState(store.atoms.processStatus(keys));
  const invalidateQueryCallback = useServiceInvalidateQuery();

  const status = useServiceStatus(path, keys);

  const storeCallbacks = options.storeCallbacks; // callbacks refs to set from here

  const monitorCallback = useEffectEvent(options.monitorCallback); // monitor changes in service function
  const matchCallback = useEffectEvent(options.matchCallback); // custom match function for this query
  const optionsMemo = useEffectItem({
    invalidateQuery: options.invalidateQuery, // invalidate the query not just refresh, default: false
    refetchInvalidQuery: options.refetchInvalidQuery, // direct refetch when invalidated and active, default: false
    refetchContext: options.refetchContext, // refetch active by lists by context, default: false
    refetchChildren: options.refetchChildren, // propagate refetch context to all children, default: false
    dataStoreKeys: options.dataStoreKeys, // store query keys for refreshing, default: true
    enablePreload: options.enablePreload, // preload entities if possible
    mutation: options.mutation // is mutation
  });

  const applyData = useCallback((response) => {
    let processed, context;
    if (response?.pages) {
      if (response.pages.length > 0) {
        context = response.pages[0].context;
      }

      processed = response.pages.length > 0 ? {
        meta: response.pages[0].response.meta,
        data: response.pages
          .map((page) => page.response)
          .reduce((a, page) => {
            page = page.hasOwnProperty('meta') ? page.data : page;
            page = page?.hasOwnProperty('data') ? page.data : page;
            return a.concat(page ?? []);
          }, [])
      } : null;
    } else {
      context = response?.context;
      processed = response?.response;
    }

    if (processed) {
      setData({
        data: processed,
        opts: {
          dataContext: context,
          ...optionsMemo,
          monitorCallback,
          matchCallback,
          invalidateQueryCallback
        }
      });
    }
  }, [setData, invalidateQueryCallback, monitorCallback, matchCallback, optionsMemo]);

  // handle multiple mutation firing
  storeCallbacks.onSuccess = (data) => {
    applyData(data);

    setStatus({
      success: true,
      error: null,
      settled: true,
      loading: false,
      reloading: false,
      loadingNext: false
    });
  }

  storeCallbacks.onError = (error) => {
    setStatus({
      success: false,
      error,
      settled: true,
      loading: false,
      reloading: false,
      loadingNext: false
    });
  }

  // data changes without a refetch / initialdata
  useEffect(() => {
    if (query.data?.response?.meta?.initialData) {
      applyData(query.data);
      setStatus({
        success: true,
        settled: true,
        loading: false
      });
    }
  }, [query.data, query.isInitialLoading, applyData, setStatus]);

  const enabled = options.enabled !== false;
  const skipData = options.skipStaleData && query.isStale;
  useLayoutEffect(() => {
    const error = enabled ? (query.status === 'error' ? query.error : null) : null;
    const loadingNext = enabled && (query.isFetchingNextPage || query.isFetchingPreviousPage);
    const reloading = enabled && !loadingNext && (query.status === 'success' || Boolean(error)) && (query.fetchStatus === 'fetching');
    const loading = enabled && !loadingNext && !reloading && (query.fetchStatus !== 'idle');

    setStatus({ loading: enabled && (loading || !status.isSettled), reloading, loadingNext, error });
  }, [query.status, query.fetchStatus, query.isSettled, query.isFetchingNextPage,
    query.isFetchingPreviousPage, query.error, status.isSettled, enabled, setStatus]);

  const dataMemo = useEffectItem(!skipData ? data?.data : null);
  const metaMemo = useEffectItem(!skipData ? data?.meta : null);
  return useMemo(() => {
    return {data: dataMemo, meta: metaMemo, status};
  }, [dataMemo, metaMemo, status]);
}
