import React, {useMemo} from 'react'
import {useParams} from 'react-router-dom';
import ProfileProvider from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import constants from 'helpers/constants';
import {
  useEntityGroups,
  useEntityPatch,
  useEntityCallbacks,
  useEntitySections
} from 'services/entity/entity.utils';
import {useEntityTagPatch} from 'services/entity/tag/tag.utils';
import {useEntityTagGroupPatch} from 'services/entity/tagGroup/tagGroup.utils';
import {
  useEntityAdd, useEntityApplyActionsBulk,
  useEntityDelete,
  useEntityEnrich,
  useEntityGet,
  useEntityLogoAdd
} from 'services/entity/entity.hooks';
import PropTypes from 'prop-types';
import {
  useMergeCardDefinitions,
  useMergeFieldData,
  useMergeSectionDefinitions, useMergeView, useProviderDataState,
  useProviderView
} from 'helpers/hooks/utils';
import {useEntityCsiPatch} from 'services/entity/csi/csi.utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import utils from 'helpers/utils';
import {useClientGet} from 'services/client/client.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useEntityTaskCreate} from 'services/entity/comment/comment.utils';
import {useCollectionEntityAssign, useCollectionEntityUnAssign} from 'services/collection/entity/entity.hooks';
import {useClientCustomFieldCache} from 'services/client/customField/customField.hooks';
import {useCollectionGet} from 'services/collection/collection.hooks';

const EntityProfileProvider = (props) => {
  const params = useParams();
  const tableProvider = useTable();
  const { entityId } = {...utils.cleanObject({
    entityId: tableProvider?.selected()?.entityId
  }), ...params, ...props};

  const clientId = useAuthClientId();

  const dataKey = props.dataKey ?? 'entityId';
  const entityView = useProviderView('entity', props.skipView);
  const view = useMergeView(entityView, props.view);

  const client = useClientGet({clientId}, {enabled: Boolean(!props.context && clientId >= 0)});
  const entity = useEntityGet({entityId}, {enabled: Boolean(!props.data && +entityId > 0)});

  const [context, onContext] = useProviderDataState(props.context ?? client);
  const [data, onData] = useProviderDataState(props.data ?? entity);

  const customFields = useClientCustomFieldCache({clientId: client?.data?.clientId},
    {enabled: client?.data?.clientId >= 0});
  const collection = useCollectionGet({collectionId: client?.data?.universeCollectionId},
    {enabled: Boolean(client?.data?.universeCollectionId)});

  const groups = useEntityGroups(view, collection?.data, (customFields.data?.data ?? customFields.data));
  const sections = useEntitySections(view);

  // isLoading anything, is loading something definition related
  const isLoading = props?.isLoading || client.status?.isLoading || entity.status?.isLoading ||
    customFields.status?.isLoading || collection.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading || !customFields.status?.isSuccess || !client.status?.isSuccess ||
    !collection.status?.isSuccess;

  const entityProfileCallbacks = useEntityCallbacks();

  const entityFieldData = useMemo(() => ({
    currency: constants.data.lookup('currencies', client.data?.props?.currency),
    customFields: (customFields.data?.data ?? customFields.data),
    tagGroups: collection?.data?.tagGroups,
    callbacks: entityProfileCallbacks
  }), [customFields.data, collection.data?.tagGroups, entityProfileCallbacks, client.data?.props?.currency]);
  const fieldData = useMergeFieldData(entityFieldData, props.fieldData);
  const cardDefinitions = useMergeCardDefinitions(groups, props.options?.cardDefinitions);
  const sectionDefinitions = useMergeSectionDefinitions(sections, props.options?.sectionDefinitions);

  const options = useMemo(() => {
    const options = {
      cardState: {
        options: {
          name: 'entity',
          type: constants.appState.type.local
        }
      }
    };

    return {
      ...(utils.mergeObjects(options, props.options)),
      cardDefinitions,
      sectionDefinitions
    }
  }, [props.options, cardDefinitions, sectionDefinitions]);

  const update = useEntityPatch();
  const create = useEntityAdd();
  const deleteData = useEntityDelete();
  const enrich = useEntityEnrich();
  const updateTag = useEntityTagPatch();
  const updateTagGroup = useEntityTagGroupPatch();
  const updateCsi = useEntityCsiPatch();
  const createTask = useEntityTaskCreate();
  const assignItems = useCollectionEntityAssign();
  const unAssignItems = useCollectionEntityUnAssign();
  const acceptItems = useCollectionEntityAssign({deleteItem: true});
  const applyActions = useEntityApplyActionsBulk();
  const createLogo = useEntityLogoAdd();

  const updaters = useMemo(() => {
    return {
      createData: (data) => {
        return create.mutation.mutateAsync(data);
      },
      deleteData: (data) => {
        return deleteData.mutation.mutateAsync(data);
      },
      updateData: update,
      enrichData: (data) => {
        return enrich.mutation.mutateAsync(data);
      },
      updateTag: updateTag,
      updateTagGroup: updateTagGroup,
      updateCsi: updateCsi,
      assignItems: (data) => {
        return assignItems.mutation.mutateAsync(data);
      },
      unAssignItems: (data) => {
        return unAssignItems.mutation.mutateAsync(data);
      },
      acceptItems: (data) => {
        return acceptItems.mutation.mutateAsync(data);
      },
      applyActions: (data) => {
        return applyActions.mutation.mutateAsync(data);
      },
      createTask: (entity, data) => {
        return createTask([entity], null, data);
      },
      createLogo: (data) => {
        return createLogo.mutation.mutateAsync(data);
      },
      ...props.updaters
    }
  }, [update, updateTag, updateTagGroup, updateCsi, createTask,
    create.mutation, deleteData.mutation, enrich.mutation, applyActions.mutation,
    assignItems.mutation, unAssignItems.mutation, acceptItems.mutation,
    createLogo.mutation, props.updaters]);

  return <ProfileProvider {...props}
                          view={view}
                          dataKey={dataKey}
                          data={data}
                          onData={props.onData ?? onData}
                          context={context}
                          onContext={props.onContext ?? onContext}
                          fieldData={fieldData}
                          options={options}
                          updaters={updaters}
                          isDefinitionsLoading={isDefinitionsLoading}
                          isLoading={isLoading}>
    {props.children}
  </ProfileProvider>
};

EntityProfileProvider.propTypes = {
  view: PropTypes.object,
  data: PropTypes.object,
  context: PropTypes.object,
  onData: PropTypes.func,
  onContext: PropTypes.func,
  options: PropTypes.object,
  updaters: PropTypes.object,
  isLoading: PropTypes.bool
}

export default EntityProfileProvider;
