import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useServiceStore} from 'components/organisms/Providers/ServiceProvider/ServiceProvider';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useServiceApiQuery} from 'stores/hooks/query';
import logger from 'helpers/logger';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {useAuth, useAuthReset} from 'services/auth/auth.utils';
import {useInvalidateQuery} from 'helpers/hooks/query';

export function useServiceInvalidateQuery () {
  const auth = useAuth();
  const invalidateKeysRef = useRef([]);
  const invalidateQuery = useInvalidateQuery();

  const invalidate = useCallback(() => {
    const paths = invalidateKeysRef.current.reduce((o, {store, keys}) => {
      o[store.path] = o[store.path] ?? {store, keys: []};
      keys.forEach((dk) => {
        if (!o[store.path].keys.find((pDk) => dk.hash === pDk.hash)) {
          o[store.path].keys.push(dk);
        }
      });

      return o;
    }, {});

    Object.keys(paths).forEach((k) => {
      paths[k].keys.forEach((dk) => {
        if (dk.remove) {
          invalidateQuery([k].concat(dk.keys), dk.exact, dk.refetch, dk.remove);
        } else if (paths[k].store.canActivelyRefresh(auth?.loggedIn)) {
          invalidateQuery([k].concat(dk.keys), dk.exact, dk.refetch);
        }
      });
    })

    invalidateKeysRef.current = [];
  }, [invalidateQuery, auth?.loggedIn]);

  const debouncedInvalidate = useMemo(() => {
    return utils.debounce(() => {
      invalidate();
    }, constants.debounce.minimal);
  }, [invalidate]);

  return useCallback((store, keys) => {
    invalidateKeysRef.current = invalidateKeysRef.current.concat([{
      store,
      keys
    }]);

    debouncedInvalidate();
  }, [debouncedInvalidate]);
}

export function useServiceInvalidate (path) {
  const store = useServiceStore(path);
  const invalidateQueryCallback = useServiceInvalidateQuery();
  const processData = useSetRecoilState(store.atoms.processData(['invalidate']));

  return useCallback((entity, servedAt, contextIds, invalidIds, addedIds, removedIds) => {
    // invalidate all older than the servedAt
    if (invalidIds?.length > 0) {
      processData({
        data: {
          meta: {
            serverTime: servedAt,
            time: new Date(),
            context: {
              ...(store.parent?.key ? {
                [store.parent.key]: contextIds?.[0]
              } : null),
              $store: {
                ...(store.parent?.listKey ? {
                  [store.parent.listKey]: contextIds
                } : null),
                $contextIds: contextIds
              }
            },
            invalidate: {
              entity,
              invalidIds,
              priority: false,
            }
          }
        },
        opts: {
          refetchContext: false,
          invalidateQueryCallback
        }
      });
    }

    if (addedIds?.length > 0 || removedIds?.length > 0) {
      processData({
        data: {
          meta: {
            serverTime: servedAt,
            time: new Date(),
            context: {
              ...(store.parent?.key ? {
                [store.parent.key]: contextIds?.[0]
              } : null),
              $store: {
                ...(store.parent?.listKey ? {
                  [store.parent.listKey]: contextIds
                } : null),
                $contextIds: contextIds
              }
            },
            invalidate: {
              entity,
              invalidIds: addedIds,
              removedIds,
              priority: false
            }
          }
        },
        opts: {
          refetchContext: true,
          invalidateQueryCallback
        }
      });
    }
  }, [invalidateQueryCallback, processData, store.parent?.key, store.parent?.listKey]);
}

export function useServiceRefresh (path) {
  const store = useServiceStore(path);
  const [entityRefresh, setEntityRefresh] = useState({1: {}, 2: {}, 3: {}});
  const [listRefresh, setListRefresh] = useState({1: {}, 2: {}, 3: {}});

  const refresh = useRecoilValue(store.atoms.processRefresh);

  const auth = useAuth();

  useServiceApiQuery (path, 'refresh',
    {dataType: constants.dataTypes.entity, slot: 1, ...entityRefresh[1]}, {
      ...constants.queryOptions.runOnceFresh,
      dataStoreKeys: false,
      onSettled: () => {
        setTimeout(() => {
          setEntityRefresh((current) => ({...current, 1: {}}));
        }, constants.debounce.cooldown);
      },
      enabled: entityRefresh[1]?.ids?.length > 0
    });

  useServiceApiQuery (path, 'refresh',
    {dataType: constants.dataTypes.entity, slot: 2, ...entityRefresh[2]}, {
      ...constants.queryOptions.runOnceFresh,
      dataStoreKeys: false,
      onSettled: () => {
        setTimeout(() => {
          setEntityRefresh((current) => ({...current, 2: {}}));
        }, constants.debounce.cooldown);
      },
      enabled: entityRefresh[2]?.ids?.length > 0
    });

  useServiceApiQuery (path, 'refresh',
    {dataType: constants.dataTypes.entity, slot: 3, ...entityRefresh[3]}, {
      ...constants.queryOptions.runOnceFresh,
      dataStoreKeys: false,
      onSettled: () => {
        setTimeout(() => {
          setEntityRefresh((current) => ({...current, 3: {}}));
        }, constants.debounce.cooldown);
      },
      enabled: entityRefresh[3]?.ids?.length > 0
    });

  useServiceApiQuery (path, 'refresh',
    {dataType: constants.dataTypes.list, slot: 1, ...listRefresh[1]}, {
      ...constants.queryOptions.runOnceFresh,
      dataStoreKeys: false,
      onSettled: () => {
        setTimeout(() => {
          setListRefresh((current) => ({...current, 1: {}}));
        }, constants.debounce.cooldown);
      },
      enabled: listRefresh[1]?.ids?.length > 0
    });

  useServiceApiQuery (path, 'refresh',
    {dataType: constants.dataTypes.list, slot: 2, ...listRefresh[2]}, {
      ...constants.queryOptions.runOnceFresh,
      dataStoreKeys: false,
      onSettled: () => {
        setTimeout(() => {
          setListRefresh((current) => ({...current, 2: {}}));
        }, constants.debounce.cooldown);
      },
      enabled: listRefresh[2]?.ids?.length > 0
    });

  useServiceApiQuery (path, 'refresh',
    {dataType: constants.dataTypes.list, slot: 3, ...listRefresh[3]}, {
      ...constants.queryOptions.runOnceFresh,
      dataStoreKeys: false,
      onSettled: () => {
        setTimeout(() => {
          setListRefresh((current) => ({...current, 3: {}}));
        }, constants.debounce.cooldown);
      },
      enabled: listRefresh[3]?.ids?.length > 0
    });

  const doRefresh = useCallback((refresh, dataType, setRefresh) => {
    const update = (time, refreshStore) => {
      const getInfo = (slots) => {
        if (store.canActivelyRefresh(auth?.loggedIn)) {
          const ids = (refreshStore || [])
            .map((s) => ({id: s.id, hash: s.context.$store.hash}))
            .filter((id) => {
              return !Object.keys(slots).some((k) => {
                return slots[k]?.hashes?.find((sId) => sId.id === id.id && sId.hash === id.hash);
              });
            })
            .reduce((a, id) => {
              if (a.length === 0 || a.find((sId) => sId.hash === id.hash)) {
                a.push(id);
              }
              return a;
            }, [])
            .slice(0, (
              (dataType === constants.dataTypes.entity && store.options.separateEntityData) ?
                store.options.refreshEntityBatchSize : store.options.refreshListBatchSize
            ));

          if (ids.length > 0) {
            return {
              ids: ids.map((id) => id.id),
              hashes: ids,
              time: time,
              startedAt: new Date(),
              ...refreshStore[0].context
            };
          }
        }

        return null;
      }

      setRefresh((current) => {
        const info = getInfo(current);
        if (info) {
          const emptySlotKey = Object.keys(current).find((k) => {
            const slot = current[k];

            return !slot?.startedAt || slot.startedAt.getTime() < (Date.now() - store.options.refreshResetTime);
          })

          if (emptySlotKey) {
            return {...current, [emptySlotKey]: info};
          }
        }
        return current;
      });
    }

    const priorityStores = refresh[dataType].cache.filter((r) => r.priority);
    if (priorityStores.length > 0) {
      return utils.observeTimeout(() => {
        update(refresh[dataType].time, priorityStores);
      }, constants.debounce.minimal);
    } else if (refresh[dataType].cache?.length > 0) {
      return utils.observeTimeout(() => {
        update(refresh[dataType].time, refresh[dataType].cache);
      }, constants.debounce.refresh);
    }
  }, [store, auth?.loggedIn]);

  useEffect(() => {
    return doRefresh(refresh, constants.dataTypes.entity, setEntityRefresh);
  }, [refresh, doRefresh, entityRefresh]);

  useEffect(() => {
    return doRefresh(refresh, constants.dataTypes.list, setListRefresh);
  }, [refresh, doRefresh, listRefresh]);
}

export function useServiceStatus (path, keys = null) {
  const store = useServiceStore(path);

  const [status, setStatus] = useRecoilState(store.atoms.processStatus(keys ?? 'all'));

  const hasKeys = Boolean(keys);

  const hasByTypes = useCallback((types, has = true) => {
    if (hasKeys) {
      return types.some((type) => !!status[type]?.value);
    } else {
      return types.some((type) => {
        const len = status.filter((s) => {
          return !!s[type]?.value;
        }).length;
        return (has ? len > 0 : len > 0 && len === status.length);
      });
    }
  }, [status, hasKeys]);

  const getByType = useCallback((type) => {
    if (hasKeys) {
      return status[type]?.value;
    } else {
      return status.filter((s) => {
        return !!s[type]?.value;
      }).map((s) => s[type].value);
    }
  }, [status, hasKeys]);

  const clearByTypes = useCallback((types) => {
    const diff = {};
    types.forEach((type) => {
      diff[type] = null
    });
    setStatus(diff);
  }, [setStatus]);

  return useMemo(() => {
    return {
      isLoading: hasByTypes(['loading'], true),
      clearLoading: () => clearByTypes(['loading']),
      isReloading: hasByTypes(['reloading'], true),
      clearReloading: () => clearByTypes(['reloading']),
      isLoadingNext: hasByTypes(['loadingNext'], true),
      clearLoadingNext: () => clearByTypes(['loadingNext']),
      isBusy: hasByTypes(['loading', 'reloading', 'loadingNext'], true),
      clearBusy: () => clearByTypes(['loading', 'reloading', 'loadingNext']),
      isSettled: hasByTypes(['settled'], false),
      clearSettled: () => clearByTypes(['settled']),
      isSuccess: hasByTypes(['success'], false),
      clearSuccess: () => clearByTypes(['success']),
      hasError: hasByTypes(['error'], true),
      error: getByType('error'),
      clearError: () => clearByTypes(['error']),
      clearAll: () => clearByTypes(['loading', 'reloading', 'loadingNext', 'settled', 'success', 'error'])
    };
  }, [hasByTypes, getByType, clearByTypes]);
}

export function useServiceAuthorize (path) {
  const store = useServiceStore(path);
  const setReset = useSetRecoilState(store.atoms.processReset);
  const invalidateQueryCallback = useServiceInvalidateQuery();

  const reset = useCallback((type) => {
    logger.trace('Resetting service:', type, store.name);

    setReset({type, invalidateQueryCallback});
  }, [store.name, invalidateQueryCallback, setReset]);

  useAuthReset(reset);
}

