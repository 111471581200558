import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';
import {rgba} from 'polished';

const StyledListField = styled(FormControl)`
  &.ListField-fullHeight {
    height: 100%;

    .ListField-container {
      height: 100%;

      .ListField-list {
        height: 100%;
      }
    }
  }
  
  .TextField {
    margin-bottom: ${props => props.theme.layout(2)};
    .CircularProgress {
      margin-top: ${props => props.theme.layout(0)};
      margin-right: ${props => props.theme.layout(-2.5)};
      margin-left: ${props => props.theme.layout(8)};
      &.second {
        margin-right: ${props => props.theme.layout(-13)};
      }
      > * {
        color: ${props => props.theme.property('palette.text.secondary')};

        width: ${props => props.theme.layout(20)} !important;
        height: ${props => props.theme.layout(20)} !important;
      }
    }
  }
  
  .ListField {
    &-list {
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .MuiFormControlLabel-root {
        width: calc(100% + ${props => props.theme.layout(6)});
        gap: ${props => props.theme.layout('0.5sp')};
        margin-left: ${props => props.theme.layout(-6)};
        margin-right: 0;
      }
      .MuiFormControlLabel-label {
        display: inline-flex;
        align-self: center;
        width: 100%;
      }

      .Checkbox, .Radio {
        align-self: baseline;
        &:not(.Mui-checked) {
          color: ${props => props.theme.property('palette.input.outlined.enabledBorder')};
        }
      }
    }
    
    &-empty {
      width: 100%;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }
    
    &-readOnly {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  // see also autoCompleteField
  .ListField-readOnly {
    .ListField-readOnly-chips {
      &.ChipList {
        .Chip {
          margin: 0;
        }
      }
    }

    &:not(.ListField-readOnly-chips) {
      display: flex;
      gap: ${props => props.theme.layout('0.5sp')};
      align-items: center;
      width: 100%;

      &:has(.placeholder) {
        color: ${props => props.theme.property('palette.text.disabled')};
      }

      > .Icon {
        align-self: flex-start;
      }
      > *:not(.Icon, .InputAdornment) {
        flex-grow: 1;
        width: 1px;
        max-width: fit-content;
      }

      .MuiInputAdornment-root {
        margin: 0;
        height: unset;
        min-height: unset;
        max-height: unset;
        color: unset;
        font: inherit;

        .Icon {
          color: ${props => props.theme.property('palette.action.active')};
        }
        
        .Link {
          text-decoration: none !important;
          &:has(.Icon) {
            line-height: 0;
          }
        }
        
        > * {
          font: inherit;
          color: unset;
        }
      }
    }
  }

  &.ListField-multiple {
    .ListField {
      &-list {
        .MuiFormControlLabel-root {
          width: calc(100% + ${props => props.theme.layout(8)});
          margin-left: ${props => props.theme.layout(-8)};
        }
      }
    }
          
    .ListField-readOnly {
      &:not(.ListField-readOnly-chips) {
        display: flex;
        gap: ${props => props.theme.layout('1sp')};
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        
        > .Box {
          display: flex;
          gap: ${props => props.theme.layout('1sp')};
          align-items: center;
          width: 100%;
        }
      }
    }
  }
  
  
  // sizes
  &.ListField-size-medium {
    .CircularProgress {
      margin-top: ${props => props.theme.layout(0.5)};
      margin-right: ${props => props.theme.layout(-1)};
      &.second {
        margin-right: ${props => props.theme.layout(-15)};
      }
      > * {
        width: ${props => props.theme.layout(20)} !important;
        height: ${props => props.theme.layout(20)} !important;
      }
    }
  }
  
  &.ListField-size-smaller {
    .ListField-list {
      .MuiFormControlLabel-root {
        gap: ${props => props.theme.layout('0.25sp')};
      }
      .MuiFormControlLabel-label {
        font-size: ${props => props.theme.fontSize(14)};
      }
    }

    .CircularProgress {
      margin-top: ${props => props.theme.layout(-0.5)};
      margin-right: ${props => props.theme.layout(4)};
      &.second {
        margin-right: ${props => props.theme.layout(-13)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledListField.propTypes = {
  theme: PropTypes.object
};

StyledListField.defaultProps = {
}

export default StyledListField;
