import {useCallback, useEffect, useMemo, useState} from 'react';
import utils from 'helpers/utils';
import {useAuthTeamId} from 'services/auth/auth.utils';
import {
  useClientUserAddPasses,
  useClientUserDelete,
  useClientUserList,
  useClientUserUpdate
} from 'services/client/user/user.hooks';
import {useClientTeamMemberOptions} from 'services/client/team/team.utils';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import constants from 'helpers/constants';
import {useClientUpdate} from 'services/client/client.hooks';
import {useClientCurrencyConversion} from 'services/client/currency/currency.utils';
import {
  useConnectionExternalFields,
  useConnectionExternalLists, useConnectionExternalTeams,
  useConnectionInternalFields,
  useConnectionInternalLists, useConnectionInternalTeams
} from 'services/connection/connection.hooks';
import {useClientTeamList} from 'services/client/team/team.hooks';

export function processClient (client) {
  const getDealflowGroups = () => {
    const statusOptions = client?.statusOptions;
    const groups = [];

    if (statusOptions) {
      for (let statusGroup of statusOptions) {
        if (statusGroup.statuses?.length > 0) {
          statusGroup = utils.camelcase(statusGroup);
          const group = {
            statusGroupId: statusGroup.statusGroupId,
            groupId: statusGroup.statusGroupId,
            name: statusGroup.groupName,
            label: statusGroup.groupName,
            short: statusGroup.short,
            icon: statusGroup.icon,
            description: statusGroup.description,
            position: statusGroup.position,
            values: [],
            color: statusGroup.color,
            statuses: []
          };
          for (let status of statusGroup.statuses) {
            status = utils.camelcase(status);
            const st = {
              value: status.statusId,
              statusId: status.statusId,
              label: status.statusName,
              color: statusGroup.color,
              name: status.statusName,
              short: statusGroup.short,
              icon: statusGroup.icon,
              group: statusGroup.groupName,
              groupPosition: statusGroup.position,
              groupColor: statusGroup.color,
              position: status.position,
              groupId: statusGroup.statusGroupId,
            };
            group.values.push(st.statusId);
            group.statuses.push(st);
          }
          groups.push(group);
        }
      }
    }

    groups.forEach((gr) => gr.statuses.sort((a, b) => a.position - b.position));
    return groups.sort((a, b) => a.position - b.position);
  }

  client = utils.camelcase(client);

  const tagGroups = utils.camelcaseEx(client.tagsGroups ?? []);

  return {
    ...utils.filterObject(client, ['tagsGroups'], true),
    tagGroups: tagGroups,
    dealflowGroups: getDealflowGroups(),
    props: utils.camelcase(client?.props),
    users: utils.camelcaseEx(client?.users)
  }
}

export function useClientPatch () {
  const clientUpdate = useClientUpdate();

  return useCallback((client, profile, props) => {
    profile = (Object.keys(profile).length > 1) ? utils.changedFormFields(client, profile) : profile;
    props = (Object.keys(props).length > 1) ? utils.changedFormFields(client.props, props) : props;

    if (!utils.isEmpty(profile) || !utils.isEmpty(props)) {
      return clientUpdate.mutation.mutateAsync({
        clientId: client.clientId,
        ...utils.underscore(profile),
        props: utils.underscore(props)
      });
    } else {
      return Promise.resolve();
    }
  }, [clientUpdate.mutation]);
}

export function useClientCollaboratorCreate () {
  const userPasses = useClientUserAddPasses();

  return useCallback((clientId, userIds) => {
    return userPasses.mutation.mutateAsync({
      clientId,
      userIds
    });
  }, [userPasses.mutation]);
}

export function useClientCollaboratorPatch () {
  const userUpdate = useClientUserUpdate();

  return useCallback((clientId, user) => {
    return userUpdate.mutation.mutateAsync({
      clientId,
      userId: user.userId,
      ...utils.underscore(user)
    });
  }, [userUpdate.mutation]);
}

export function useClientCollaboratorDelete () {
  const userDelete = useClientUserDelete();

  return useCallback((clientId, userId) => {
    return userDelete.mutation.mutateAsync({
      clientId,
      userId
    });
  }, [userDelete.mutation]);
}

export function useClientDealflowGroup (groupId) {
  const client = useAuthClient();
  const groups = client?.dealflowGroups;

  return groups?.find((g) => +g.groupId === +groupId);
}

export function useClientDealflowStatus (statusId) {
  const client = useAuthClient();
  const groups = client?.dealflowGroups;

  return useMemo(() => {
    let status = null;
    groups?.some((gr) => {
      status = gr.statuses.find((st) => +st.statusId === +statusId);
      return Boolean(status);
    });
    return status;
  }, [groups, statusId]);
}

export function useClientDealflowInGroups (dealflow, totalCount = 0, addNoDealflow = false) {
  const client = useAuthClient();
  const dealflowGroups = client?.dealflowGroups;

  return useMemo(() => {
    if (dealflow && dealflowGroups) {
      const noDealflowGroup = dealflowGroups.find((g) => g.statuses.find((status) => +status.statusId === 0));

      let inDealflowCount = 0;
      const groups = dealflow.reduce((a, df) => {
        const group = dealflowGroups.find((g) => g.statuses.find((status) => +status.statusId === +df.status));
        if (group) {
          let groupData = a.find((g) => +g.groupId === group.groupId);
          if (!groupData) {
            groupData = {
              ...group,
              statuses: (group.statuses ?? []).map((status) => ({
                ...status,
              })),
              relative: 0,
              count: 0
            };
            a.push(groupData);
          }

          const statusIdx = groupData.statuses.findIndex((g) => +g.statusId === df.status);

          groupData.statuses[statusIdx] = {
            ...(groupData.statuses[statusIdx]),
            count: df.count
          };
          groupData.relative += df.count;
          groupData.count += df.count;
          inDealflowCount += df.count;
        }
        return a;
      }, []);

      if (noDealflowGroup && addNoDealflow && (inDealflowCount < totalCount || totalCount === 0)) {
        const noDealflow = {
          ...noDealflowGroup,
          noDealflow: true,
          count: Math.max(0, totalCount - inDealflowCount),
          relative: inDealflowCount > 0 ?
            Math.min(Math.max(0, totalCount - inDealflowCount), inDealflowCount * 1.5) :
            Math.max(totalCount - inDealflowCount, 1),
          statuses: [{...noDealflowGroup?.statuses?.[0], count: Math.max(0, totalCount - inDealflowCount)}]
        };

        groups.splice(0, 0, noDealflow);
      }

      return groups;
    } else {
      return null;
    }
  }, [dealflowGroups, dealflow, totalCount, addNoDealflow]);
}

export function useDashboardCards (view) {
  return useMemo(() => {
    let cardDefs = [];
    if (!view || view) { // any view
      cardDefs = cardDefs.concat([
        {
          name: 'periodUpdate',
          auth: {
            read: utils.createAuth({attribute: 'dashboard.periodUpdate.read'}),
            update: utils.createAuth({attribute: 'dashboard.periodUpdate.update'}),
          }
        },
        {
          name: 'tasks',
          auth: {
            read: utils.createAuth({attribute: 'dashboard.tasks.read'}),
            update: utils.createAuth({attribute: 'dashboard.tasks.update'}),
          }
        },
        {
          name: 'recentCollections',
          auth: {
            read: utils.createAuth({attribute: 'dashboard.recentCollections.read'}),
            update: utils.createAuth({attribute: 'dashboard.recentCollections.update'}),
          }
        },
        {
          name: 'dealflow',
          auth: {
            read: utils.createAuth({attribute: 'dashboard.dealflow.read'}),
            update: utils.createAuth({attribute: 'dashboard.dealflow.update'}),
          }
        },
        {
          name: 'themeBreakdown',
          auth: {
            read: utils.createAuth({attribute: 'dashboard.themeBreakdown.read'}),
            update: utils.createAuth({attribute: 'dashboard.themeBreakdown.update'}),
          }
        },
        {
          name: 'insights',
          auth: {
            read: utils.createAuth({attribute: 'dashboard.insights.read'}),
            update: utils.createAuth({attribute: 'dashboard.insights.update'}),
          }
        }
      ]);

      return cardDefs;
    }
  }, [view]);
}

export function useClientGroups (view, client, collection) {
  return useMemo(() => {
    const groups = [];

    Object.keys(constants.client.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.client.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('client', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => {
              return {
                ...f,
                entity: 'client',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute: `client.field.${f.name}.read`}),
                  update: utils.createAuth({attribute: `client.field.${f.name}.update`})
                },
                required: f.required ?? false
              };
            }) ?? []
          });
        }
      }
    });

    if (utils.isDefined(client?.dealflowGroups)) {
      client?.dealflowGroups.forEach((dg) => {
        const noDealflowGroup = Boolean(dg.statuses.find((status) => +status.statusId === 0));

        if (!noDealflowGroup) {
          groups.push({
            name: `statusGroup-${dg.groupId}`,
            data: dg,
            position: +dg.position,
            entity: 'client',
            auth: {
              read: utils.createAuth({attribute: 'client.statusGroup.read', meta: {collection, statusGroup: dg}}),
              update: utils.createAuth({attribute: 'client.statusGroup.update', meta: {collection, statusGroup: dg}})
            },
            title: dg.name,
            DialogProps: {
              variant: 'basic',
              width: 700,
              minWidth: 700,
              height: 592,
              minHeight: 592,
              showHeader: false,
              wrapContent: false
            }
          });
        }
      });
    }

    let position = groups.reduce((m, g) => g.name.startsWith('statusGroup-') ? Math.max(g.position, m) : m, 0);
    groups.push({
      name: `statusGroup-new-${position}`, // force new card
      data: {},
      auth: {
        read: utils.createAuth({attribute: 'client.statusGroup.create', meta: {collection}}),
        update: utils.createAuth({attribute: 'client.statusGroup.create', meta: {collection}})
      },
      position: position,
      title: 'New deal flow group',
      DialogProps: {
        variant: 'basic',
        width: 700,
        minWidth: 700,
        height: 592,
        minHeight: 592,
        showHeader: false,
        wrapContent: false
      }
    });

    if (utils.isDefined(collection?.tagGroups)) {
      collection.tagGroups.forEach((tg) => {
        groups.push({
          name: `tagGroup-${tg.groupId}`,
          data: tg,
          position: +tg.pos,
          entity: 'client',
          auth: {
            read: utils.createAuth({attribute: 'client.tagGroup.read', meta: {collection, tagGroup: tg}}),
            update: utils.createAuth({attribute: 'client.tagGroup.update', meta: {collection, tagGroup: tg}})
          },
          title: tg.name,
          DialogProps: {
            variant: 'basic',
            width: 900,
            minWidth: 900,
            height: 692,
            minHeight: 692,
            showHeader: false,
            wrapContent: false
          }
        });
      });
    }

    position = groups.reduce((m, g) => g.name.startsWith('tagGroup-') ? Math.max(g.position, m) : m, 0);
    groups.push({
      name: `tagGroup-new-${position}`, // force new card
      data: {},
      auth: {
        read: utils.createAuth({attribute: 'client.tagGroup.create', meta: {collection}}),
        update: utils.createAuth({attribute: 'client.tagGroup.create', meta: {collection}})
      },
      position: position,
      title: 'New category',
      DialogProps: {
        variant: 'basic',
        width: 900,
        minWidth: 900,
        height: 692,
        minHeight: 692,
        showHeader: false,
        wrapContent: false
      }
    });

    if (utils.isDefined(collection?.projectSources)) {
      const sources = collection?.projectSources;
      let position = groups.reduce((m, g) => g.name.startsWith('source-') ? Math.max(g.position, m) : m, 0);

      sources.forEach((s, idx) => {
        groups.push({
          name: `source-${s.sourceId ?? idx}`,
          data: s,
          auth: {
            read: utils.createAuth({attribute: 'client.source.read', meta: {collection, source: s}}),
            update: utils.createAuth({attribute: 'client.source.update', meta: {collection, source: s}})
          },
          position: position + idx,
          DialogProps: {
            fullWidth: true,
            maxWidth: 884
          }
        });
      });
    }

    position = groups.reduce((m, g) => g.name.startsWith('source-') ? Math.max(g.position, m) : m, 0);
    groups.push({
      name: `source-new-${position + 1}`, // force new card
      data: {},
      auth: {
        read: utils.createAuth({attribute: 'client.source.create', meta: {collection}}),
        update: utils.createAuth({attribute: 'client.source.create', meta: {collection}})
      },
      position: position + 1,
      title: 'New source',
      anchor: 'right',
    });

    if (constants.data.services.length > 0) {
      const services = constants.data.services;
      let position = groups.reduce((m, g) => g.name.startsWith('service-') ? Math.max(g.position, m) : m, 0);

      services.forEach((s, idx) => {
        s = {...s, name: s.label, serviceId: s.value};

        const extended = !s?.always && !s?.plan;
        groups.push({
          name: `service-${s.value}`,
          title: s.label,
          data: s,
          auth: {
            read: utils.createAuth({attribute: 'client.service.read', meta: {collection, service: s}}),
            update: utils.createAuth({attribute: 'client.service.update', meta: {collection, service: s}})
          },
          position: position + idx,
          DialogProps: {
            variant: 'basic',
            showHeader: extended,
            showFooter: extended,
            autoFocus: extended,
            width: extended ? 944 : null,
            minWidth: extended ? 944 : 0,
            height: extended ? 692 : null,
            minHeight: extended ? 692 : null
          }
        });
      });
    }

    let planGroupIndex = groups.findIndex((g) => g.name === 'plan');
    let planGroup = groups[planGroupIndex];
    groups.splice(planGroupIndex, 1);

    if (constants.data.plans.length > 0) {
      const plans = constants.data.plans;
      let position = groups.reduce((m, g) => g.name.startsWith('plan-') ? Math.max(g.position, m) : m, 0);

      plans.forEach((p, idx) => {
        p = {...p, name: p.label, planId: p.value};

        groups.push({
          ...planGroup,
          name: `plan-${p.value}`,
          title: p.label,
          data: p,
          auth: {
            read: utils.createAuth({attribute: 'client.plan.read', meta: {collection, plan: p}}),
            update: utils.createAuth({attribute: 'client.plan.update', meta: {collection, plan: p}})
          },
          position: position + idx
        });
      });
    }

    if (utils.isDefined(client?.props?.connections)) {
      const connections = client?.props?.connections;
      let position = groups.reduce((m, g) => g.name.startsWith('connection-') ? Math.max(g.position, m) : m, 0);

      connections.forEach((c, idx) => {
        groups.push({
          name: `connection-${c.connectionId ?? idx}`,
          data: c,
          auth: {
            read: utils.createAuth({attribute: 'client.connection.read', meta: {collection, connection: c}}),
            update: utils.createAuth({attribute: 'client.connection.update', meta: {collection, connection: c}})
          },
          position: position + idx,
          DialogProps: {
            width: 900,
            minWidth: 900,
            height: 692,
            minHeight: 692,
          }
        });
      });
    }

    position = groups.reduce((m, g) => g.name.startsWith('connection-') ? Math.max(g.position, m) : m, 0);
    groups.push({
      name: `connection-new-${position + 1}`, // force new card
      data: {},
      auth: {
        read: utils.createAuth({attribute: 'client.connection.create', meta: {collection}}),
        update: utils.createAuth({attribute: 'client.connection.create', meta: {collection}})
      },
      position: position + 1,
      title: 'New connection',
      anchor: 'right',
      DialogProps: {
        width: 900,
        minWidth: 900,
        height: 692,
        minHeight: 692,
      }
    });

    return groups;
  }, [view, collection, client]);
}

export function useClientCallbacks (addTeam = true) {
  const teamId = useAuthTeamId();
  const client = useAuthClient();
  const teamMemberOptions = useClientTeamMemberOptions(teamId, true, addTeam);
  const dealLeaderOptions = useClientTeamMemberOptions(teamId, Boolean(client?.props?.proxyCanBeDealLeader), false);

  const dealflowGroups = client?.dealflowGroups;
  const dealflowStatuses = useMemo(() => {
    if (dealflowGroups) {
      return dealflowGroups.reduce((a, gr) => {
        return a.concat(gr.statuses.map((status) => ({
          status,
          label: status.name,
          value: +status.statusId
        })));
      }, [])
    } else {
      return [];
    }
  }, [dealflowGroups]);

  const [dealflowStatusSearch, setDealflowStatusSearch] = useState({});
  const dealflowStatusCurrent = dealflowStatusSearch[Object.keys(dealflowStatusSearch)[0]];
  const dealflowStatusEnabled = Boolean(dealflowStatusCurrent?.callback);

  const [teamMemberSearch, setTeamMemberSearch] = useState({});
  const teamMemberCurrent = teamMemberSearch[Object.keys(teamMemberSearch)[0]];
  const teamMemberEnabled = Boolean(teamMemberCurrent?.callback);

  const [dealLeaderSearch, setDealLeaderSearch] = useState({});
  const dealLeaderCurrent = dealLeaderSearch[Object.keys(dealLeaderSearch)[0]];
  const dealLeaderEnabled = Boolean(dealLeaderCurrent?.callback);

  const [currencyListSearch, setCurrencyListSearch] = useState({});
  const currencyListCurrent = currencyListSearch[Object.keys(currencyListSearch)[0]];
  const currencyListEnabled = Boolean(currencyListCurrent?.callback);
  const currencyConversion = useClientCurrencyConversion(currencyListCurrent?.search);

  const [teamListSearch, setTeamListSearch] = useState({});
  const teamListCurrent = teamListSearch[Object.keys(teamListSearch)[0]];
  const teamListEnabled = Boolean(teamListCurrent?.callback);
  const teamList = useClientTeamList({
    clientId: client?.clientId,
    search: teamListCurrent?.search,
    filter: utils.addFilter((utils.toArray(teamListCurrent?.ids, true).length > 0) ? [{
      id: 'teamIds',
      value: utils.toArray(teamListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, teamListCurrent?.filter)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: teamListEnabled
  });

  const [userListSearch, setUserListSearch] = useState({});
  const userListCurrent = userListSearch[Object.keys(userListSearch)[0]];
  const userListEnabled = Boolean(userListCurrent?.callback);
  const userList = useClientUserList({
    clientId: client?.clientId,
    search: userListCurrent?.search,
    filter: utils.addFilter((utils.toArray(userListCurrent?.ids, true).length > 0) ? [{
      id: 'userIds',
      value: utils.toArray(userListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, userListCurrent?.filter)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: userListEnabled
  });

  const [internalTeamsSearch, setInternalTeamsSearch] = useState({});
  const internalTeamsCurrent = internalTeamsSearch[Object.keys(internalTeamsSearch)[0]];
  const internalTeamsEnabled = Boolean(internalTeamsCurrent?.callback);
  const internalTeams = useConnectionInternalTeams({
    filter: utils.addFilter((utils.toArray(internalTeamsCurrent?.ids, true).length > 0) ? [{
      id: 'teamIds',
      value: utils.toArray(internalTeamsCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, internalTeamsCurrent?.filter),
    allPages: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: internalTeamsEnabled
  });

  const [internalListsSearch, setInternalListsSearch] = useState({});
  const internalListsCurrent = internalListsSearch[Object.keys(internalListsSearch)[0]];
  const internalListsEnabled = Boolean(internalListsCurrent?.callback);
  const internalLists = useConnectionInternalLists({
    teamId: internalListsCurrent?.teamId,
    filter: utils.addFilter((utils.toArray(internalListsCurrent?.ids, true).length > 0) ? [{
      id: 'listIds',
      value: utils.toArray(internalListsCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, internalListsCurrent?.filter),
    allPages: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: internalListsEnabled
  });

  const [internalFieldsSearch, setInternalFieldsSearch] = useState({});
  const internalFieldsCurrent = internalFieldsSearch[Object.keys(internalFieldsSearch)[0]];
  const internalFieldsEnabled = Boolean(internalFieldsCurrent?.callback);
  const internalFields = useConnectionInternalFields({
    teamId: internalFieldsCurrent?.teamId,
    collectionId: internalFieldsCurrent?.collectionId,
    filter: utils.addFilter((utils.toArray(internalFieldsCurrent?.ids, true).length > 0) ? [{
      id: 'fieldIds',
      value: utils.toArray(internalFieldsCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, internalFieldsCurrent?.filter),
    allPages: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: internalFieldsEnabled
  });

  const [externalTeamsSearch, setExternalTeamsSearch] = useState({});
  const externalTeamsCurrent = externalTeamsSearch[Object.keys(externalTeamsSearch)[0]];
  const externalTeamsEnabled = Boolean(externalTeamsCurrent?.callback);
  const externalTeams = useConnectionExternalTeams({
    type: externalTeamsCurrent?.type,
    key: externalTeamsCurrent?.key,
    filter: utils.addFilter((utils.toArray(externalTeamsCurrent?.ids, true).length > 0) ? [{
      id: 'teamIds',
      value: utils.toArray(externalTeamsCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, externalTeamsCurrent?.filter),
    allPages: true
  }, {
    ...constants.queryOptions.runOnceFresh,
    enabled: externalTeamsEnabled
  });

  const [externalListsSearch, setExternalListsSearch] = useState({});
  const externalListsCurrent = externalListsSearch[Object.keys(externalListsSearch)[0]];
  const externalListsEnabled = Boolean(externalListsCurrent?.callback);
  const externalLists = useConnectionExternalLists({
    type: externalListsCurrent?.type,
    key: externalListsCurrent?.key,
    teamId: externalListsCurrent?.teamId,
    filter: utils.addFilter((utils.toArray(externalListsCurrent?.ids, true).length > 0) ? [{
      id: 'listIds',
      value: utils.toArray(externalListsCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, externalListsCurrent?.filter),
    allPages: true
  }, {
    ...constants.queryOptions.runOnceFresh,
    enabled: externalListsEnabled
  });

  const [externalFieldsSearch, setExternalFieldsSearch] = useState({});
  const externalFieldsCurrent = externalFieldsSearch[Object.keys(externalFieldsSearch)[0]];
  const externalFieldsEnabled = Boolean(externalFieldsCurrent?.callback);
  const externalFields = useConnectionExternalFields({
    type: externalFieldsCurrent?.type,
    key: externalFieldsCurrent?.key,
    teamId: externalFieldsCurrent?.teamId,
    listId: externalFieldsCurrent?.listId,
    filter: utils.addFilter((utils.toArray(externalFieldsCurrent?.ids, true).length > 0) ? [{
      id: 'fieldIds',
      value: utils.toArray(externalFieldsCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, externalFieldsCurrent?.filter),
    allPages: true
  }, {
    ...constants.queryOptions.runOnceFresh,
    enabled: externalFieldsEnabled
  });

  useEffect(() => {
    if (!teamMemberEnabled || teamMemberOptions) {
      teamMemberCurrent?.callback?.(
        (teamMemberOptions ?? []).filter((tm) => {
          const must = utils.toArray(teamMemberCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(teamMemberCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!teamMemberCurrent?.search ||
            tm.value.toString() === teamMemberCurrent?.search.toString() ||
            tm.label.toLowerCase().includes(teamMemberCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => tm.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => tm.value.toString() === v))
            );
        })
      );
      if (utils.isDefined(teamMemberCurrent)) {
        setTeamMemberSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [teamMemberCurrent, teamMemberEnabled, teamMemberOptions]);

  useEffect(() => {
    if (!dealLeaderEnabled || dealLeaderOptions) {
      dealLeaderCurrent?.callback?.(
        (dealLeaderOptions ?? []).filter((dl) => {
          const must = utils.toArray(dealLeaderCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(dealLeaderCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!dealLeaderCurrent?.search ||
              dl.value.toString() === dealLeaderCurrent?.search.toString() ||
              dl.label.toLowerCase().includes(dealLeaderCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => dl.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => dl.value.toString() === v))
            );
        })
      );
      if (utils.isDefined(dealLeaderCurrent)) {
        setDealLeaderSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [dealLeaderCurrent, dealLeaderEnabled, dealLeaderOptions]);

  useEffect(() => {
    if (!teamListEnabled || teamList.data || teamList.status.hasError) {
      teamListCurrent?.callback?.((teamList.data ?? [])
        .map((t) => ({
          ...t,
          label: t.name,
          value: +t.teamId,
          id: +t.teamId,
        }))
        .filter((t) => {
          const must = utils.toArray(teamListCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(teamListCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!teamListCurrent?.search ||
              t.label.toLowerCase().includes(teamListCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => t.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => t.value.toString() === v))
            );
        }));

      if (utils.isDefined(teamListCurrent)) {
        setTeamListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [teamListCurrent, teamListEnabled, teamList.data, teamList.status.hasError]);

  useEffect(() => {
    if (!userListEnabled || userList.data || userList.status.hasError) {
      userListCurrent?.callback?.((userList.data ?? [])
        .map((u) => ({
          ...u,
          label: `${utils.personName(u.firstName, u.lastName)}`,
          display: u.username,
          value: +u.userId,
          id: +u.userId,
        }))
      );
      if (utils.isDefined(userListCurrent)) {
        setUserListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [userListCurrent, userListEnabled, userList.data, userList.status.hasError]);

  useEffect(() => {
    if (!dealflowStatusEnabled || dealflowStatuses) {
      dealflowStatusCurrent?.callback?.(
        (dealflowStatuses ?? []).filter((st) => {
          const must = utils.toArray(dealflowStatusCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(dealflowStatusCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!dealflowStatusCurrent?.search ||
            st.value.toString() === dealflowStatusCurrent?.search.toString() ||
            st.label.toLowerCase().includes(dealflowStatusCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => st.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => st.value.toString() === v))
            );
        })
      );
      if (utils.isDefined(dealflowStatusCurrent)) {
        setDealflowStatusSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [dealflowStatusCurrent, dealflowStatusEnabled, dealflowStatuses]);

  useEffect(() => {
    if (!currencyListEnabled || currencyConversion) {
      currencyListCurrent?.callback?.((currencyConversion ?? [])
        .map((c) => ({
          ...c,
          label: `${c.symbol} ${utils.formatNumber(Math.round(+c.original))} (${c.name})`,
          value: Math.round(+c.converted)
        }))
      );
      if (utils.isDefined(currencyListCurrent)) {
        setCurrencyListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [currencyListCurrent, currencyListEnabled, currencyConversion]);

  useEffect(() => {
    if (!internalTeamsEnabled || internalTeams.data || internalTeams.status.hasError) {
      internalTeamsCurrent?.callback?.((internalTeams.data?.data?.teams ?? [])
        .map((t) => ({
          ...t,
          label: t.label ?? t.name,
          value: t.id
        }))
        .filter((t) => {
          const must = utils.toArray(internalTeamsCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(internalTeamsCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!internalTeamsCurrent?.search ||
              t.name.toString() === internalTeamsCurrent?.search.toString() ||
              t.label.toLowerCase().includes(internalTeamsCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => t.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => t.value.toString() === v))
            );
        }));
      if (utils.isDefined(internalTeamsCurrent)) {
        setInternalTeamsSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [internalTeamsCurrent, internalTeamsEnabled, internalTeams.data, internalTeams.status.hasError]);

  useEffect(() => {
    if (!internalListsEnabled || internalLists.data || internalLists.status.hasError) {
      internalListsCurrent?.callback?.((internalLists.data?.data?.lists ?? [])
        .map((l) => ({
          ...l,
          label: l.label ?? l.name,
          value: l.id
        }))
        .filter((l) => {
          const must = utils.toArray(internalListsCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(internalListsCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!internalListsCurrent?.search ||
              l.name.toString() === internalListsCurrent?.search.toString() ||
              l.label.toLowerCase().includes(internalListsCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => l.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => l.value.toString() === v))
            );
        }));
      if (utils.isDefined(internalListsCurrent)) {
        setInternalListsSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [internalListsCurrent, internalListsEnabled, internalLists.data, internalLists.status.hasError]);

  useEffect(() => {
    if (!internalFieldsEnabled || internalFields.data || internalFields.status.hasError) {
      internalFieldsCurrent?.callback?.((internalFields.data?.data?.fields ?? [])
        .map((f) => ({
          ...f,
          label: f.label ?? f.name,
          value: f.id
        }))
        .filter((f) => {
          const must = utils.toArray(internalFieldsCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(internalFieldsCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!internalFieldsCurrent?.search ||
              f.name.toString() === internalFieldsCurrent?.search.toString() ||
              f.label.toLowerCase().includes(internalFieldsCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => f.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => f.value.toString() === v))
            );
        }));
      if (utils.isDefined(internalFieldsCurrent)) {
        setInternalFieldsSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [internalFieldsCurrent, internalFieldsEnabled, internalFields.data, internalFields.status.hasError]);

  useEffect(() => {
    if (!externalTeamsEnabled || externalTeams.data || externalTeams.status.hasError) {
      externalTeamsCurrent?.callback?.((externalTeams.data?.data?.teams ?? [])
        .map((t) => ({
          ...t,
          label: t.label ?? t.name,
          value: t.id
        }))
        .filter((t) => {
          const must = utils.toArray(externalTeamsCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(externalTeamsCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!externalTeamsCurrent?.search ||
              t.name.toString() === externalTeamsCurrent?.search.toString() ||
              t.label.toLowerCase().includes(externalTeamsCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => t.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => t.value.toString() === v))
            );
        }));
      if (utils.isDefined(externalTeamsCurrent)) {
        setExternalTeamsSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [externalTeamsCurrent, externalTeamsEnabled, externalTeams.data, externalTeams.status.hasError]);

  useEffect(() => {
    if (!externalListsEnabled || externalLists.data || externalLists.status.hasError) {
      externalListsCurrent?.callback?.((externalLists.data?.data?.lists ?? [])
        .map((l) => ({
          ...l,
          label: l.label ?? l.name,
          value: l.id
        }))
        .filter((l) => {
          const must = utils.toArray(externalListsCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(externalListsCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!externalListsCurrent?.search ||
              l.name.toString() === externalListsCurrent?.search.toString() ||
              l.label.toLowerCase().includes(externalListsCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => l.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => l.value.toString() === v))
            );
        }));
      if (utils.isDefined(externalListsCurrent)) {
        setExternalListsSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [externalListsCurrent, externalListsEnabled, externalLists.data, externalLists.status.hasError]);

  useEffect(() => {
    if (!externalFieldsEnabled || externalFields.data || externalFields.status.hasError) {
      externalFieldsCurrent?.callback?.((externalFields.data?.data?.fields ?? [])
        .map((f) => ({
          ...f,
          label: f.label ?? f.name,
          value: f.id
        }))
        .filter((f) => {
          const must = utils.toArray(externalFieldsCurrent?.ids, true).map((v) => v.toString()).filter((v) => !v.startsWith('-'));
          const mustNot = utils.toArray(externalFieldsCurrent?.ids, true).map((v) => v.toString())
            .filter((v) => v.startsWith('-')).map((v) => v.slice(1));

          return (!externalFieldsCurrent?.search ||
              f.name.toString() === externalFieldsCurrent?.search.toString() ||
              f.label.toLowerCase().includes(externalFieldsCurrent?.search.toLowerCase())) &&
            (
              (must.length === 0 || must.find((v) => f.value.toString() === v)) &&
              (mustNot.length === 0 || !mustNot.find((v) => f.value.toString() === v))
            );
        }));
      if (utils.isDefined(externalFieldsCurrent)) {
        setExternalFieldsSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [externalFieldsCurrent, externalFieldsEnabled, externalFields.data, externalFields.status.hasError]);

  return useMemo(() => {
    return {
      teams: ({key, search, ids, filter, callback}) => {
        setTeamListSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      users: ({key, search, ids, filter, callback}) => {
        setUserListSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      members: ({key, search, ids, filter, callback}) => {
        setTeamMemberSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      dealLeaders: ({key, search, ids, filter, callback}) => {
        setDealLeaderSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      dealflowStatuses: ({key, search, ids, filter, callback}) => {
        setDealflowStatusSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      currencyConversions: ({key, search, ids, filter, callback}) => {
        setCurrencyListSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      internalTeams: ({key, search, ids, filter, context, callback}) => {
        setInternalTeamsSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      internalLists: ({key, search, ids, filter, context, callback}) => {
        setInternalListsSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      internalFields: ({key, search, ids, filter, context, callback}) => {
        setInternalFieldsSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      externalTeams: ({key, search, ids, filter, context, callback}) => {
        setExternalTeamsSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      externalLists: ({key, search, ids, filter, context, callback}) => {
        setExternalListsSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      },
      externalFields: ({key, search, ids, filter, context, callback}) => {
        setExternalFieldsSearch((current)=> ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      }
    }
  }, []);
}
